import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type OwnProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  to: string;
};
type Props = OwnProps & RouteComponentProps<any>;

/**
 * A button integrated with the react-router-dom library.
 */
function LinkButtonInternal(props: Props) {
  const {
    history,
    match,
    location,
    to,
    onClick,
    children,
    ...restProps
  } = props;

  function onClickHandler(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (onClick) onClick(e);
    history.push(to);
  }

  return (
    <button {...restProps} onClick={onClickHandler}>
      {children}
    </button>
  );
}

export const LinkButton = withRouter(LinkButtonInternal);
