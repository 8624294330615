import { createSelector } from 'reselect';
import { daysLeft, filterByState, getAllAnalyses } from './analyses';
import { get, identity } from 'lodash-es';
import _ from 'lodash';
import { AnalysisState } from '../flowTypes';
import { AnalysisType, getAnalysisType } from '../utils/analyses';

export const getMapFilters = (state) => get(state, 'map.filters');

export const getFilteredMapAnalysisKeys = createSelector(
  getAllAnalyses, getMapFilters,
  (analyses, mapFilters) => {
    const { deadline, soil, residue, drone, markedBy, probeTaken } = mapFilters;
    return _(analyses)
      .values()
      .filter(identity)
      .filter(filterByState(AnalysisState.Inbox))
      .filter(filterByDeadline(deadline))
      .filter(filterByLabOffer(soil, residue, drone))
      .filter(filterByLabMarked(markedBy))
      .filter(filterByLabProbeTaken(probeTaken))
      .map(analysis => analysis.key)
      .value();
  });

function filterByDeadline(deadline) {
  return analysis => deadline == null || daysLeft(analysis) <= deadline;
}

function filterByLabOffer(soil, residue, drone) {
  return analysis => {
    const analysisType = getAnalysisType(analysis);
    return (soil && analysisType === AnalysisType.Soil) ||
      (residue && analysisType === AnalysisType.Residue) ||
      (drone && analysisType === AnalysisType.Drone);
  };
}

function filterByLabMarked(markedBy) {
  return analysis => markedBy == null || get(analysis, 'lab_marked_by.uid') === markedBy;
}

function filterByLabProbeTaken(probeTaken) {
  return analysis => probeTaken == null || probeTaken === (get(analysis, 'lab_probe_taken_by') != null);
}
