import * as React from 'react';
import {
  city as cityColumn,
  companyName as companyNameColumn,
  completedOn as completedOnColumn,
  country as countryColumn,
  created as createdColumn,
  lab as labColumn,
  labNote as labNoteColumn,
  labOffer as labOfferColumn,
  number as numberColumn,
  probeTaker as probeTakerColumn,
  probeType as probeTypeColumn,
  resultOn as resultOnColumn,
  status as statusColumn,
  crop as cropColumn,
  field as fieldColumn,
  cropArt as cropArtColumn,
} from './columns';
import Table from './Table';
import Status from './Status';
import { Analysis, Profile } from '../../flowTypes';

const ZERO = 0;
const ONE = 1;

type Props = {
  onClickAnalysis: (arg0: Analysis) => void;
  onFilterChange: (...args: Array<any>) => any;
  analyses: Array<Analysis>;
  isLoading: boolean;
  profile: Profile;
};

export default class ResultsTable extends React.Component<Props> {
  render() {
    const {
      profile: { isSuperAdmin },
      profile,
      ...restProps
    } = this.props;

    const columns = [
      numberColumn,
      labOfferColumn,
      {
        ...probeTypeColumn,
        Cell: probeTypeColumn.makeCell(profile),
      },
      cropColumn,
      cropArtColumn,
      fieldColumn,
      probeTakerColumn,
      resultOnColumn,
      createdColumn,
      companyNameColumn,
      cityColumn,
      countryColumn,
      labNoteColumn,
      completedOnColumn,
      {
        ...statusColumn,
        Cell: ({ original: analysis }) => <Status analysis={analysis}/>,
      },
    ];

    if (isSuperAdmin) {
      columns.splice(ONE, ZERO, labColumn);
    }

    return (
      <Table
        columns={columns}
        {...restProps}
      />
    );
  }
}
