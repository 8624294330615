import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getAllAnalyses, getPosition } from '../../selectors/analyses';
import { FilterMode } from '../../selectors/filters';
import { get, isEqual, keys } from 'lodash-es';
import Page from '../Page/Page';
import { changeFilters, setCurrentLocation, setMapView } from '../../actions/actions';
import type { Analysis, Profile } from '../../flowTypes';
import Map, { DEFAULT_MAP_ZOOM, silverStyle } from './Map';
import { getBounds, latLngToMapPos, posToLatLng } from './utils';
import AnalysisMarker from './AnalysisMarker';
import Nav from './ToolBar';
import { getFilteredMapAnalysisKeys } from '../../selectors/map';
import ReactDOM from 'react-dom';
import AnalysisModal from '../AnalysisModal/AnalysisModal';
import RejectAnalysisModal from '../Analyses/RejectAnalysisModal';
import { setAcceptance, setMarked } from '../../data/analysis';
import { firestoreConnect } from 'react-redux-firebase';
import SpiderfiedMarkerClusterer from './SpiderfiedMarkerClusterer';
import { TrafficLayer } from '@react-google-maps/api';
import MapControl, { ControlButton, ControlContainer, TOP_LEFT } from './MapControl';
import CurrentLocationMarker from './CurrentLocationMarker';

const ZERO = 0;
const ONE = 1;
const CLOSER_ZOOM = 20;
const MAP_BOUNDS_PADDING = 50;

type Props = {
  filteredAnalyses: Array<Analysis>;
  actions: Record<string, any>;
  lab: Record<string, any>;
  isLoading: boolean;
  center: google.maps.LatLng | google.maps.LatLngLiteral;
  zoom: number;
  firestore: Record<string, any>;
  profile: Profile;
  currentLocation: Record<string, any>;
};

type State = {
  didSetCenter: boolean;
  analysisToOpen: Analysis | null;
  analysisToReject: Analysis | null;
  showTrafficLayer: boolean;
  showCurrentLocation: boolean;
  mapRef: google.maps.Map | null;
  markerClusterer?: any;
};

class AnalysesMap extends React.Component<Props, State> {
  state: State = {
    didSetCenter: false,
    analysisToOpen: null,
    analysisToReject: null,
    showTrafficLayer: false,
    showCurrentLocation: false,
    mapRef: null,
  };

  mapRef: google.maps.Map | null = null;
  watchId: number | null = null;

  constructor(props) {
    super(props);

    this.onAccept = this.onAccept.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onMark = this.onMark.bind(this);
    this.onUnmark = this.onUnmark.bind(this);
  }

  render() {
    const {
      lab,
      center,
      zoom,
      actions,
    } = this.props;
    const { setMapView } = actions;
    const onUpdateView = () => this.mapRef && setMapView(latLngToMapPos(this.mapRef.getCenter()), this.mapRef.getZoom());

    return (
      <Page
        className="analyses"
        wrapperClassName="pl-0 pr-0"
        title={lab ? <span>{lab.name}</span> : null}
        before={<Nav/>}
        footer={null}
      >
        <Map
          onLoad={(map) => {
            if (map) {
              this.mapRef = map;
              this.setState({ mapRef: map });
              this.centerOnMarkersOnce();
            }
          }}
          mapContainerClassName="map-container"
          mapContainerStyle={{
            height: 'calc(100vh - 70px - 52px)',
          }}
          options={{ gestureHandling: 'greedy', styles: silverStyle }}
          center={center || { lat: 51.165691, lng: 10.451526000000058 }}
          zoom={zoom != null ? zoom : DEFAULT_MAP_ZOOM}
          onDragEnd={onUpdateView}
          onZoomChanged={onUpdateView}
        >
          {this.renderMarkerClusterer()}
          {this.state.showTrafficLayer && <TrafficLayer options={{ autoRefresh: true }} />}
          {this.renderMapControls()}
          {this.renderCurrentLocationMarker()}
        </Map>

        {
          !!this.state.analysisToOpen &&
          ReactDOM.createPortal(
            <AnalysisModal
              open={true}
              analysis={this.state.analysisToOpen}
              onHide={() => this.setState({ analysisToOpen: null })}
              onAccept={this.onAccept}
              onReject={this.onReject}
              onMark={this.onMark}
              onUnmark={this.onUnmark}
            />,
            document.getElementById('modal-portal') as HTMLElement,
          )
        }
        {
          !!this.state.analysisToReject &&
          <RejectAnalysisModal
            analysis={this.state.analysisToReject}
            show={true}
            onCancel={(e) => this.setState({ analysisToReject: null })}
            onReject={(reason) => {
              this.state.analysisToReject && setAcceptance(this.props.firestore, this.props.profile, this.state.analysisToReject, false, reason)
                .then(() => this.setState({ analysisToReject: null }));
            }}/>
        }
      </Page>
    );
  }

  private renderMarkerClusterer() {
    if (!this.state.mapRef) {
      return null;
    }
    const mapRef = this.state.mapRef;
    return <SpiderfiedMarkerClusterer
      markersWontMove={true}
      markersWontHide={true}
      basicFormatEvents={true}
      spiralFootSeparation={49}
      spiralLengthFactor={7}
      spiralLengthStart={20}
      map={mapRef}
      onMarkerClusterer={(markerClusterer) => this.setState({ markerClusterer })}
    >
      {this.renderMarkers(mapRef)}
    </SpiderfiedMarkerClusterer>;
  }

  private renderMarkers(mapRef: google.maps.Map) {
    if (!this.state.markerClusterer) {
      return null;
    }
    return this.props.filteredAnalyses.map((analysis, i) =>
      <AnalysisMarker
        key={i}
        analysis={analysis}
        onClick={() => this.setState({ analysisToOpen: analysis })}
        map={mapRef}
        markerClusterer={this.state.markerClusterer}
      />);
  }

  private renderCurrentLocationMarker() {
    if (!this.state.showCurrentLocation || !this.state.markerClusterer) {
      return null;
    }
    return <CurrentLocationMarker markerClusterer={this.state.markerClusterer} />;
  }

  private renderMapControls() {
    if (!this.mapRef) {
      return null;
    }

    return <MapControl
      position={TOP_LEFT}
      map={this.mapRef}
    >
      <ControlContainer>
        <ControlButton
          className={this.state.showTrafficLayer ? 'active' : ''}
          onClick={() => this.setState({ showTrafficLayer: !this.state.showTrafficLayer })}
        ><i className="fa fa-car" /> <span>Verkehrslage</span></ControlButton>
        {!!navigator.geolocation &&
          <ControlButton
            className={this.state.showCurrentLocation ? 'active' : ''}
            onClick={() => {
              this.setCurrentPositionState(!this.state.showCurrentLocation, 'click');
            }}
          ><i className="fa fa-map-marker" /> <span>Meine Position</span></ControlButton>
        }
      </ControlContainer>
    </MapControl>;
  }

  setCurrentPositionState(state = !this.state.showCurrentLocation, reason?) {
    if (navigator.geolocation) {
      if (this.watchId != null) {
        navigator.geolocation.clearWatch(this.watchId);
        this.watchId = null;
      }
      if (state) {
        let gotoLocation = reason === 'click';
        this.watchId = navigator.geolocation.watchPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          if (!isEqual(this.props.currentLocation, pos)) {
            this.props.actions.setCurrentLocation(pos);
          }
          if (!this.state.didSetCenter && this.mapRef) {
            this.props.actions.setMapView(pos, CLOSER_ZOOM);
            this.setState({ didSetCenter: true });
          }
          if (gotoLocation) {
            this.props.actions.setMapView(this.props.currentLocation, CLOSER_ZOOM);
            gotoLocation = false;
          }
        },
        (e) => console.warn('could not get location', e),
        // $FlowFixMe
        { timeout: 5000 },
        );
      }
      this.setState({ showCurrentLocation: state });
    }
  }

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillMount() {
    this.setCurrentPositionState(this.state.showCurrentLocation);
  }

  componentWillUnmount() {
    this.setCurrentPositionState(false);
  }

  componentDidUpdate(prevProps: Props, prevState, snapshot) {
    if (this.didReceiveFilteredAnalysisForFirstTime(prevProps)) {
      this.centerOnMarkersOnce();
    }
  }

  onAccept(analysis: Analysis) {
    setAcceptance(this.props.firestore, this.props.profile, analysis, true);
  }

  onReject(analysis: Analysis) {
    this.setState({ analysisToReject: analysis });
  }

  onMark(analysis: Analysis) {
    setMarked(this.props.firestore, this.props.profile, analysis, true);
  }

  onUnmark(analysis: Analysis) {
    setMarked(this.props.firestore, this.props.profile, analysis, false);
  }

  centerOnMarkersOnce() {
    const {
      filteredAnalyses,
      currentLocation,
      actions,
    } = this.props;
    const { setMapView } = actions;

    if (!this.state.didSetCenter && filteredAnalyses.length) {
      this.centerOnMarkers();
      this.setState({ didSetCenter: true });
    } else if (!this.state.didSetCenter && currentLocation && this.mapRef) {
      setMapView(currentLocation, CLOSER_ZOOM);
      this.setState({ didSetCenter: true });
    }
  }

  centerOnMarkers() {
    const { filteredAnalyses, actions } = this.props;
    const { setMapView } = actions;

    if (this.mapRef) {
      if (filteredAnalyses.length > ONE) {
        this.mapRef.fitBounds(getBounds(filteredAnalyses.map(getPosition)), MAP_BOUNDS_PADDING);
        setMapView(latLngToMapPos(this.mapRef.getCenter()), this.mapRef.getZoom());
      } else if (filteredAnalyses.length == ONE) {
        const [firstFilteredAnalysis] = filteredAnalyses;
        this.mapRef.setCenter(posToLatLng(getPosition(firstFilteredAnalysis)));
        setMapView(latLngToMapPos(this.mapRef.getCenter()), this.mapRef.getZoom());
      }
    }
  }

  private didReceiveFilteredAnalysisForFirstTime(prevProps: Props) {
    return prevProps.filteredAnalyses.length === ZERO && this.props.filteredAnalyses.length > ZERO;
  }
}

export default compose<React.ComponentClass>(
  connect((state) => {
    const allAnalyses = getAllAnalyses(state);
    const profile = get(state, 'firebase.profile');
    const requesting = get(state, 'firestore.status.requesting');
    const filterMode = FilterMode.open;
    const loadingRequests = keys(requesting)
      .filter(r => requesting[r] && new RegExp('^analyses\\?where::state==' + filterMode.filterState).exec(r));
    const isLoading = loadingRequests.length && !!requesting[loadingRequests[ZERO]];

    return ({
      filteredAnalyses: getFilteredMapAnalysisKeys(state).map(key => allAnalyses[key]),
      lab: (profile && profile.labId != null && get(state, `firestore.data.labs.${profile.labId}`)) || null,
      isLoading,
      center: get(state, 'map.center'),
      zoom: get(state, 'map.zoom'),
      profile,
      currentLocation: get(state, 'map.currentLocation'),
    });
  }, (dispatch) => ({
    actions: bindActionCreators(
      {
        changeFilters,
        setMapView,
        setCurrentLocation,
      },
      dispatch,
    ),
  })),
  firestoreConnect(),
)(AnalysesMap);
