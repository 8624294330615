import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get, identity } from 'lodash-es';
import './style.css';
import { firebaseConnect } from 'react-redux-firebase';
import type { Profile } from '../../flowTypes';
import DropDown from '../DropDown/DropDown';
import { Link } from 'react-router-dom';
import { FilterMode } from '../../selectors/filters';
import { classes } from '../../utils/dom';
import { toggleMenu } from '../../actions/actions';
import HelpDropDown from './HelpDropDown';

class UserProfile extends React.Component<{
  profile: Profile;
  firebase: Record<string, any>;
}> {
  static contextTypes = {
    router: PropTypes.object,
  };

  logout() {
    this.props.firebase.logout();
    window.location.href = '/';
  }

  render() {
    const {
      profile: { displayName, email, isLoaded, isEmpty, photoURL, isSuperAdmin, isLabAdminUser },
    } = this.props;
    return (
      !isLoaded || isEmpty
        ? null
        : <DropDown
          tagName="li"
          className="nav-item"
          button={
            <a className="nav-link dropdown-toggle waves-effect waves-dark" href="javascript: void 0">
              {
                photoURL
                  ? <img src="" alt="user" className="profile-pic"/>
                  : <i className="fa fa-user"/>
              }
            </a>
          }
        >
          <ul className="dropdown-user">
            <li>
              <div className="dw-user-box">
                <div className="u-img">
                  {
                    photoURL
                      ? <img src="" alt="user" className="profile-pic"/>
                      : <i className="fa fa-user fa-2x"/>
                  }
                </div>
                <div className="u-text">
                  <h4>{displayName} <span className="email" title={email}>{email}</span></h4>
                </div>
              </div>
            </li>
            <li><Link to="/changepassword"><i className="fa fa-key"/> Passwort ändern</Link></li>
            {isSuperAdmin || isLabAdminUser ? <li><Link to="/users"><i className="fa fa-user-plus"/> Benutzer verwalten</Link></li> : null}
            <li><a href="javascript: void 0" onClick={this.logout.bind(this)}><i className="fa fa-power-off"/> Abmelden</a></li>
          </ul>
        </DropDown>
    );
  }
}

class Header extends React.Component<{
  children?: React.ReactNode;
  title?: React.ReactNode | null;
  usersWithPrivileges: Array<any>;
  profile: Profile;
  firebase: Record<string, any>;
  menuOpen: boolean;
  dispatch: (arg0: { type: string, payload: any }) => void;
}> {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const { title, profile, firebase, menuOpen, dispatch } = this.props;
    const router = this.context.router;

    return (
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">

          {/* logo */}
          <div className="navbar-header">
            <a href="/" className="branding navbar-brand">
              <img className="logo" src="new-logo.min.svg" alt="farmerJoe | Lab"/>
            </a>
          </div>

          <div className="top-nav navbar-header flex-grow-1">
            {
              profile.isLoaded && !profile.isEmpty &&
              <ul className="navbar-nav justify-content-center">
                <li className="nav-item">
                  <a className="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)"
                    onClick={() => dispatch(toggleMenu())}
                  >
                    <i className={classes('fa ', menuOpen ? 'fa-times' : 'fa-bars')}/>
                  </a>
                </li>
                <li className={['nav-item', /^\/map/.exec(router.route.location.pathname) && 'active'].filter(identity).join(' ')}>
                  <Link className="nav-link waves-effect waves-dark" to="/map">
                    <i className="fa fa-map"/> <span>Karte</span>
                  </Link>
                </li>
                <li className={['nav-item', new RegExp(`^/(|${FilterMode.enumValues.map(e => e.enumKey).join('|')})(/|$)`).exec(router.route.location.pathname) &&
                                            'active'].filter(identity).join(' ')}>
                  <Link className="nav-link waves-effect waves-dark" to="/open">
                    <i className="fa fa-flask"/> <span>Analysen</span>
                  </Link>
                </li>
              </ul>
            }
          </div>

          <div className="navbar-collapse flex-grow-0">
            <h3 className="navbar-nav ml-auto title">{title}</h3>
            <ul className="navbar-nav my-lg-0">
              <UserProfile profile={profile} firebase={firebase}/>
              <HelpDropDown />
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

type HeaderExternalProps = { title: any };

export default compose<React.ComponentClass<HeaderExternalProps>>(
  firebaseConnect(),
  connect((state) => ({
    profile: get(state, 'firebase.profile'),
    menuOpen: get(state, 'menuOpen'),
  }), (dispatch) => ({ dispatch })),
)(Header);
