import * as React from 'react';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { Loading } from '../Loading/Loading';
import Page from '../Page/Page';
import type { Profile } from '../../flowTypes';

type Props = {
  children?: React.ReactNode;
  labs: Array<any>;
  profile: Profile;
  dispatch: (...args: Array<any>) => any;
};

class Loader extends React.Component<Props, { first: boolean }> {
  render() {
    const { children, labs, profile } = this.props;
    if (!isLoaded(labs) || !profile.isLoaded) {
      return <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column">
        <Loading isLoading={true}/>
      </Page>;
    } else {
      return children;
    }
  }
}

export default compose<React.ComponentClass>(
  connect((state) => ({
    labs: get(state, 'firestore.data.labs'),
    profile: get(state, 'firebase.profile'),
  }), (dispatch) => ({ dispatch })),
  firestoreConnect(props => {
    const labId = get(props, 'profile.labId');
    const isSuperAdmin = get(props, 'profile.isSuperAdmin');
    if (isSuperAdmin) {
      return [
        {
          collection: 'labs',
        },
      ];
    } else if (labId != null) {
      return [
        {
          collection: 'labs',
          doc: labId,
        },
      ];
    } else {
      return [];
    }
  }),
)(Loader);
