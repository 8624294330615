import * as React from 'react';
import { compose } from 'redux';
import './ToolBar.css';

class ToolBar extends React.Component<{
  onAddNewUser: (...args: Array<any>) => any;
}> {
  render() {
    const {
      onAddNewUser,
    } = this.props;

    return (
      [
        <div key={0} style={{ height: '52px' }}></div>,
        <aside key={1} className="left-sidebar">
          <div className="scroll-sidebar">
            <nav className="sidebar-nav">
              <ul id="sidebarnav">
                <li className="active">
                  <button onClick={onAddNewUser} className="new-user-button">
                    <i className="fa fa-user-plus"/>&nbsp;
                    <span>Neuen Benutzer hinzufügen</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </aside>,
      ]
    );
  }
}

export default compose(
)(ToolBar);
