import { Enumify } from 'enumify';
import { AnalysisState } from '../flowTypes';

export class FilterMode extends Enumify {
  static open = new FilterMode(AnalysisState.Inbox);
  static inbox = new FilterMode(AnalysisState.Inbox);
  static results = new FilterMode(AnalysisState.Completed);
  static cancelled = new FilterMode(AnalysisState.Cancelled);
  static _ = FilterMode.closeEnum();

  filterState: number;
  constructor(value: number) {
    super();
    this.filterState = value;
  }
}
