import React, { Component } from 'react';

type Props = {
  onChange: (...args: Array<any>) => any;
  filterMode: any;
};

class SearchBox extends Component<Props, {
  query: string;
}> {
  state = {
    query: '',
  };

  private searchBoxRef = React.createRef<HTMLInputElement>();

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.filterMode !== this.props.filterMode) {
      this.setState({ query: '' });
    }
  }

  render() {
    const {
      onChange,
    } = this.props;
    return (
      <div className="form-control search-container" onClick={e => this.searchBoxRef.current && this.searchBoxRef.current.focus()}>
        <div className="search-inner-container">
          <i className="fa fa-search"/>
          <input
            ref={this.searchBoxRef}
            className="form-control search-box"
            placeholder="Suchen"
            value={this.state.query}
            onChange={(e) => {
              const value = e.target.value;
              this.setState({ query: value });
              onChange(value);
            }}
          />
        </div>
      </div>
    );
  }
}

export default SearchBox;
