import * as React from 'react';
import type { AnalysisCost } from '../../flowTypes';

const PRICE_DECIMAL_POINTS = 2;

type Props = {
  className: string;
  analysisCost: AnalysisCost;
};

export default function AnalysisCostSection(
  {
    className,
    analysisCost: {
      currency,
      grossPrice,
      netPrice,
      vat,
      vatSum,
    },
  }: Props) {
  return <div className={['analysis-cost-section', className].join(' ')}>
    <h4 className="card-title">Kosten</h4>
    <div className="section-body d-table simple-table">
      <div className="d-table-row">
        <span className="d-table-cell">Analyse</span> <span className="d-table-cell text-right">{netPrice.toFixed(PRICE_DECIMAL_POINTS)} {currency}</span>
      </div>
      <div className="d-table-row">
        <span className="d-table-cell">MwSt. ({vat} %)</span> <span className="d-table-cell text-right">{vatSum.toFixed(PRICE_DECIMAL_POINTS)} {currency}</span>
      </div>
      <div className="d-table-row">
        <span className="d-table-cell">Gesamtkosten</span> <span className="d-table-cell text-right font-weight-bold">{grossPrice.toFixed(PRICE_DECIMAL_POINTS)} {currency}</span>
      </div>
    </div>
  </div>;
}
