import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
import { get } from 'lodash-es';

class DropDown extends React.Component<{
  children?: React.ReactNode;
  button?: React.ReactNode;
  onHide?: (...args: Array<any>) => any;
  onShow?: (...args: Array<any>) => any;
  tagName?: string;
  className?: string;
  menuClassName?: string;
  menuPosition?: string;
  menuAnimation?: string;

  // from onClickOutside
  outsideClickIgnoreClass: any;
  eventTypes: any;
  preventDefault: any;
  stopPropagation: any;
  disableOnClickOutside: any;
  enableOnClickOutside: any;
}> {
  private dropdownRef = React.createRef<HTMLElement>();
  private dropdownMenuRef = React.createRef<HTMLDivElement>();

  handleClickOutside() {
    this.toggle(false);
  }

  toggle(state?: boolean | null) {
    if (this.dropdownRef.current && this.dropdownMenuRef.current) {
      const isBeingShown = state != null ? state : !this.dropdownRef.current.classList.contains('show');

      this.dropdownRef.current.classList.toggle('show', isBeingShown);
      this.dropdownMenuRef.current.classList.toggle('show', isBeingShown);

      if (isBeingShown && !!this.props.onShow) {
        this.props.onShow();
      } else if (!isBeingShown && !!this.props.onHide) {
        this.props.onHide();
      }
    }
  }

  render() {
    const {
      children, button, tagName = 'div', className = '', menuPosition = 'dropdown-menu-right', menuAnimation = 'scale-up', menuClassName = '',
      outsideClickIgnoreClass, eventTypes, preventDefault, stopPropagation, disableOnClickOutside, enableOnClickOutside, // from onClickOutside, ignore
      ...restProps
    } = this.props;

    let key = 0;
    return React.createElement(tagName, { ...restProps, ref: this.dropdownRef, className: 'dropdown ' + className }, [
      button
        ? React.Children.map(button, (button: any) => React.cloneElement(button, {
          key: key++,
          onClick: (...args) => {
            this.toggle();
            const onClick = get(button, 'props.onClick');
            return (onClick && onClick(...args)) || null;
          },
        },
        ))
        : null,
      <div ref={this.dropdownMenuRef} className={['dropdown-menu', menuPosition, menuAnimation, menuClassName].filter(s => !!s).join(' ')} key={key++}>
        {children}
      </div>,
    ]);
  }
}

export default onClickOutside(DropDown);
