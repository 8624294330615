import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { get } from 'lodash-es';
import { FilterMode } from '../../selectors/filters';
import { getInboxAnalysesCount, getOpenAnalysesCount } from '../../selectors/analyses';
import { changeFilters } from '../../actions/actions';
import './style.css';
import { LinkButton } from '../Common/LinkButton';

const ZERO = 0;

type ChangeFiltersInput = {
  mode: string;
};
type NavigationBarProps = {
  inboxAnalysesCount: number;
  openAnalysesCount: number;
  actions: {
    changeFilters: (input: ChangeFiltersInput) => void;
  };
  mode: string;
};

const NavigationBar = (props: NavigationBarProps) => {
  const { inboxAnalysesCount, openAnalysesCount, actions: { changeFilters }, mode } = props;
  const filterMode = FilterMode.enumValueOf(mode);

  function inboxBtnClasses() {
    const classes: string[] = ['main-nav-btn'];
    if (filterMode === FilterMode.inbox) classes.push('active');
    if (inboxAnalysesCount > ZERO) {
      classes.push('inbox-non-empty');
    } else {
      classes.push('inbox-btn');
    }
    return classes.join(' ');
  }

  function openBtnClasses() {
    const classes: string[] = ['main-nav-btn', 'open-btn'];
    if (filterMode === FilterMode.open) classes.push('active');
    return classes.join(' ');
  }

  function resultsBtnClasses() {
    const classes: string[] = ['main-nav-btn', 'results-btn'];
    if (filterMode === FilterMode.results) classes.push('active');
    return classes.join(' ');
  }

  function cancelledBtnClasses() {
    const classes: string[] = ['main-nav-btn', 'cancelled-btn'];
    if (filterMode === FilterMode.cancelled) classes.push('active');
    return classes.join(' ');
  }

  return [
    <div key={0} style={{ height: '52px' }}></div>,
    <aside key={1} className="navigation-bar left-sidebar">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <LinkButton
            className={inboxBtnClasses()}
            onClick={() => changeFilters({ mode: FilterMode.inbox.enumKey })}
            to="/inbox"
          >
            <i className="fa fa-square" /><span>Posteingang ( {inboxAnalysesCount} )</span>
          </LinkButton>
          <LinkButton
            className={openBtnClasses()}
            onClick={() => changeFilters({ mode: FilterMode.open.enumKey })}
            to="/open"
          >
            <i className="fa fa-square" /><span>Offen ( {openAnalysesCount} )</span>
          </LinkButton>
          <LinkButton
            className={resultsBtnClasses()}
            onClick={() => changeFilters({ mode: FilterMode.results.enumKey })}
            to="/results"
          >
            <i className="fa fa-check-square" /><span>Erledigt</span>
          </LinkButton>
          <LinkButton
            className={cancelledBtnClasses()}
            onClick={() => changeFilters({ mode: FilterMode.cancelled.enumKey })}
            to="/cancelled"
          >
            <i className="fa fa-times" /><span>Storniert</span>
          </LinkButton>
        </nav>
      </div>
    </aside>,
  ];
};

export default compose(
  connect((state) => ({
    openAnalysesCount: getOpenAnalysesCount(state),
    inboxAnalysesCount: getInboxAnalysesCount(state),
    mode: get(state, 'filters.mode'),
  }), (dispatch) => ({
    actions: bindActionCreators(
      {
        changeFilters,
      },
      dispatch,
    ),
  })),
)(NavigationBar);
