import * as React from 'react';
import { daysLeft } from '../../selectors/analyses';
import {
  city as cityColumn,
  companyName as companyNameColumn,
  country as countryColumn,
  created as createdColumn,
  crop as cropColumn,
  days as daysColumn,
  field as fieldColumn,
  lab as labColumn,
  labNote as labNoteColumn,
  labOffer as labOfferColumn,
  number as numberColumn,
  probeTaker as probeTakerColumn,
  probeType as probeTypeColumn,
  resultOn as resultOnColumn,
  status as statusColumn,
} from './columns';
import Table from './Table';
import Status from './Status';
import { Analysis, Profile } from '../../flowTypes';
import { analysisRowClassNameBasedOnDaysCount } from './utils';

const ZERO = 0;
const ONE = 1;

type Props = {
  onClickAnalysis: (arg0: Analysis) => void;
  onFilterChange: (...args: Array<any>) => any;
  analyses: Array<Analysis>;
  isLoading: boolean;
  profile: Profile;
};

export default class IndexTable extends React.Component<Props> {
  render() {
    const {
      profile: { isSuperAdmin },
      profile,
      ...restProps
    } = this.props;

    const columns = [
      numberColumn,
      labOfferColumn,
      {
        ...probeTypeColumn,
        Cell: probeTypeColumn.makeCell(profile),
      },
      daysColumn,
      cropColumn,
      fieldColumn,
      probeTakerColumn,
      resultOnColumn,
      createdColumn,
      companyNameColumn,
      cityColumn,
      countryColumn,
      labNoteColumn,
      {
        ...statusColumn,
        Cell: ({ original: analysis }) => <Status analysis={analysis}/>,
      },
    ];

    if (isSuperAdmin) {
      columns.splice(ONE, ZERO, labColumn);
    }

    return (
      <Table
        columns={columns}
        getTrProps={(state, rowInfo, column, instance) => ({
          className: analysisRowClassNameBasedOnDaysCount(daysLeft(rowInfo.original)),
        })}
        {...restProps}
      />
    );
  }
}
