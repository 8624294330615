import React from 'react';
import Page from '../Page/Page';
import './style.css';

type LoadingIconProps = {
  width?: number;
};

export function LoadingIcon(props: LoadingIconProps) {
  const iconStyle: React.CSSProperties = {
    position: 'static',
    width: props.width,
    height: props.width,
  };
  return <svg className="circular" viewBox="25 25 50 50" style={iconStyle}>
    <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"></circle>
  </svg>;
}

type Props = {
  isLoading?: boolean;
  error?: any;
};

export const Loading = (props: Props) => {
  const { isLoading = true, error } = props;
  // Handle the loading state
  if (isLoading) {
    return (
      <div className="loading-container">
        <LoadingIcon/>
        {/* <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i> */}
      </div>
    );
  } else if (error) {
    // Handle the error state
    return <div>Sorry, there was a problem loading the page.</div>;
  } else {
    return null;
  }
};

export const LoadingWholePage = (props: Props) => {
  const { isLoading, error } = props;
  return (
    <Page>
      <Loading isLoading={isLoading} error={error} />
    </Page>
  );
};
