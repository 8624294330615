import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { map, get, keys } from 'lodash-es';
import { ConfirmDialog } from '../Dialog/Dialog';
import { deleteFile } from '../../data/analysis';
import { Loading } from '../Loading/Loading';
import { Analysis, AnalysisState, File, Profile } from '../../flowTypes';
import { FilterMode } from '../../selectors/filters';
import PdfViewer from '../PdfViewer/PdfViewer';

const ZERO = 0;

type Props = {
  firebase: any;
  firestore: any;
  analysis: Analysis;
  token: string;
  profile: Profile;
};

type State = {
  openFiles: Record<string, boolean>;
  fileToRemove: File | null;
  isRemovingFile: boolean;
};

class FileSection extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openFiles: {
        ...keys(get(props, 'analysis.result.file', {})).reduce((o, key) => {
          o[key] = props.analysis.state === FilterMode.results.filterState;
          return o;
        }, {}),
        orderJSON: props.analysis.state === AnalysisState.Inbox,
        orderPDF: props.analysis.state === AnalysisState.Inbox,
      },
      fileToRemove: null,
      isRemovingFile: false,
    };
  }

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // open on upload
    this.setState({
      openFiles: {
        ...keys(get(nextProps, 'analysis.result.file', {})).reduce((o, key) => {
          o[key] = this.state.openFiles[key] == null || this.state.openFiles[key];
          return o;
        }, {}),
        orderJSON: this.state.openFiles.orderJSON,
        orderPDF: this.state.openFiles.orderPDF,
      },
    });
  }

  toggleFile(key) {
    this.setState({ openFiles: { ...this.state.openFiles, [key]: !this.state.openFiles[key] } });
  }

  render() {
    const { firebase, firestore, analysis, token, profile } = this.props;
    const { key, analyse_number, labSelected: { labId }, result } = analysis;
    const isSuperAdmin = profile.isSuperAdmin;

    // $FlowFixMe
    const jsonHref = `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/generateOrderJSON/${key}?token=${token}&download=1`;
    const jsonFilename = `${analyse_number}.json`;

    return ([
      <div className="file-section" key={0}>
        <h4 className="card-title">Auftrag</h4>
        <ul className="file-list">
          {(labId === 'lab1' || isSuperAdmin)
            ? <li>
            <div className="card">
              <div className="card-header" onClick={() => this.toggleFile('orderJSON')}>
                <span>{jsonFilename}</span>
              </div>
              <div className={'card-body collapse ' + (this.state.openFiles.orderJSON ? 'show' : '')}>
                <a href={jsonHref} className="btn btn-primary">herunterladen</a>
              </div>
            </div>
          </li>
            : null}
          <FileItem
            // $FlowFixMe
            file={{ name: `${analyse_number}.pdf`, downloadURL: `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/generateOrderPDF/${key}?token=${token}` }}
            onClick={(file) => this.toggleFile('orderPDF')}
            isShown={this.state.openFiles.orderPDF}
          />
        </ul>
      </div>,
      <div className="file-section" key={1}>
        <h4>Hochgeladene Dateien:</h4>

        {this.renderResultFiles()}

        <ConfirmDialog show={!!this.state.fileToRemove} title="Datei löschen" onClose={(value) => {
          if (value === 'yes') {
            this.setState({ isRemovingFile: true });
            this.state.fileToRemove && deleteFile(firebase, firestore, key, this.state.fileToRemove)
              .then(() => {
                this.setState({ fileToRemove: null, isRemovingFile: false });
              });
          } else {
            this.setState({ fileToRemove: null });
          }
        }}>
          <p>Möchten Sie dieses Datei löschen?</p>
          {this.state.isRemovingFile ? <Loading isLoading={true}/> : null}
        </ConfirmDialog>
      </div>,
    ]);
  }

  private renderResultFiles() {
    const { analysis, token } = this.props;
    const resultFiles = get(analysis, 'result.file', {});

    if (keys(resultFiles).length === ZERO) {
      return (<p>keine Dateien vorhanden</p>);
    }

    return (
      <ul className="file-list">
        {
          map(resultFiles, (file, key) => {
            const downloadURL = `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/analyses/${analysis.key}/downloadResultFile/${key}?token=${token}`;

            return (
              <FileItem
                key={key}
                file={{ name: file.name, downloadURL }}
                onClick={(file) => this.toggleFile(key)}
                onDelete={() => this.setState({ fileToRemove: file })}
                isShown={this.state.openFiles[key]}
              />
            );
          })
        }
      </ul>
    );
  }
}

type SubsetOfFile = {
  name: string;
  downloadURL: string;
};
type FileItemProps = {
  onClick: (arg0: SubsetOfFile) => void;
  onDelete?: (arg0: SubsetOfFile) => void;
  file: SubsetOfFile;
  isShown: boolean;
};

const FileItem = ({ onClick, onDelete, file, isShown }: FileItemProps) => {
  let pdfViewer;
  return (
    <li>
      <div className="card">
        <div className="card-header" onClick={() => onClick(file)}>
          <span>{file.name}</span>
          <div className="card-actions">
            {onDelete && <a className="btn btn-danger" onClick={(e) => {
              e.stopPropagation();
              onDelete(file);
            }}><i className="fas fa-trash"/></a>}
            <a className="btn btn-primary" href="javascript:void 0" onClick={(e) => {
              e.stopPropagation();
              pdfViewer && pdfViewer.download();
            }}><i className="fa fa-download"/> Herunterladen</a>
          </div>
        </div>
        <div className={'card-body collapse ' + (isShown ? 'show' : '')}>
          <PdfViewer ref={(ref) => { pdfViewer = ref; }} className="pdf-viewer" src={file.downloadURL} />
        </div>
      </div>
    </li>
  );
};

const selector = (state, ownProps) => {
  return {
    analysis: ownProps.analysis,
    profile: get(state, 'firebase.profile'),
  };
};

const wrappedUploader = firestoreConnect(props => {
  if (props.analysis) {
    return [{
      collection: 'analyses',
      doc: props.analysis.key,
    }];
  }
  return [];
})(FileSection);

export default connect(
  selector,
)(wrappedUploader);
