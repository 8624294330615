import { actionTypes } from 'redux-firestore';
import { DEFAULT_MAP_ZOOM } from '../components/Map/Map';

const { CLEAR_DATA } = actionTypes;

export const editFertilizing = (fertilizing_uuid) => ({
  type: 'app/editFertilizing',
  payload: { fertilizing_uuid },
});

export const changeFilters = (filters, options) => {
  return {
    type: 'app/changeFilters',
    payload: filters,
    options,
  };
};

export const clearFirestoreData = (preserve) => {
  return {
    type: CLEAR_DATA,
    preserve,
  };
};

export const changeMapFilters = (filters, options) => {
  return {
    type: 'app/changeMapFilters',
    payload: filters,
    options,
  };
};

export const setMapView = (center, zoom = DEFAULT_MAP_ZOOM) => {
  return {
    type: 'app/setMapView',
    payload: {
      center,
      zoom,
    },
  };
};

export const setCurrentLocation = (location) => {
  return {
    type: 'app/setCurrentLocation',
    payload: location,
  };
};

export const toggleMenu = (state?) => {
  return {
    type: 'app/toggleMenu',
    payload: state,
  };
};
