import React, { Component } from 'react';
import {
  Redirect,
} from 'react-router-dom';
import { LoadingWholePage } from '../Loading/Loading';
import Page from '../Page/Page';

import FirebaseUI from '../Firebase/UI';

const firebase = require('firebase');

type Props = {
  location: any;
};

type State = {
  user: any;
  redirectToReferrer: boolean;
  tokenLogin: boolean;
};

export default class Authenticate extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      user: firebase.auth().currentUser,
      redirectToReferrer: false,
      tokenLogin: false,
    };
  }

  loginWithToken(token) {
    if (this.state.tokenLogin === false) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          return firebase.auth().signInWithCustomToken(token);
        })

        .then(user => {
          this.setState({ user, redirectToReferrer: true, tokenLogin: true });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === 'auth/invalid-custom-token') {
            alert('We couln\'t authenticate you with the provided token. Please login using your username and password');
          } else {
            console.error(error, errorMessage);
          }

          this.setState({ redirectToReferrer: false, tokenLogin: true });
        });
    }
  }

  render() {
    const { from, from: { search } } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (search) {
      const query = new URLSearchParams(search);

      // When the URL is /the-path?some-key=a-value ...
      const value = query.get('customToken');

      if (value && this.state.tokenLogin === false) {
        this.loginWithToken(value);

        return <LoadingWholePage isLoading={true}/>;
      }
    }

    if (redirectToReferrer) {
      return (
        <Redirect to={from.pathname}/>
      );
    }

    return (
      <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column authenticate">
        <FirebaseUI signInSuccessUrl={from.pathname}/>
      </Page>
    );
  }
}
