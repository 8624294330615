import * as React from 'react';
import { get, has, isPlainObject } from 'lodash-es';
import moment from 'moment';
import FirebaseImage from '../Common/FirebaseImage';
import { classes } from '../../utils/dom';

const ZERO = 0;
const ONE = 1;

const formatPrice = (key, option) => {
  return (
    <div key={key} className="d-table-row">
      <div className="d-table-cell">{option.title}</div>
      <div className="d-table-cell text-right font-weight-bold">{option.price}</div>
    </div>
  );
};

const formatValue = (value, fieldConfig, key) => {
  if (!fieldConfig) {
    return null;
  }

  if (fieldConfig.type === 'enum') {
    if (Array.isArray(value) && value.length > ONE) {
      return <div className="d-table simple-table w-auto">
        {value.map((option, i) => {
          const enumVal = get(fieldConfig, `enums.${option}`);

          if (isPlainObject(enumVal)) {
            return formatPrice(`${key}-${option}`, enumVal);
          }

          return <span key={i} className="value font-weight-bold">{enumVal}</span>;
        })}
      </div>;
    } else {
      const enumVal = get(fieldConfig, `enums.${value}`);

      if (isPlainObject(enumVal)) {
        return <div className="d-table">
          {formatPrice(Array.isArray(value) ? `${key}-${value[ZERO]}` : `${key}-${value}`, enumVal)}
        </div>;
      }

      return <span key={key} className="value font-weight-bold">{enumVal}</span>;
    }
  }

  if (fieldConfig.type === 'date') {
    return value != null ? moment(value).format('DD.MM.YYYY') : null;
  }

  if (fieldConfig.type === 'string') {
    if (value) {
      return <span key={key} className="value font-weight-bold">{String(value)}</span>;
    }

    return null;
  }

  if (fieldConfig.type === 'boolean') {
    const truthy = get(fieldConfig, 'display.truthy', 'Ja');
    const falsy = get(fieldConfig, 'display.truthy', 'Nein');

    return <span key={key} className="value font-weight-bold">{value === true ? truthy : falsy}</span>;
  }

  if (fieldConfig.type === 'image') {
    return <FirebaseImage imageRef={value.ref}/>;
  }

  return <span key={key} className="value font-weight-bold">{value}</span>;
};

type ConfigType = {
  translations: Record<string, any>;
  defaultLanguage: string;
};

type RenderType = (
  currentLocale: string,
  form: Record<string, any>,
  config: ConfigType,
) => Record<string, any>;

export const render: RenderType = (currentLocale, form, config) => {
  const formConfig = getConfig(currentLocale, config);

  const o = formConfig.order
    .filter(blockName => has(form, blockName))
    .reduce((o, blockName) => {
      const block = get(formConfig, `blocks.${blockName}`);

      if (block) {
        // if the bloc is a field itself, render it
        if (!block.fields) {
          const field = block;

          const fieldValue = get(form, `${blockName}`);

          const renderedField = renderField(blockName, fieldValue, field, blockName);

          if (renderedField) {
            o[blockName] = {
              title: block.header,
              body: renderedField,
            };
            return o;
          }
        }

        // if we are dealing with fields, render them inside of a block
        const fields = block.order
          .filter(fieldName => has(form, `${blockName}.${fieldName}`))

          // filter out fields that are shown elsewhere
          .filter(fieldName => blockName !== 'offers' || (blockName === 'offers' && fieldName !== 'probe_date' && fieldName !== 'result_on'))

          .map(fieldName => {
            const field = get(block, `fields.${fieldName}`);
            const fieldValue = get(form, `${blockName}.${fieldName}`);

            return renderField(fieldName, fieldValue, field, blockName);
          }).filter(field => field !== null);

        if (fields.length) {
          o[blockName] = {
            title: block.header,
            body: fields,
          };
          return o;
        }

        return o;
      }

      return o;
    }, {});

  o.order = formConfig.order
    .filter(blockName => has(o, blockName));
  return o;
};

type getConfigType = (arg0: string, arg1: ConfigType) => any;
export const getConfig: getConfigType = (currentLocale, config) => config.translations[currentLocale] || config.translations[config.defaultLanguage];

const renderField = (key, fieldValue, field, blockName) => {
  // we don't have a field config, then return here
  if (typeof field === 'undefined') {
    return null;
  }

  if (typeof fieldValue !== 'undefined') {
    const formattedValue = formatValue(fieldValue, field, key);

    // The user didn't fill those values
    if (formattedValue === null) {
      return null;
    }

    if (Array.isArray(formattedValue) && formattedValue.length) {
      return <div key={key}>{formattedValue}</div>;
    }

    return <div key={key} className={classes('form-field', blockName === 'crop' && (key === 'name' && 'crop-name'))}>
      {field.label ? <span className="name">{field.label}{' '}</span> : ''}{formattedValue}
    </div>;
  }

  return null;
};
