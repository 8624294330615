import * as React from 'react';
import Modal from 'react-bootstrap-modal';

type DialogProps = {
  children?: React.ReactNode;
  show: boolean;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  headerProps?: {
    closeButton?: boolean;
    onClose?: (...args: Array<any>) => any;
  };

  backdrop?: any;
  keyboard?: any;
  transition?: any;
  attentionClass?: any;
  container?: any;
  onEnter?: any;
  onEntering?: any;
  onEntered?: any;
  onExit?: any;
  onExiting?: any;
  onExited?: any;
};

export default class Dialog extends React.Component<DialogProps & {
  onClose?: (value: null) => void;
}> {
  render() {
    const {
      children,
      headerProps = { closeButton: true },
      title = '',
      onClose,
      footer,
      ...restProps
    } = this.props;

    return (
      <Modal
        {...restProps}
        onHide={() => onClose && onClose(null)}
      >
        {title != null && <Modal.Header {...headerProps}><Modal.Title>{title}</Modal.Title></Modal.Header>}
        <Modal.Body>{children}</Modal.Body>
        {footer != null && <Modal.Footer>{footer}</Modal.Footer>}
      </Modal>
    );
  }
}

type Button = { value: any, label: React.ReactNode, className?: string, autoFocus?: boolean };

export class ConfirmDialog extends React.Component<DialogProps & {
  buttons?: Array<Button>;
  onClose: (value: any) => void;
}> {
  render() {
    const {
      children,
      title = 'Achtung',
      buttons = [{ value: 'no', label: 'Nein', className: 'btn-secondary' }, { value: 'yes', label: 'Ja', className: 'btn-primary', autoFocus: true }],
      onClose,
      ...restProps
    } = this.props;

    return (
      <Dialog
        footer={buttons
          .map(({ value, label, className = '', autoFocus }, i) =>
            <button autoFocus={!!autoFocus} className={'btn ' + className} key={i} onClick={() => onClose(value)}>{label}</button>,
          )}
        title={title}
        onClose={onClose}
        {...restProps}
      >
        {children}
      </Dialog>
    );
  }
}
