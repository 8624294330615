import { createSelector } from 'reselect';
import { get, identity, isArray, isString, negate } from 'lodash-es';
import _ from 'lodash';
import moment from 'moment/moment';
import { FilterMode } from './filters';
import { toDate } from '../utils/Date';
import { AnalysisState, OrderByDirection } from '../flowTypes';

export const getAllAnalyses = (state) => {
  return get(state, 'firestore.data.analyses', {});
};

export const getFilters = (state) => get(state, 'filters');
export const getFoundKeys = (state) => get(state, 'search.foundKeys', []);

export const getFilteredAnalysisKeys = createSelector(getAllAnalyses, getFilters, getFoundKeys, (analyses, { mode, orderBy: orderByForEachFilterMode, query }, foundKeys) => {
  const filterMode: FilterMode | undefined = FilterMode.enumValueOf(mode) as any;
  if (!filterMode) {
    throw new Error('Unexpected value for filterMode: undefined');
  }
  const orderBy = orderByForEachFilterMode[filterMode.enumKey];

  // prepare ordering arrays for lodash
  let orderByFields: any[] = [];
  let orderByOrders: OrderByDirection[] = [];
  if (isString(orderBy)) {
    orderByFields = [orderBy];
    orderByOrders = [OrderByDirection.Asc];
  } else if (isArray(orderBy)) {
    orderBy.forEach((o) => {
      if (isString(o)) {
        orderByFields.push(o);
        orderByOrders.push(OrderByDirection.Asc);
      } else if (isArray(orderBy)) {
        const [fieldName, orderByDirection] = o;
        orderByFields.push(fieldName);
        orderByOrders.push(orderByDirection || OrderByDirection.Asc);
      }
    });
  }

  const usedAnalyses = query ? foundKeys.map(key => analyses[key]) : analyses;

  let _analyses = _(usedAnalyses)
    .values()
    .filter(identity)
    .filter(filterByState(filterMode.filterState));

  if (filterMode === FilterMode.open) {
    _analyses = _analyses.filter(negate(filterByNeitherAcceptedNorRejected));
  } else if (filterMode === FilterMode.inbox) {
    _analyses = _analyses.filter(filterByNeitherAcceptedNorRejected);
  }

  return _analyses
    .orderBy(orderByFields, orderByOrders)
    .map(analysis => analysis.key)
    .value();
});

export const getOpenAnalysesCount = createSelector(getAllAnalyses, (analyses) => {
  return _(analyses)
    .values()
    .filter(identity)
    .filter(filterByState(AnalysisState.Inbox))
    .filter(negate(filterByNeitherAcceptedNorRejected))
    .size();
});

export const getInboxAnalysesCount = createSelector(getAllAnalyses, (analyses) => {
  return _(analyses)
    .values()
    .filter(identity)
    .filter(filterByState(AnalysisState.Inbox))
    .filter(filterByNeitherAcceptedNorRejected)
    .size();
});

export function filterByState(state) {
  return analysis => {
    return analysis.state === state;
  };
}

function filterByNeitherAcceptedNorRejected(analysis) {
  return analysis.lab_accepted === null;
}

export const daysLeft = ({ created, form }: { created: any, form: Record<string, any> }) => {
  const today = moment().startOf('day');
  const result_on = (form && form.offers && form.offers.result_on) || created;
  return -today.diff(moment(toDate(result_on)), 'days');
};

export function getPosition(analysis) {
  const service = get(analysis, 'form.offers.service', 'a');
  const serviceA = get(analysis, 'form.offers.serviceA', 'a');
  const isDroneService = analysis.labOffer === 'drone';

  // for Blickwinkel we return the field position
  if (isDroneService) {
    return get(analysis, 'field.position');
  }

  if (service === 'a' && serviceA === 'a') {
    return get(analysis, 'company.position');
  }
  return get(analysis, 'field.position');
}
