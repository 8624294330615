import * as React from 'react';
import DropDown from '../DropDown/DropDown';
import './HelpDropDown.css';

export default function HelpDropDown() {
  return (
    <DropDown
      tagName="li"
      className="nav-item help"
      button={
        <a className="nav-link dropdown-toggle waves-effect waves-dark" href="javascript: void 0">Hilfe</a>
      }
    >
      <div className="help-dropdown-item">
        <span>E-Mail:</span> <a href="mailto:labs@farmerjoe.com" target="_blank" rel="noreferrer">labs@farmerjoe.com</a>
      </div>
      <div className="help-dropdown-item">
        <span>Telefon:</span> <a href="tel:+493057714726" target="_blank" rel="noreferrer">+49 30 57714726</a>
      </div>
    </DropDown>
  );
}
