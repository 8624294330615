import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { reverse } from 'lodash-es';
import { toDate } from '../../utils/Date';
import { AnalysisState } from '../../flowTypes';

function formatUser(modified_by) {
  return modified_by ? modified_by.name : 'Unbekannter Benutzer';
}

function formatDate(modified) {
  return modified ? moment(toDate(modified)).format('YYYY-MM-DD HH:mm') : 'unbekannte Zeit';
}

type HeaderProps = {
  time: any;
  user: any;
};
function Header({ time, user }: HeaderProps) {
  return <span className="timestamp">{formatDate(time)} &mdash; {formatUser(user)}</span>;
}

const properties = {
  state: ({ before, after, modified, modified_by, lab_accepted, cancelled_reason }) => (
    <li>
      <Header time={modified} user={modified_by}/>
      Status: {after === AnalysisState.Completed ? 'Erledigt' : after === AnalysisState.Cancelled ? 'Storniert' : after === AnalysisState.Inbox ? lab_accepted ? 'Offen' : 'Posteingang' : ''}
      {after === AnalysisState.Cancelled && cancelled_reason ? ` Grund der Ablehnung: ${cancelled_reason}` : null}
    </li>
  ),
  lab_note: ({ before, after, modified, modified_by }) => (
    <li>
      <Header time={modified} user={modified_by}/>
      Bemerkung: &quot;{after}&quot;
    </li>
  ),
  lab_marked_by: ({ before, after, modified, modified_by }) => (
    <li>
      <Header time={modified} user={modified_by}/>
      {after ? 'Analyse markiert' : 'Markierung aufgehoben'}.
    </li>
  ),
  lab_accepted: ({ before, after, modified, modified_by }) => (
    <li>
      <Header time={modified} user={modified_by}/>
      Analyse {after ? 'angenommen' : 'abgelehnt'}.
    </li>
  ),
  result: ({ before, after, modified, modified_by }) => (
    <li>
      <Header time={modified} user={modified_by}/>
      Datei {after ? 'hochgeladen' : 'gelöscht'}.
    </li>
  ),
  lab_probe_taken_by: ({ before, after, modified, modified_by }) => (
    <li>
      <Header time={modified} user={modified_by}/>
      Probe {after ? 'als gezogen markiert' : 'als nicht gezogen markiert'}.
    </li>
  ),
};

type LogProps = {
  log?: any[];
};
function Log({ log = [] }: LogProps) {
  const mutableLog = [...log];

  return log.length
    ? <ul className="log list-style-none">{
      reverse(mutableLog).map((l, i) => React.Children.map(properties[l.prop](l), (c) => React.cloneElement(c, { key: i })))
    }</ul>
    : 'keine Einträge';
}

export default compose(
  connect((state) => ({})),
)(Log);
