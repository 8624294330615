import moment from 'moment/moment';
import { get } from 'lodash-es';
import { daysLeft } from '../../selectors/analyses';
import * as React from 'react';
import { getConfig } from '../AnalysisModal/Form';
import { toDate } from '../../utils/Date';
import { getAnalysisTypeShortName } from '../../utils/analyses';

export const number = {
  Header: 'Nummer',
  getHeaderProps: () => ({ title: 'Nummer' }),
  accessor: 'analyse_number',
  sortable: false,
  maxWidth: 120,
};

type ProbeTakerCellProps = {
  value?: {
    name: any;
  };
};
export const probeTaker = {
  Header: 'Bestellt von',
  getHeaderProps: () => ({ title: 'Bestellt von' }),
  id: 'probetaker',
  accessor: 'created_by',
  sortable: false,
  Cell: (props: ProbeTakerCellProps) => {
    if (!props.value) {
      return null;
    }
    return (
      <div>
        <div><strong>{props.value.name}</strong></div>
      </div>
    );
  },
};
export const created = {
  Header: 'Bestellt am',
  getHeaderProps: () => ({ title: 'Bestellt am' }),
  accessor: 'created',
  sortable: true,
  headerClassName: '-sort',
  Cell: props => moment(toDate(props.value)).format('DD.MM.YYYY HH:mm'),
  minWidth: 130,
};

type CompanyNameCellProps = {
  value?: {
    name: any;
    tel: any;
  };
};
export const companyName = {
  Header: 'Firnmenname',
  id: 'company.name',
  getHeaderProps: () => ({ title: 'Firnmenname' }),
  accessor: 'company',
  sortable: true,
  headerClassName: '-sort',
  Cell: (props: CompanyNameCellProps) => {
    if (!props.value) {
      return null;
    }
    return (
      <div>
        <div><strong>{props.value.name}</strong></div>
        <div>{props.value.tel}</div>
      </div>
    );
  },
};

type InvoiceAddressCellProps = {
  value?: {
    street: any;
  };
};
export const invoiceAddress = {
  Header: 'Adresse',
  getHeaderProps: () => ({ title: 'Adresse' }),
  id: 'address',
  accessor: 'invoice_address',
  sortable: false,
  Cell: (props: InvoiceAddressCellProps) => {
    if (!props.value) {
      return null;
    }
    return (
      <div>
        <div>{props.value.street}</div>
      </div>
    );
  },
};

type CityCellProps = {
  value?: {
    city: any;
    zip: any;
  };
};
export const city = {
  Header: 'Stadt',
  getHeaderProps: () => ({ title: 'Stadt' }),
  id: 'city',
  accessor: 'invoice_address',
  sortable: false,
  Cell: (props: CityCellProps) => {
    if (!props.value) {
      return null;
    }
    return (
      <div>{props.value.zip} {props.value.city}</div>
    );
  },
};
export const country = {
  Header: 'Land',
  getHeaderProps: () => ({ title: 'Land' }),
  id: 'country',
  accessor: 'invoice_address.country',
  sortable: false,
};
export const labOffer = {
  Header: 'Analyse',
  id: 'labOffer',
  getHeaderProps: () => ({ title: 'Analysetyp' }),
  accessor: getAnalysisTypeShortName,
  sortable: false,
};

type ResultOnCellProps = {
  value: any;
};
export const resultOn = {
  Header: 'Ergebnis bis',
  getHeaderProps: () => ({ title: 'Ergebnis bis' }),
  accessor: 'form.offers.result_on',
  sortable: true,
  headerClassName: '-sort',
  Cell: ({ value }: ResultOnCellProps) => {
    return <div>
      <span>{moment(toDate(value)).format('DD.MM.YYYY HH:mm')}</span>
    </div>;
  },
};

type ProbeTypeCellProps = {
  original: any;
};
export const probeType = {
  Header: 'Probe',
  id: 'probetype',
  getHeaderProps: () => ({ title: 'Probetyp' }),
  sortable: false,
  makeCell: (profile) => function MakeCellComponent({ original: analysis }: ProbeTypeCellProps) {
    let iconClass;
    const type: any[] = [];
    const formConfig = getConfig(profile.language, analysis.formConfig);
    const service = get(analysis, 'form.offers.service');

    if (service == null) {
      type.push('Selbst ziehen');
      iconClass = 'fa-home';
    } else {
      const serviceText = get(formConfig, 'blocks.offers.fields.service.enums');
      type.push(serviceText[service]);

      if (service === 'a') {
        const serviceA = get(analysis, 'form.offers.serviceA');
        const serviceAText = get(formConfig, 'blocks.offers.fields.serviceA.enums');
        type.push(<br key={0}/>);
        type.push(serviceAText[serviceA]);

        if (serviceA === 'a') {
          iconClass = 'fa-home';
        } else {
          iconClass = 'fa-cube';
        }
      } else {
        iconClass = 'fa-map-pin';
      }
    }
    return <span><i className={'fa ' + iconClass}/> {type || ''}</span>;
  },
};
export const labNote = {
  Header: 'Bemerkung',
  getHeaderProps: () => ({ title: 'Note' }),
  accessor: 'lab_note',
  sortable: false,
};
export const status = {
  Header: 'Status',
  getHeaderProps: () => ({ title: 'Status' }),
  accessor: 'lab_accepted',
  sortable: false,
  minWidth: 190,
};

type DaysCellProps = {
  original: any;
};
export const days = {
  Header: 'Tage',
  getHeaderProps: () => ({ title: 'Tage' }),
  id: 'days',
  sortable: false,
  maxWidth: 75,
  Cell: ({ original: analysis }: DaysCellProps) => <span className="days-left">{daysLeft(analysis) + ' T' || ''}</span>,
};

type CompletedOnCellProps = {
  value: any;
};
export const completedOn = {
  Header: 'Erledigt am',
  getHeaderProps: () => ({ title: 'Erledigt am' }),
  accessor: 'completed_on',
  sortable: true,
  headerClassName: '-sort',
  Cell: ({ value }: CompletedOnCellProps) => {
    return <div>
      <span>{moment(value).format('DD.MM.YYYY HH:mm')}</span>
    </div>;
  },
};

type CancelledOnCellProps = {
  value: any;
};
export const cancelledOn = {
  Header: 'Storniert am',
  getHeaderProps: () => ({ title: 'Storniert am' }),
  accessor: 'cancelled_on',
  sortable: true,
  headerClassName: '-sort',
  Cell: ({ value }: CancelledOnCellProps) => {
    return <div>
      <span>{moment(toDate(value)).format('DD.MM.YYYY HH:mm')}</span>
    </div>;
  },
};

type CropCellProps = {
  value: any;
};
export const crop = {
  Header: 'Kultur',
  getHeaderProps: () => ({ title: 'Kultur' }),
  accessor: 'field.activeCrop.name',
  sortable: false,
  Cell: ({ value }: CropCellProps) => {
    return <div>
      {value}
    </div>;
  },
};

const DEFAULT_FIELD_AREA = 0;
type FieldCellProps = {
  value: any;
};
export const field = {
  Header: 'Feld',
  getHeaderProps: () => ({ title: 'Feld' }),
  accessor: 'field',
  sortable: false,
  Cell: ({ value: field }: FieldCellProps) => {
    const fieldTitleParts = [field.name];
    if (!field.stock) {
      fieldTitleParts.push(`(${(field.size || DEFAULT_FIELD_AREA)} ha)`);
    }
    const fieldTitle = fieldTitleParts.join(' ');
    return <div title={fieldTitle}>{fieldTitle}</div>;
  },
};
export const lab = {
  Header: 'Labor',
  getHeaderProps: () => ({ title: 'Labor' }),
  accessor: 'labSelected.name',
  sortable: false,
};

type CropArtCellProps = {
  value: any;
};
export const cropArt = {
  Header: 'Sorte',
  getHeaderProps: () => ({ title: 'Sorte' }),
  accessor: 'field.activeCrop.art',
  sortable: false,
  Cell: ({ value }: CropArtCellProps) => {
    return <div>
      {value}
    </div>;
  },
};
