import * as React from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { Loading } from '../Loading/Loading';
import Page from '../Page/Page';
import { AnalysisState, Profile } from '../../flowTypes';
import { FilterMode } from '../../selectors/filters';

type Props = {
  children?: React.ReactNode;
  profile: Profile;
  dispatch: (...args: Array<any>) => any;
  status: { requesting: Record<string, any>, requested: Record<string, any> };
};

class Loader extends React.Component<Props> {
  render() {
    const {
      children,
      status: { requesting, requested },
      profile,
    } = this.props;
    const isLoading = !(requesting['analyses\\?where::state==0'] === false && requested['analyses\\?where::state==0'] === true);

    if (isLoading && !profile.isLoaded) {
      return <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column">
        <Loading isLoading={true}/>
      </Page>;
    } else {
      return children;
    }
  }
}

export default compose<React.ComponentClass>(
  connect((state) => ({
    profile: get(state, 'firebase.profile'),
    orderBy: get(state, `filters.orderBy.${FilterMode.open.enumKey}`),
    status: get(state, 'firestore.status'),
  }), (dispatch) => ({ dispatch })),
  firestoreConnect(({ profile, orderBy }) => {
    const hasLabId = !profile.isSuperAdmin && profile.labId != null;
    const where = [['state', '==', AnalysisState.Inbox]];
    if (hasLabId) {
      where.push(['labSelected.key', '==', profile.labId]);
    }
    return [{
      collection: 'analyses',
      where,
      orderBy,
      requestId: 'analysis-list',
      filterMode: FilterMode.open.enumKey,
    }];
  }),
)(Loader);
