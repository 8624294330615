import fire from './firebase';
import firebase from 'firebase';
import { isFunction } from 'lodash-es';
import fetch from './fetch';

export const createNewLabUser = async ({ email, password, displayName, firstname, lastname, language, labId, isLabAdminUser }, token) => {
  // create secondary app because creating users causes the new user to be signed in
  const secondaryApp = firebase.initializeApp(fire.options, 'CreateUserApp');
  let user: any = null;
  let createdUsersEntry = false;
  let isExistingUser = false;

  try {
    try {
      user = await secondaryApp.auth().createUserWithEmailAndPassword(email, password);
      await user.updateProfile({ displayName });

      await secondaryApp
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({
          email, displayName, firstname, lastname, uid: user.uid, language,
        });
      createdUsersEntry = true;
    } catch (e: any) {
      if (e.code === 'auth/email-already-in-use') {
        isExistingUser = true;
        const snapshot = await firebase.firestore().collection('users').where('email', '==', email).get();
        if (snapshot.size) {
          const [firstDoc] = snapshot.docs;
          user = firstDoc.data();
        } else {
          throw e;
        }
      } else {
        throw e;
      }
    }

    const uWPProps: any = {
      labId: labId,
      isLabUser: true,
      name: user.name || displayName,
      key: user.uid,
    };
    if (isLabAdminUser != null) {
      uWPProps.isLabAdminUser = isLabAdminUser;
    }

    const uWPRef = firebase.firestore()
      .collection('usersWithPrivileges')
      .doc(user.uid);

    const uWPExists = (await uWPRef.get()).exists;
    const promise = uWPExists ? uWPRef.update(uWPProps) : uWPRef.set(uWPProps);

    await promise
      .catch((e) => {
        console.error(`failed to create usersWithPrivileges for user id ${user.uid}`, {
          labId: labId,
          isLabUser: true,
          key: user.uid,
          name: displayName,
        }, e);
        return Promise.reject(e);
      });

    const sendLabInvitationUrl = `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/sendLabUserInvitation?token=${token}`;
    await fetch(sendLabInvitationUrl, {
      method: 'POST',
      body: JSON.stringify({
        uid: user.uid,
        isExistingUser,
        labId,
        password,
        isLabAdminUser,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    return {
      user,
      userWithPrivileges: uWPProps,
      isExistingUser,
    };
  } catch (e) {
    // delete user if something went wrong
    if (createdUsersEntry) {
      await secondaryApp
        .firestore()
        .collection('users')
        .doc(user.uid)
        .delete();
    }

    if (user && isFunction(user.delete)) {
      await user.delete();
    }
    throw e;
  } finally {
    await secondaryApp.auth().signOut();
    await secondaryApp.delete();
  }
};

export function getUserInfo(user = firebase.auth().currentUser) {
  if (user === null) {
    throw new Error('Unexpected user value: null');
  }
  const { email, displayName, phoneNumber, uid } = user;
  return {
    email: email,
    name: displayName,
    phoneNumber: phoneNumber || null,
    uid: uid,
  };
}

export function setLabAdmin(reduxFirestore, userKey, state) {
  return updateUserWithPrivileges(reduxFirestore, userKey, { isLabAdminUser: !!state });
}

export async function removeLabUser(reduxFirestore, userKey) {
  const query = {
    collection: 'usersWithPrivileges',
    doc: userKey,
  };
  const snap = await reduxFirestore.get(query);
  if (snap.exists) {
    const {
      isSuperAdmin,
    } = snap.data();
    if (!isSuperAdmin) {
      reduxFirestore.delete(query);
    } else {
      reduxFirestore.update(query, {
        isLabUser: firebase.firestore.FieldValue.delete(),
        isLabAdminUser: firebase.firestore.FieldValue.delete(),
        labId: firebase.firestore.FieldValue.delete(),
      });
    }
  }
}

function updateUserWithPrivileges(reduxFirestore, userKey, newProps) {
  const query = {
    collection: 'usersWithPrivileges',
    doc: userKey,
  };
  return reduxFirestore
    .update(query, newProps)
    .then(() => reduxFirestore.get(query));
}
