import React from 'react';
import firebase from 'firebase';
import { LoadingIcon } from '../Loading/Loading';
import fetch from '../../data/fetch';
import useSWR from 'swr';

type Props = {
  labId: string;
  isSuperAdmin?: boolean;
};

export function CompletedAnalysesCount(props: Props) {
  const { labId, isSuperAdmin } = props;
  const { data, error } = useSwrToFetchCount(labId, isSuperAdmin);

  if (!data) {
    return <LoadingIcon width={27} />;
  }
  if (error) {
    return null;
  }

  return <>{data.count}</>;
}

function useSwrToFetchCount(labId: string, isSuperAdmin?: boolean) {
  const url = decideEndpointUrl(labId, isSuperAdmin);
  const fetcher = () => fetchCount(url);
  return useSWR(url, fetcher);
}

async function fetchCount(endpointUrl: string) {
  const token = await getToken();
  const avoidCacheToken = new Date().getTime();
  const urlWithParams = `${endpointUrl}?time=${avoidCacheToken}`;
  const response = await fetch(urlWithParams, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.json();
}

function decideEndpointUrl(labId: string, isSuperAdmin?: boolean) {
  if (isSuperAdmin) {
    return `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/labs/completedAnalysesCount`;
  } else {
    return `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/labs/${labId}/completedAnalysesCount`;
  }
}

async function getToken() {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    throw new Error('Authentication is required');
  }
  return currentUser.getIdToken(false);
}
