import * as React from 'react';
import { daysLeft } from '../../selectors/analyses';
import {
  city as cityColumn,
  companyName as companyNameColumn,
  country as countryColumn,
  created as createdColumn,
  days as daysColumn,
  labNote as labNoteColumn,
  labOffer as labOfferColumn,
  probeTaker as probeTakerColumn,
  probeType as probeTypeColumn,
  resultOn as resultOnColumn,
  crop as cropColumn,
  field as fieldColumn,
  lab as labColumn,
} from './columns';
import Table from './Table';
import { Analysis, Profile } from '../../flowTypes';
import {
  analysisCellClassNameBasedOnDaysCount,
  analysisRowClassNameBasedOnDaysCount,
} from './utils';

const ZERO = 0;

type Props = {
  onClickAnalysis: (arg0: Analysis) => void;
  onFilterChange: (...args: Array<any>) => any;
  analyses: Array<Analysis>;
  isLoading: boolean;
  profile: Profile;
};

export default class InboxTable extends React.Component<Props> {
  render() {
    const {
      profile: { isSuperAdmin },
      profile,
      ...restProps
    } = this.props;

    const columns = [
      labOfferColumn,
      {
        ...probeTypeColumn,
        Cell: probeTypeColumn.makeCell(profile),
      },
      {
        ...daysColumn,
        Cell: ({ value, original: analysis }) => {
          const leftDaysCount = daysLeft(analysis);
          return <span className={analysisCellClassNameBasedOnDaysCount(leftDaysCount)}>{leftDaysCount} T</span>;
        },
      },
      cropColumn,
      fieldColumn,
      probeTakerColumn,
      resultOnColumn,
      createdColumn,
      companyNameColumn,
      cityColumn,
      countryColumn,
      labNoteColumn,
    ];

    if (isSuperAdmin) {
      columns.splice(ZERO, ZERO, labColumn);
    }

    return (
      <Table
        columns={columns}
        getTrProps={(state, rowInfo, column, instance) => ({
          className: analysisRowClassNameBasedOnDaysCount(daysLeft(rowInfo.original)),
        })}
        {...restProps}
      />
    );
  }
}
