import { merge, dissoc } from 'ramda';
import { combineReducers } from 'redux';

import { firebaseStateReducer } from 'react-redux-firebase';
import { firestoreReducer, actionTypes } from 'redux-firestore';

import { get } from 'lodash-es';
import { FilterMode } from '../selectors/filters';

const { GET_SUCCESS, LISTENER_RESPONSE, CLEAR_DATA } = actionTypes;
const ZERO = 0;
const ONE = 1;

function byIds(state = {}, action) {
  switch (action.type) {
    case GET_SUCCESS:
    case LISTENER_RESPONSE: {
      if (!action.payload || !action.payload.ordered) {
        return state;
      }

      if (!action.meta.byIds) {
        return state;
      }

      const byIds = action.meta.byIds;
      return {
        ...state,
        [byIds]: action.payload.data ? Object.keys(action.payload.data) : [],
      };
    }
    case CLEAR_DATA:
      // support keeping data when logging out - #125
      if (action.preserve) {
        // return pick(state, action.preserve); // pick returns a new object
      }
      return state;
    default:
      return state;
  }
}

const reviewRequested = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/requestReview':
      return !!payload.value;
    default:
      return state;
  }
};

const DEFAULT_ITEMS_LIMIT = 20;

const filters = (state = {}, { type, payload: filters, options }) => {
  switch (type) {
    case 'app/changeFilters': {
      const merge = get(options, 'merge', true);

      // reset some props if needed
      const hasOrderBy = 'orderBy' in filters;
      const hasQuery = 'query' in filters;
      const hasStartAfter = 'startAfter' in filters;
      const hasPage = 'page' in filters;
      const hasMode = 'mode' in filters;
      const hasLimit = 'limit' in filters;
      const mode = get(filters, 'mode', FilterMode.open.enumKey);

      if (!hasQuery && (hasOrderBy || hasMode)) {
        filters.query = '';
      }
      if (!hasPage && (hasStartAfter || hasQuery || hasOrderBy || hasMode)) {
        filters.page = 0;
      }
      if (!hasStartAfter && (hasPage || hasQuery || hasOrderBy || hasMode)) {
        filters.startAfter = null;
      }

      // set limit
      if (!hasLimit && hasMode) {
        const filterMode = FilterMode.enumValueOf(mode);
        filters.limit = filterMode === FilterMode.open || filterMode === FilterMode.inbox ? null : DEFAULT_ITEMS_LIMIT;
      }

      return merge ? { ...state, ...filters } : filters;
    }
    default:
      return state;
  }
};

const openCompanyId = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/addCompany':
    case 'app/openCompany':
      return payload.company_id;
    default:
      return state;
  }
};

const editCompanyId = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/editCompany':
      return payload.company_id;
    default:
      return state;
  }
};

const fieldById = (state = {}, { type, payload }) => {
  switch (type) {
    case 'app/addField':
    case 'app/updateField':
      return merge(state, { [payload.field_id]: payload });
    case 'app/removeField':
      return dissoc(payload.object_id, state);
    default:
      return state;
  }
};

const openFieldId = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/addField':
    case 'app/openField':
      return payload.field_id;
    default:
      return state;
  }
};

const editFieldId = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/editField':
      return payload.field_id;
    default:
      return state;
  }
};

const editWaitTime = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/editWaitTime':
      return Object.keys(payload).length ? payload : null;
    default:
      return state;
  }
};

const editFertilizing = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/editFertilizing':
      return payload.fertilizing_uuid;
    default:
      return state;
  }
};

const editCommentId = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/editComment':
      return payload.comment_id;
    default:
      return state;
  }
};

export const analysisWarehouse = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/analysisWarehouse':
      return payload.warehouse;
    default:
      return state;
  }
};

export const analysisField = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/analysisField':
      return payload.id;
    default:
      return state;
  }
};

export const analysisFromField = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/analysisFromField':
      return !!payload.value;
    default:
      return state;
  }
};

let oldFilterMode = FilterMode.open.enumKey; // hack to get access to current filters.state in the store
const hasMoreAnalyses = (state = {}, { type, meta, payload }) => {
  let newFilterMode;
  switch (type) {
    case 'app/changeFilters': {
      newFilterMode = get(payload, 'filters.mode');

      if (oldFilterMode !== newFilterMode) {
        oldFilterMode = newFilterMode;
        return state;
      }

      const result = {
        ...state,
        [newFilterMode]: true,
      };
      oldFilterMode = newFilterMode;
      return result;
    }
    case LISTENER_RESPONSE: {
      if (meta.requestId !== 'analysis-list' || !meta.filterMode) {
        return state;
      }

      newFilterMode = meta.filterMode;

      const limit = get(meta, 'limit');
      return {
        ...state,
        [newFilterMode]: !!limit && limit <= get(payload, 'ordered.length', ZERO),
      };
    }
    default:
      return state;
  }
};

const selectedLab = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/selectedLab':
      return payload.lab;
    default:
      return state;
  }
};

const selectedOffer = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/selectedOffer':
      return payload.offer;
    default:
      return state;
  }
};

const app = (state = null, { type, payload }) => {
  switch (type) {
    case 'app/versionChange':
      return merge(state, { version: payload.version });
    case 'app/deleteVersion':
      return {};
    default:
      return state;
  }
};

const owner = (state = {
  isFetching: false,
  error: false,
  errorMessage: '',
  user: {},
}, { type, payload, meta, error }) => {
  switch (type) {
    case 'app/register':
    case 'app/login':
      if (meta.done && !error) {
        return { isFetching: false, error: false, user: payload };
      }

      // Handle the error
      if (meta.done && error) {
        return { isFetching: false, error: true, errorMessage: payload, user: {} };
      }

      return {
        isFetching: true,
        error: false,
        user: {},
      };
    case 'app/accountEdit':
    case 'app/changePassword':
      if (meta.done && !error) {
        return { isFetching: false, error: false, user: payload };
      }

      // Handle the error
      if (meta.done && error) {
        // Return an error, but keep the old user object
        return { isFetching: false, error: true, errorMessage: payload, user: { ...state.user } };
      }

      return {
        isFetching: true,
        error: false,
        user: { ...state.user },
      };
    default:
      return state;
  }
};

const slider = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case 'app/sliderSeen':
      return {
        ...state,
        [`${payload.type}_${payload.version}`]: payload.seen,
      };
    default:
      return state;
  }
};

const search = (state: any = {}, { type, payload, meta }) => {
  switch (type) {
    case 'app/searchApiRequest':
      return {
        ...state,
        requesting: true,
      };
    case 'app/searchApiResponse':
      return {
        ...state,
        requesting: false,
        loaded: true,
        ...payload,
      };
    case 'app/searchApiResponseError':
      return {
        ...state,
        requesting: false,
        loaded: false,
        error: payload,
      };
    case 'app/addFoundKeys': {
      const { nbPages, page } = meta;

      return {
        ...state,
        foundKeys: [...(state.foundKeys || []), ...payload],
        hasMoreAnalyses: page + ONE < nbPages,
      };
    }
    case 'app/clearFoundKeys':
      return {
        ...state,
        foundKeys: undefined,
        hasMoreAnalyses: true,
      };
    default:
      return state;
  }
};

const map = (state = { filters: {} }, { type, payload, options }) => {
  switch (type) {
    case 'app/changeMapFilters': {
      const filters = payload;
      const merge = get(options, 'merge', true);

      return { ...state, filters: merge ? { ...state.filters, ...filters } : filters };
    }
    case 'app/setMapView':
      return { ...state, center: payload.center, zoom: payload.zoom };
    case 'app/setCurrentLocation':
      return { ...state, currentLocation: payload };
    default:
      return state;
  }
};

const menuOpen = (state = false, { type, payload, meta }) => {
  switch (type) {
    case 'app/toggleMenu':
      return payload === undefined ? !state : !!payload;
    default:
      return state;
  }
};

const appReducer = combineReducers({
  slider,
  filters,
  openCompanyId,
  editCompanyId,
  fieldById,
  selectedLab,
  selectedOffer,
  analysisFromField,
  hasMoreAnalyses,
  analysisField,
  analysisWarehouse,
  openFieldId,
  editFieldId,
  editWaitTime,
  editCommentId,
  editFertilizing,
  app,
  owner,
  reviewRequested,
  search,
  map,
  menuOpen,

  firebase: firebaseStateReducer,

  firestore: firestoreReducer,
  byIds,
  // byIds,
});

export default (state, action) => {
  let newState = state;
  if (action.type === 'app/logout') {
    newState = {
      slider: state.slider,
    };
  }

  return appReducer(newState, action);
};
