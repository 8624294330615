import * as React from 'react';
import ReactDOM from 'react-dom';
import { identity } from 'lodash-es';

const ONE = 1;

export default class MapControl extends React.Component<{
  children: React.ReactNode;
  position: string;
  map: google.maps.Map;
}> {
  controlDiv?: Element;
  divIndex?: number;

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillMount() {
    this.controlDiv = document.createElement('div');
    this.divIndex = this.props.map.controls[(window as any).google.maps.ControlPosition[this.props.position]].push(this.controlDiv) - ONE;
  }

  componentWillUnmount() {
    if (this.divIndex !== undefined) {
      this.props.map.controls[(window as any).google.maps.ControlPosition[this.props.position]].removeAt(this.divIndex);
    }
  }

  render() {
    if (!this.controlDiv) {
      throw new Error('controlDiv is not initialized yet');
    }
    return ReactDOM.createPortal(
      this.props.children,
      this.controlDiv,
    );
  }
}

export const BOTTOM = 'BOTTOM';
export const BOTTOM_CENTER = 'BOTTOM_CENTER';
export const BOTTOM_LEFT = 'BOTTOM_LEFT';
export const BOTTOM_RIGHT = 'BOTTOM_RIGHT';
export const CENTER = 'CENTER';
export const LEFT = 'LEFT';
export const LEFT_BOTTOM = 'LEFT_BOTTOM';
export const LEFT_CENTER = 'LEFT_CENTER';
export const LEFT_TOP = 'LEFT_TOP';
export const RIGHT = 'RIGHT';
export const RIGHT_BOTTOM = 'RIGHT_BOTTOM';
export const RIGHT_CENTER = 'RIGHT_CENTER';
export const RIGHT_TOP = 'RIGHT_TOP';
export const TOP = 'TOP';
export const TOP_CENTER = 'TOP_CENTER';
export const TOP_LEFT = 'TOP_LEFT';
export const TOP_RIGHT = 'TOP_RIGHT';

type ControlContainerProps = {
  children?: React.ReactNode;
};
export const ControlContainer = ({ children }: ControlContainerProps) => (
  <div className="control-container gmnoprint">
    {children}
  </div>
);

type ControlButtonProps = {
  children?: React.ReactNode;
  className?: string;
  onClick: () => void;
};
export const ControlButton = ({ children, className, ...restProps }: ControlButtonProps) => (
  <div className="control-button-container gm-style-mtc">
    <div
      role="button"
      tabIndex={0}
      draggable="false"
      className={['control-button', className].filter(identity).join(' ')}
      {...restProps}
    >
      {children}
    </div>
  </div>
);
