import * as React from 'react';
import { mergeWith, values } from 'lodash-es';
import Page from '../Page/Page';
import firebase from 'firebase';
import { Loading } from '../Loading/Loading';
import './style.css';
import NavigationBar from '../NavigationBar/NavigationBar';
import { LinkButton } from '../Common/LinkButton';

type State = {
  password: string;
  newPassword: string;
  errors: any;
  inProgress: boolean;
  done: boolean;
};

export default class ChangePassword extends React.Component<{
  children?: React.ReactNode;
}, State> {
  state: State = {
    password: '',
    newPassword: '',
    errors: {},
    inProgress: false,
    done: false,
  };

  private onSubmit(e) {
    e.preventDefault();

    const errors = this.validate();
    if (errors) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: {} });
    }

    const user = firebase.auth().currentUser;
    if (!user || !user.email) {
      throw new Error('Failed to get the currentUser.');
    }

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      this.state.password,
    );
    this.setState({ inProgress: true });
    user.reauthenticateWithCredential(credential)
      .then(() => {
        return user.updatePassword(this.state.newPassword);
      })
      .then(() => this.setState({ inProgress: false, done: true }))
      .catch((e) => {
        this.setState({ inProgress: false, errors: mergeWith({}, this.state.errors, this.processCallError(e)) });
      });
  }

  private validate() {
    let errors = null;
    if (!this.state.password) {
      errors = mergeWith(errors, {
        password: {
          empty: 'Passwort fehlt',
        },
      });
    }
    if (!this.state.newPassword) {
      errors = mergeWith(errors, {
        newPassword: {
          empty: 'Passwort fehlt',
        },
      });
    }
    return errors;
  }

  private processCallError(e) {
    switch (e.code) {
      case 'auth/wrong-password':
        return { password: { wrong: 'Ungültiges Passwort' } };
      case 'auth/weak-password':
        return { newPassword: { weak: 'Passwort muss mindestens 6 Zeichen lang sein.' } };
      default:
        console.error(e);
        return { unknown: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es erneut.' };
    }
  }

  render() {
    return (
      <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column" before={<NavigationBar/>}>
        <div className="change-password align-self-center">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Passwort ändern</h4>
              {this.renderForm()}
            </div>
          </div>
        </div>
      </Page>
    );
  }

  private renderForm() {
    const { inProgress, done, errors } = this.state;

    if (inProgress) {
      return <Loading isLoading={true}/>;
    }

    if (done) {
      return this.renderDoneState();
    }

    if (errors.unknown) {
      return <p className="text-danger">{errors.unknown}</p>;
    }

    const passwordErrors = values(errors.password);
    const newPasswordErrors = values(errors.newPassword);
    return (
      <form>
        <div className={'form-group ' + (passwordErrors.length && ' has-danger ')}>
          <label className="form-control-label" htmlFor="password">Aktuelles Passwort:</label>
          <input
            type="password"
            id="password"
            onChange={e => this.setState({ password: e.target.value })}
            className="form-control"
          />
          {
            passwordErrors.length
              ? <div className="form-control-feedback">{passwordErrors.join('; ')}</div>
              : null
          }
        </div>
        <div className={'form-group ' + (newPasswordErrors.length && ' has-danger ')}>
          <label className="form-control-label" htmlFor="newPassword">Neues Passwort:</label>
          <input
            type="password"
            id="newPassword"
            onChange={e => this.setState({ newPassword: e.target.value })}
            className="form-control"
          />
          {
            newPasswordErrors.length
              ? <div className="form-control-feedback">{newPasswordErrors.join('; ')}</div>
              : null
          }
        </div>
        <div className="d-flex">
          <LinkButton to="/" className="btn btn-secondary">Abbrechen</LinkButton>
          <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary ml-auto">Passwort ändern</button>
        </div>
      </form>
    );
  }

  private renderDoneState() {
    return (
      <div>
        <p>Ihr Passwort wurde geändert.</p>
        <LinkButton to="/" className="btn btn-secondary">Schließen</LinkButton>
      </div>
    );
  }
}
