import * as React from 'react';
import {
  cancelledOn as cancelledOnColumn,
  city as cityColumn,
  companyName as companyNameColumn,
  country as countryColumn,
  lab as labColumn,
  labNote as labNoteColumn,
  labOffer as labOfferColumn,
  number as numberColumn,
  probeTaker as probeTakerColumn,
  probeType as probeTypeColumn,
  resultOn as resultOnColumn,
  status as statusColumn,
} from './columns';
import Table from './Table';
import type { Analysis, Profile } from '../../flowTypes';
import Status from './Status';

const ZERO = 0;
const ONE = 1;

type Props = {
  onClickAnalysis: (arg0: Analysis) => void;
  onFilterChange: (...args: Array<any>) => any;
  analyses: Array<Analysis>;
  isLoading: boolean;
  profile: Profile;
  onAccept: (arg0: Analysis) => void;
};

export default class CancelledTable extends React.Component<Props> {
  render() {
    const {
      profile: { isSuperAdmin },
      profile,
      onAccept,
      ...restProps
    } = this.props;

    const columns = [
      numberColumn,
      labOfferColumn,
      {
        ...probeTypeColumn,
        Cell: probeTypeColumn.makeCell(profile),
      },
      probeTakerColumn,
      resultOnColumn,
      companyNameColumn,
      cityColumn,
      countryColumn,
      labNoteColumn,
      cancelledOnColumn,
      {
        ...statusColumn,
        Cell: ({ original: analysis }) =>
                <div className="status">
                  <Status analysis={analysis}/>
                  {
                    analysis.lab_accepted === false
                      ? <div>
                        <button
                          className="btn btn-primary" onClick={(e) => {
                            onAccept(analysis);
                            e.stopPropagation();
                          }} title="Annehmen">
                          <i className="fa fa-check"/> Annehmen
                        </button>
                      </div>
                      : null
                  }
                </div>,
      },
    ];

    if (isSuperAdmin) {
      columns.splice(ONE, ZERO, labColumn);
    }

    return (
      <Table
        columns={columns}
        {...restProps}
      />
    );
  }
}
