import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { debounce, get, without } from 'lodash-es';
// var Modal = require('react-bootstrap-modal')
import fire, { modifiedProps } from '../../data/firebase';
import FileSection from './FileSection';
import { ConfirmDialog } from '../Dialog/Dialog';
import './style.css';
import { convertDMS } from '../../utils/coordinates';
import moment from 'moment/moment';
import AnalysisResultUploader from '../AnalysisResultUploader/AnalysisResultUploader';
import { daysLeft, getPosition } from '../../selectors/analyses';
import { FilterMode } from '../../selectors/filters';
import { compose } from 'redux';
import withToken from '../Firebase/withToken';
import Log from './Log';
import { getConfig, render } from './Form';
import { getCompost, getCropRow, getDatabase, getNote, getProbeForm, getResultFor, getService } from './OldSections';
import AnalysisCostSection from './AnalysisCostSection';
import { setProbeTaken } from '../../data/analysis';
import Status from '../Analyses/Status';
import { classes } from '../../utils/dom';
import { toDate } from '../../utils/Date';
import { Analysis, AnalysisState } from '../../flowTypes';
import { analysisCellClassNameBasedOnDaysCount } from '../Analyses/utils';
import { getAnalysisTypeLongName } from '../../utils/analyses';

const MODAL_HEADER_HEIGHT = 81;
const MODAL_FOOTER_HEIGHT = 86;
const TOP_MARGIN = 30;
const BOTTOM_MARGIN = 30;
const BORDERS_SUM_HEIGHT = 2;

type Props = {
  open: boolean;
  analysis: Analysis;
  onHide: () => void;
  onAccept: (analysis: Analysis) => void;
  onReject: (analysis: Analysis) => void;
  onMark: (analysis: Analysis) => void;
  onUnmark: (analysis: Analysis) => void;

  firestore: any;
  mode: any;
  token: any;
  currentLocation: any;
  log: any;
  profile: any;
};

type State = {
  open: boolean;
  deleteAnalysis: boolean;
};

class AnalysisModal extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      deleteAnalysis: false,
    };

    this.labStateChange = this.labStateChange.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  changeState(event) {
    const { analysis } = this.props;

    fire.firestore().collection('analyses')
      .doc(analysis.key)
      .set({
        state: parseInt(event.target.value, 10),
        ...modifiedProps(),
      }, { merge: true });
  }

  labStateChange(event) {
    const { analysis } = this.props;

    fire.firestore().collection('analyses').doc(analysis.key)
      .set({
        labState: parseInt(event.target.value, 10),
        ...modifiedProps(),
      }, { merge: true });
  }

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ open: props.open });
  }

  deleteAndClose() {
    const { analysis, onHide, firestore } = this.props;
    firestore.delete({
      collection: 'analyses',
      doc: analysis.key,
    })
      .then(() => {
        onHide();
      }, (error) => {
        console.error(error);
        alert(error && error.message);
      });
  }

  render() {
    const {
      log: { log },
      onHide,
      profile,
      onAccept,
      onReject,
      onMark,
      onUnmark,
      firestore,
      mode,
      token,
    } = this.props;

    const analysis = this.props.analysis as any;
    const { formConfig, form, analysisCost } = analysis;

    const filterMode = FilterMode.enumValueOf(mode);

    const closeModal = () => {
      this.setState({ open: false });
      onHide && onHide();
    };

    if (!this.state.open) {
      return false;
    }

    const isSuperAdmin = profile.isSuperAdmin;
    const isFormConfig = !!formConfig;
    const position = getPosition(analysis);

    const googleAnalysisPosition = position.latitude + ',' + position.longitude;
    const renderedFormConfig = render(profile.language, form, formConfig);

    const cardClass = 'col section';
    const sections = without([
      getStatusSection(cardClass, analysis),
      <div className="w-100" key="splitter-1" />,
      getFieldSection(cardClass, analysis),

      ...isFormConfig
        ? [
            renderedFormConfig.crop ? makeSection(cardClass, renderedFormConfig.crop.title, renderedFormConfig.crop.body) : null,
            renderedFormConfig.offers ? makeSection(cardClass, renderedFormConfig.offers.title, renderedFormConfig.offers.body) : null,
            getDateSection(cardClass, analysis, profile, formConfig),
        <div className="w-100" key="splitter-2"/>,
        ...without(renderedFormConfig.order, 'crop', 'offers').map(key => makeSection(cardClass, renderedFormConfig[key].title, renderedFormConfig[key].body)),
          ]
        : [
            makeOldSection(cardClass, getCropRow(analysis)),
            makeOldSection(cardClass, getService(analysis)),
            getDateSection(cardClass, analysis, profile, formConfig),
            makeOldSection(cardClass, getCompost(analysis)),
            makeOldSection(cardClass, getDatabase(analysis)),
            makeOldSection(cardClass, getResultFor(analysis)),
            makeOldSection(cardClass, getProbeForm(analysis)),
            makeOldSection(cardClass, getNote(analysis)),
          ],

      <div className="w-100" key="splitter-3"/>,
      getBillingAddressSection(cardClass, analysis),
      getOrderingParty(cardClass, analysis),
      getCreatedBy(cardClass, analysis),

      <div className="w-100" key="splitter-4"/>,
      getLogSection(cardClass, log),
      <LabNoteSection analysis={analysis} firestore={firestore} cardClass={cardClass} key="labNoteSection" />,
      analysisCost ? <AnalysisCostSection className={cardClass} analysisCost={analysisCost}/> : null,
    ], null) as JSX.Element[];

    let resultUploader;
    return (
      <div>

        <Modal
          show={this.state.open}
          onHide={closeModal}
          aria-labelledby="ModalHeader"
          className={'modal-xl analysis-modal ' + (filterMode === FilterMode.inbox ? 'inbox' : '')}
        >
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'><strong><span style={{ fontSize: 'x-large' }}><span className="mr-2">{analysis.analyse_number}</span>
              {getAnalysisTypeLongName(analysis)}</span></strong>
              {isSuperAdmin ? <SuperAdminKeyInput analysis={analysis}/> : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
            maxHeight: window.innerHeight - MODAL_HEADER_HEIGHT - MODAL_FOOTER_HEIGHT - TOP_MARGIN - BOTTOM_MARGIN - BORDERS_SUM_HEIGHT,
          }}>

            <AnalysisResultUploader analysis={analysis} dropZoneRef={(node) => { resultUploader = node; }}>
              <div className="row" key="sections-div">{sections.map((section, i) => React.cloneElement(section, { key: i }))}</div>
              <FileSection analysis={analysis} token={token} key="file-section"/>
              <button className="btn btn-primary" onClick={() => resultUploader.open()} key="upload-button">Ergebnis als PDF hochladen</button>
            </AnalysisResultUploader>

          </Modal.Body>
          <Modal.Footer>
            {isSuperAdmin &&
             <button
               className='delete-button btn btn-danger'
               onClick={() => this.setState({ deleteAnalysis: true })}
             >
               <i className="fa fa-trash"/><span className="hidden-md-down"> Analyse löschen</span>
             </button>
            }
            {
              analysis.state == AnalysisState.Inbox && analysis.lab_marked_by == null
                ? <button
                  className="mark-button btn btn-secondary"
                  onClick={onMark.bind(null, analysis)}
                  title="Markieren"
                >
                  <i className="fa fa-check-square"/><span className="hidden-md-down"> Markieren</span>
                </button>
                : null
            }
            {
              analysis.state == AnalysisState.Inbox && analysis.lab_marked_by != null
                ? <button
                  className="mark-button btn btn-primary"
                  onClick={onUnmark.bind(null, analysis)}
                  title="Markierung löschen"
                >
                  <span className="name">{analysis.lab_marked_by.name}</span>
                  <i className="fa fa-times"/><span className="hidden-md-down"> Markierung löschen</span>
                </button>
                : null
            }
            {
              analysis.state == AnalysisState.Inbox && analysis.lab_accepted === true && analysis.lab_probe_taken_by == null
                ? <button
                  className="mark-button btn btn-secondary"
                  onClick={() => setProbeTaken(firestore, profile, analysis, true)}
                  title="Probe ziehen"
                >
                  <i className="fa fa-clipboard-check"/><span className="hidden-md-down"> Probe ziehen</span>
                </button>
                : null
            }
            {
              analysis.state == AnalysisState.Inbox && analysis.lab_accepted === true && analysis.lab_probe_taken_by != null
                ? <button
                  className="mark-button btn btn-secondary"
                  onClick={() => setProbeTaken(firestore, profile, analysis, false)}
                  title="Probeziehung aufheben"
                >
                  <span className="name">{analysis.lab_probe_taken_by.name}</span>
                  <i className="fa fa-times"/><span className="hidden-md-down"> Probeziehung aufheben</span>
                </button>
                : null
            }
            {
              analysis.state == AnalysisState.Inbox
                ? <button
                  className="navigate-button btn btn-secondary"
                  onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${googleAnalysisPosition}`, 'blank')}
                  title="Navigieren"
                >
                  <i className="fa fa-car"/><span className="hidden-md-down"> Navigieren</span>
                </button>
                : null
            }
            {
              (analysis.state == AnalysisState.Inbox && (analysis.lab_accepted == null || analysis.lab_accepted === true))
                ? <button
                  className="reject-button btn btn-danger"
                  onClick={onReject.bind(null, analysis)}
                  title="Ablehnen"
                >
                  <i className="fa fa-times"/> Ablehnen
                </button>
                : null
            }
            {
              (analysis.state == AnalysisState.Inbox && analysis.lab_accepted == null) || (analysis.state == AnalysisState.Cancelled && analysis.lab_accepted === false)
                ? <button
                  className="accept-button btn btn-primary"
                  onClick={onAccept.bind(null, analysis)}
                  title="Annehmen"
                >
                  <i className="fa fa-check"/> Annehmen
                </button>
                : null
            }
            {filterMode !== FilterMode.inbox ? <Modal.Dismiss className='btn btn-primary-outline close-button'>Fertig</Modal.Dismiss> : null}
          </Modal.Footer>
        </Modal>
        {isSuperAdmin
          ? <ConfirmDialog
          title="Analyse löschen"
          show={this.state.deleteAnalysis}
          onClose={(value) => {
            if (value == 'yes') {
              this.deleteAndClose();
            }
            this.setState({ deleteAnalysis: false });
          }
          }>
          Sind Sie sicher?
        </ConfirmDialog>
          : null}
      </div>
    );
  }
}

function makeOldSection(cardClass, section) {
  if (!section) {
    return null;
  }

  return <div className={cardClass}>

    <h4 className="card-title">{section.label}</h4>
    <div className="section-body">
      {section.stack.reduce((lines, line, i) => {
        if (line) {
          lines.push(line);
          lines.push(<br key={i}/>);
        }
        return lines;
      }, [])}
    </div>
  </div>;
}

function makeSection(cardClass, header, body) {
  if (!body) {
    return null;
  }

  return <div className={cardClass}>
    <h4 className="card-title">{header}</h4>
    <div className="section-body">
      {body}
    </div>
  </div>;
}

function getStatusSection(cardClass, analysis) {
  return <div className={classes('status-section', cardClass)}>
    <span className="card-title">Status</span><Status analysis={analysis}/>
  </div>;
}

function getDateSection(cardClass, analysis, profile, formConfig) {
  const { created, form } = analysis;
  const isFormConfig = !!formConfig;
  const resultOn = toDate(get(analysis, 'form.offers.result_on'));
  const createdAt = toDate(created);
  const probeDate = toDate(form.offers.probe_date);
  const leftDaysCount = daysLeft(analysis);

  return <div className={classes('date-section', cardClass)}>
    <h4 className="card-title"></h4>
    <div className="section-body">
      <table>
        <tbody>
        <tr>
          <td className="text-right">
            Ergebnis bis
          </td>
          <td>
            <strong>{moment(resultOn).format('DD.MM.YYYY HH:mm')}</strong> (<span
            className={analysisCellClassNameBasedOnDaysCount(leftDaysCount)}>{leftDaysCount} T</span>)
          </td>
        </tr>
        <tr>
          <td className="text-right">Bestellung vom</td>
          <td><strong>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</strong></td>
        </tr>
        {isFormConfig &&
         <tr>
           <td className="text-right">{get(getConfig(profile.language, formConfig), 'blocks.offers.fields.probe_date.label')}</td>
           <td><strong>{moment(probeDate).format('DD.MM.YYYY')}</strong></td>
         </tr>
        }
        </tbody>
      </table>
    </div>
  </div>;
}

const DEFAULT_FIELD_AREA = 0;
const COORDINATES_DECIMAL_POINTS = 4;

function getFieldSection(cardClass, { field }) {
  return <div className={cardClass}>
    <h4 className="card-title">Feld</h4>
    <div className="section-body">
      <span className="">{field.name}</span><br/>
      {!field.stock ? [(field.size || DEFAULT_FIELD_AREA) + ' ha', <br key={1}/>] : null}
      {
        field.position != null &&
        <div className="form-field">
          <span className="name">GPS</span>
          <span className="value"><a
             href={'http://www.google.com/maps/place/' + field.position.latitude + ',' + field.position.longitude}
             target="_blank" rel="noreferrer">
            {field.position.latitude.toFixed(COORDINATES_DECIMAL_POINTS)},{field.position.longitude.toFixed(COORDINATES_DECIMAL_POINTS)} ({convertDMS(field.position.latitude, field.position.longitude)})
          </a></span>
        </div>
      }
      {!!field.notes && <p>Info: {field.notes}</p>}
    </div>
  </div>;
}

function getBillingAddressSection(cardClass, { invoice_address }) {
  return <div className={cardClass}>
    <h4 className="card-title">Rechnungsaddresse</h4>
    <div className="section-body">
      <address>
        <span className="modal-block-first-line">{invoice_address.name}</span><br/>
        {invoice_address.street}<br/>
        {invoice_address.zip}{' '}{invoice_address.city}<br/>
        {invoice_address.country}<br/>
        {invoice_address.tel != null &&
         [
          <a key="link" href={'tel:' + invoice_address.tel}>{invoice_address.tel}</a>,
          <br key="break"/>,
         ]}
        {invoice_address.email != null &&
         [
          <a key="link" href={'mailto:' + invoice_address.email}>{invoice_address.email}</a>,
          <br key="break"/>,
         ]}
      </address>
    </div>
  </div>;
}

function getOrderingParty(cardClass, { company, invoice_address }) {
  return <div className={cardClass}>
    <h4 className="card-title">Auftraggeber</h4>
    <div className="section-body">
      <address>
        <span className="modal-block-first-line">{company.name}</span><br/>
        {company.street}<br/>
        {company.zip}{' '}{company.city}<br/>
        {company.country}<br/>
        {company.tel != null &&
         [
          <a key="link" href={'tel:' + invoice_address.tel}>{invoice_address.tel}</a>,
          <br key="break"/>,
         ]}
        {company.email != null &&
         [
          <a key="link" href={'mailto:' + invoice_address.email}>{invoice_address.email}</a>,
          <br key="break"/>,
         ]}
      </address>
    </div>
  </div>;
}

function getCreatedBy(cardClass, { created_by }) {
  return <div className={cardClass}>
    <h4 className="card-title">Bestellt von</h4>
    <div className="section-body">
      <address>
        <span className="modal-block-first-line">{created_by.name}</span><br/>
        {created_by.phoneNumber != null &&
         [
          <a key="link" href={'tel:' + created_by.phoneNumber}>{created_by.phoneNumber}</a>,
          <br key="break"/>,
         ]}
        {created_by.email != null &&
         [
          <a key="link" href={'mailto:' + created_by.email}>{created_by.email}</a>,
          <br key="break"/>,
         ]}
      </address>
    </div>
  </div>;
}

function getLogSection(cardClass, log) {
  return <div className={cardClass}>
    <h4 className="card-title">Protokoll</h4>
    <div className="section-body">
      <Log log={log}/>
    </div>
  </div>;
}

type LabNoteSectionProps = {
  analysis: Analysis;
  firestore: any;
  cardClass: string;
};

type LabNoteSectionState = {
  text: string;
};

const SAVE_DEBOUNCE_PERIOD = 5000;

class LabNoteSection extends React.Component<LabNoteSectionProps, LabNoteSectionState> {
  debouncedSave: () => void;

  constructor(props) {
    super(props);
    const lab_note = (this.props.analysis as any).lab_note;

    this.state = {
      text: lab_note,
    };
    this.debouncedSave = debounce(this.save.bind(this), SAVE_DEBOUNCE_PERIOD);
  }

  save() {
    const { analysis: { key }, firestore } = this.props;

    const analysisQuery = {
      collection: 'analyses',
      doc: key,
    };
    firestore.update(analysisQuery, {
      lab_note: this.state.text,
      ...modifiedProps(),
    });
  }

  componentWillUnmount() {
    const lab_note = (this.props.analysis as any).lab_note;
    if (lab_note !== this.state.text) {
      this.save();
    }
  }

  render() {
    const { cardClass } = this.props;
    return (
      <div className={cardClass}>
        <h4 className="card-title">Bemerkung</h4>
        <textarea className="note form-control" value={this.state.text || ''} onChange={(e) => {
          this.setState({ text: e.target.value }, () => this.debouncedSave());
        }}/>
      </div>
    );
  }
}

type SuperAdminKeyInputProps = {
  analysis: Analysis;
};
function SuperAdminKeyInput({ analysis }: SuperAdminKeyInputProps) {
  return <input className="form-control analysis-key-input" style={{ width: 'auto' }} value={analysis.key} readOnly={true} onClick={(e) => {
    (e.target as any).select();
    document.execCommand('Copy');
  }}/>;
}

type AnalysisModalExternalProps = {
  open: boolean;
  analysis: Analysis;
  onHide: () => void;
  onAccept: (analysis: Analysis) => void;
  onReject: (analysis: Analysis) => void;
  onMark: (analysis: Analysis) => void;
  onUnmark: (analysis: Analysis) => void;
};

export default compose<React.ComponentClass<AnalysisModalExternalProps>>(
  connect((state, ownProps) => {
    let { analysis } = ownProps;

    if (get(state, `firestore.data.analyses.${analysis.key}`)) {
      analysis = get(state, `firestore.data.analyses.${analysis.key}`);
    }
    return {
      analysis: analysis,
      profile: get(state, 'firebase.profile'),
      mode: get(state, 'filters.mode'),
      log: get(state, `firestore.data.logs.${analysis.key}`) || { log: [] },
      currentLocation: get(state, 'map.currentLocation'),
    };
  }),
  withToken,
  firestoreConnect(props => {
    const { analysis } = props;

    if (analysis) {
      return [{
        collection: 'analyses',
        doc: analysis.key,
      }, {
        collection: 'logs',
        doc: analysis.key,
      }];
    }

    return [];
  }),
)(AnalysisModal);
