import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get, mergeWith, toPairs, values } from 'lodash-es';
import { Loading } from '../Loading/Loading';
import './style.css';
import type { Profile } from '../../flowTypes';
import { firestoreConnect } from 'react-redux-firebase';
import { createNewLabUser } from '../../data/user';
import withToken from '../Firebase/withToken';
import Modal from 'react-bootstrap-modal';

const MIN_PASSWORD_LENGTH = 6;
const ONE = 1;

type FormType = {
  email?: string;
  displayName?: string;
  password?: string;
  confirmPassword?: string;
  firstname?: string;
  lastname?: string;
  labId?: string;
  isLabAdminUser?: boolean;
};

type State = {
  form: FormType;
  errors: any;
  requestState: string;
  requestResponse?: Record<string, any> | null;
  open: boolean;
};

class CreateLabUser extends React.Component<{
  children?: React.ReactNode;
  profile: Profile;
  firebase: Record<string, any>;
  labs: Record<string, any>;
  token: string;
  onHide: (...args: Array<any>) => any;
  firestore: Record<string, any>;
}, State> {
  state: State = {
    form: {
      email: '',
      displayName: '',
      password: '',
      confirmPassword: '',
      firstname: '',
      lastname: '',
      labId: '',
      isLabAdminUser: false,
    },

    errors: {},
    requestState: 'none',
    requestResponse: null,
    open: true,
  };

  constructor(props) {
    super(props);

    this.state.form.labId = this.props.profile.labId || '';
  }

  onSubmit(e) {
    e.preventDefault();

    const errors = this.validate();
    if (errors) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: {} });
    }

    const {
      profile,
      token,
      firestore,
    } = this.props;
    const {
      form,
    } = this.state;
    this.setState({ requestState: 'loading' });

    createNewLabUser({
      ...form,
      language: profile.language,
    } as any, token)
      .then((o) => {
        this.setState({ requestState: 'done', requestResponse: o });
        return firestore.get({
          collection: 'users',
          doc: o.user.uid,
        }); // make sure that user is fetched
      })
      .catch((e) => {
        console.error(e);
        this.setState({ requestState: 'error', errors: mergeWith({}, this.state.errors, this.processCallError(e)) });
      });
  }

  validate() {
    let errors = null;
    if (!this.state.form.email) {
      errors = mergeWith(errors, {
        email: {
          empty: 'E-Mail fehlt',
        },
      });
    }
    if (!this.state.form.displayName) {
      errors = mergeWith(errors, {
        displayName: {
          empty: 'Name fehlt',
        },
      });
    }
    if (!/\s+/.exec(this.state.form.displayName || '')) {
      errors = mergeWith(errors, {
        displayName: {
          empty: 'Vor- oder Nachname fehlt',
        },
      });
    }
    if (!this.state.form.password) {
      errors = mergeWith(errors, {
        password: {
          empty: 'Passwort fehlt',
        },
      });
    } else if (this.state.form.password.length < MIN_PASSWORD_LENGTH) {
      errors = mergeWith(errors, {
        password: {
          weak: 'Passwort muss mindestens 6 Zeichen lang sein.',
        },
      });
    }
    if (this.state.form.password !== this.state.form.confirmPassword) {
      errors = mergeWith(errors, {
        confirmPassword: {
          empty: 'Passwörter stimmen nicht überein',
        },
      });
    }
    if (!this.state.form.labId) {
      errors = mergeWith(errors, {
        labId: {
          empty: 'Labor fehlt',
        },
      });
    }
    return errors;
  }

  processCallError(e) {
    switch (e.code) {
      case 'permission-denied':
        return { other: 'Sie haben keine Rechte einen Benutzer zu erstellen.' };
      case 'auth/email-already-in-use':
        return { email: { inuse: 'Ein Benutzer mit dieser E-Mail-Adresse existiert schon.' } };
      case 'auth/invalid-email':
        return { email: { invalid: 'Falsches E-Mail-Format' } };
      default:
        console.error(e);
        return { other: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es erneut.' };
    }
  }

  render() {
    const {
      onHide,
    } = this.props;

    const closeModal = () => {
      this.setState({ open: false });
      onHide && onHide();
    };

    return (
      <Modal
        show={this.state.open}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id='ModalHeader'>
            Neuen Benutzer erstellen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            this.state.requestState === 'loading'
              ? <Loading isLoading={true}/>
              : this.state.requestState === 'done'
                ? <div>
                {
                  this.state.requestResponse && this.state.requestResponse.isExistingUser
                    ? <p>Ein Benutzer mit dieser E-Mail-Adresse existiert schon. Zugriffsrechte wurden ihm zugewiesen.</p>
                    : <p>Ein neuer Benutzer wurde erstellt.</p>
                }
              </div>
                : this.state.errors.other
                  ? <div>
                  <p className="text-danger">{this.state.errors.other}</p>
                </div>
                  : this.renderForm()
          }
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => { closeModal(); }} className="btn btn-secondary ml-auto">
            schließen
          </button>
          {
            this.state.requestState === 'done'
              ? <button onClick={() => {
                this.setState({ errors: {}, requestState: 'none', form: { labId: this.state.form.labId } });
              }} className="btn btn-primary">Neuer Benutzer</button>
              : this.state.errors.other
                ? <button onClick={() => {
                  this.setState({ errors: {}, requestState: 'none' });
                }} className="btn btn-primary">zurück</button>
                : this.state.requestState === 'none'
                  ? <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary">Erstellen</button>
                  : null
          }
        </Modal.Footer>
      </Modal>
    );
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    const changes: FormType = {
      [name]: value,
    };

    if (name === 'displayName') {
      const [firstnamePart, lastnamePart] = value.split(/\s+/);
      changes.firstname = firstnamePart;
      changes.lastname = lastnamePart;
    }

    this.setState({
      form: {
        ...this.state.form,
        ...changes,
      },
    });
  }

  renderForm() {
    const customizedProps = (id, label, checkboxLabel?) => ({
      id: id,
      label: label,
      checkboxLabel,
      value: this.state.form[id],
      onChange: this.onChange.bind(this),
      errors: this.state.errors[id],
    });

    const {
      profile: { isSuperAdmin, isLabAdminUser },
      labs,
    } = this.props;

    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <Input {...customizedProps('displayName', 'Vor- und Nachname')}/>
        <Input {...customizedProps('email', 'E-Mail')}/>
        <Input type="password" {...customizedProps('password', 'Passwort')}/>
        <Input type="password" {...customizedProps('confirmPassword', 'Passwort wiederholen')}/>
        {
          isSuperAdmin
            ? <Input type="select" {...customizedProps('labId', 'Labor')}>
              <option value=''/>
              {toPairs(labs).filter(e => e[ONE]).map(([key, lab]) => <option key={key} value={key}>{lab.name}</option>)}
            </Input>
            : <input name="labId" value={this.state.form.labId} onChange={this.onChange.bind(this)} type="hidden"/>
        }
        {isSuperAdmin || isLabAdminUser
          ? <Input type="checkbox" {...customizedProps('isLabAdminUser', 'Administrator', 'hat das Recht Benutzer zu verwalten')}/>
          : null
        }
        {/* hack to get submit on enter press working */}
        <input type="submit" style={{ position: 'absolute', left: '-9999px', width: '1px', height: '1px', visibility: 'hidden' }} tabIndex={-1} />
      </form>
    );
  }
}

type InputType = {
  id: string;
  value: string | boolean;
  label: string;
  onChange: (event: React.SyntheticEvent) => void;
  errors?: Record<string, any>;
  type?: string;
  children?: React.ReactNode;
  checkboxLabel?: string;
};

function Input({ id, value, label, checkboxLabel, onChange, errors, type = 'text', children }: InputType) {
  const errorValues = values(errors);

  return <div className={'form-group ' + (errorValues.length && ' has-danger ')}>
    <label className="form-control-label" htmlFor={id}>{label}:</label><br/>
    {
      type === 'select'
        ? <select
          id={id}
          name={id}
          value={value as any}
          onChange={e => onChange(e)}
          className="form-control"
        >
          {children}
        </select>
        : <input
          type={type}
          id={id}
          name={id}
          value={value as any}
          checked={type === 'checkbox' ? (value as boolean) : undefined}
          onChange={e => onChange(e)}
          className="form-control filled-in"
        />
    }
    {type === 'checkbox' ? <label key={1} htmlFor={id}>{checkboxLabel}</label> : null}
    {
      errorValues.length
        ? <div className="form-control-feedback">{errorValues.join('; ')}</div>
        : null
    }
  </div>;
}

type CreateLabUserExternalProps = {
  onHide: () => void;
};

export default compose<React.ComponentClass<CreateLabUserExternalProps>>(
  connect((state) => ({
    profile: get(state, 'firebase.profile'),
    labs: get(state, 'firestore.data.labs'),
  })),
  firestoreConnect(),
  withToken,
)(CreateLabUser);
