import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { get, isEqual, keys, last, throttle } from 'lodash-es';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import AnalysisModal from '../AnalysisModal/AnalysisModal';
import { changeFilters, clearFirestoreData } from '../../actions/actions';
import { bindActionCreators, compose } from 'redux';
import { getAllAnalyses, getFilteredAnalysisKeys, getFilters } from '../../selectors/analyses';
import { FilterMode } from '../../selectors/filters';
import AnalysisResultUploader from '../AnalysisResultUploader/AnalysisResultUploader';
import './style.css';
import Page, { DefaultWrapper } from '../Page/Page';
import RejectAnalysisModal from './RejectAnalysisModal';
import { Loading } from '../Loading/Loading';
import SearchBox from './SearchBox';
import algoliasearchHelper from 'algoliasearch-helper';
import algoliasearch from 'algoliasearch';
import type { Analysis, Profile, Search } from '../../flowTypes';
import { addFoundKeys, clearFoundKeys } from '../../actions/search';
import InboxTable from './InboxTable';
import OpenTable from './OpenTable';
import ResultsTable from './ResultsTable';
import CancelledTable from './CancelledTable';
import NavigationBar from '../NavigationBar/NavigationBar';
import { setAcceptance, setMarked } from '../../data/analysis';
import { CompletedAnalysesCount } from './CompletedAnalysesCount';

// the wrapper needs to be defined outside of the render function or react will throw maximum update depth exceeded errors
type WrapperProps = { children: React.ReactNode };
const Wrapper = ({ children }: WrapperProps) => <DefaultWrapper><AnalysisResultUploader className="page-uploader">{children}</AnalysisResultUploader></DefaultWrapper>;

type Props = {
  filteredAnalyses: Array<Analysis>;
  analyses: Record<string, Analysis>;
  filters: Record<string, any>;
  hasMoreAnalyses: Record<string, any>;
  profile: Profile;
  search: Search;
  firestore: Record<string, any>;
  actions: Record<string, any>;
  hasMoreAnalysesSearch: boolean;
  lab: Record<string, any>;
  isLoading: boolean;
};

const SEARCH_THROTTLE_TIMEOUT = 500;
const INBOX_HITS_PER_PAGE = 1000;
const DEFAULT_HITS_PER_PAGE = 20;
const ONE = 1;
const ZERO = 0;
const NEGATIVE_ONE = -1;
const DEFAULT_PAGE_INDEX = 0;

class Analyses extends Component<Props, {
  analysisToReject: Analysis | void | null;
  analysisToOpen: Analysis | void | null;
}> {
  static contextTypes = {
    router: PropTypes.object,
  };

  client: algoliasearch;
  helper: algoliasearchHelper;
  throttledOnSearch: (arg0: string) => void;

  constructor(props) {
    super(props);

    this.state = {
      analysisToReject: null,
      analysisToOpen: null,
    };
    this.throttledOnSearch = throttle(this.onSearch.bind(this), SEARCH_THROTTLE_TIMEOUT);
    this.onClickAnalysis = this.onClickAnalysis.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onMark = this.onMark.bind(this);
    this.onUnmark = this.onUnmark.bind(this);
    this.onFilterChangeFromTable = this.onFilterChangeFromTable.bind(this);

    this.createSearchClient();
    this.createSearchHelper();
  }

  createSearchClient() {
    const {
      search: { appId, apiKey },
    } = this.props;
    this.client = algoliasearch(appId, apiKey);
  }

  createSearchHelper(props = this.props) {
    const {
      search: { analysesIndex },
      filters: { mode },
    } = props;

    const filterMode = FilterMode.enumValueOf(mode);

    if (this.helper) {
      this.helper.removeAllListeners();
    }

    this.helper = algoliasearchHelper(this.client, analysesIndex, {
      facets: ['state', 'key'],
      attributesToRetrieve: ['key'],
      hitsPerPage: filterMode === FilterMode.open || filterMode === FilterMode.inbox ? INBOX_HITS_PER_PAGE : DEFAULT_HITS_PER_PAGE,
    });
    this.helper.on('result', this.onSearchResult.bind(this));
  }

  onSearchResult(data) {
    const { addFoundKeys } = this.props.actions;
    const { hits } = data;
    const foundKeys = hits.map(({ key }) => key);

    addFoundKeys(foundKeys, data);
  }

  onSearch(value) {
    const { filters: { mode } } = this.props;
    const { changeFilters, clearFoundKeys } = this.props.actions;
    const filterMode: FilterMode | undefined = FilterMode.enumValueOf(mode) as any;

    changeFilters({ query: value });

    this.helper.setQuery(value);
    // this.helper.addFacetRefinement('state', state)
    this.helper.addNumericRefinement('state', '=', filterMode?.filterState);

    // in the case of changed query clear found keys only when the results come in
    const onResult = (data) => {
      clearFoundKeys();
      this.onSearchResult(data);
      this.helper.removeListener('result', onResult);
    };

    this.helper.on('result', onResult);
    this.helper.search();
  }

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillMount() {
    const { actions: { changeFilters } } = this.props;
    const pathname = get(this.context.router, 'route.location.pathname', '/');
    const mode = pathname.substr(ONE) || FilterMode.open.enumKey;
    changeFilters({ mode });

    if (pathname === '/') {
      this.context.router.history.replace('/open');
    }
  }

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (get(this.props, 'filters.limit') !== get(nextProps, 'filters.limit') || get(this.props, 'filters.mode') !== get(nextProps, 'filters.mode')) {
      this.createSearchHelper(nextProps);
    }
  }

  render() {
    const {
      lab,
      filters: { mode },
    } = this.props;

    const filterMode = FilterMode.enumValueOf(mode);

    return (
      <Page
        className="analyses"
        title={lab ? <span>{lab.name}</span> : null}
        before={<NavigationBar/>}
        wrapper={Wrapper}
      >

        <div className="row page-titles">
        </div>

        <div className="card">
          <div className="card-body">

            <div className="text-center">
              <SearchBox filterMode={filterMode} onChange={this.throttledOnSearch}/>
              {this.renderAnalysesCountIfNeeded()}
            </div>

            {this.renderTable()}
            {this.renderLoadMore()}
          </div>
        </div>

        {
          !!this.state.analysisToOpen &&
          ReactDOM.createPortal(
            <AnalysisModal
              open={true}
              analysis={this.state.analysisToOpen}
              onHide={() => this.setState({ analysisToOpen: null })}
              onAccept={this.onAccept}
              onReject={this.onReject}
              onMark={this.onMark}
              onUnmark={this.onUnmark}
            />,
            document.getElementById('modal-portal') as HTMLElement,
          )
        }
        {!!this.state.analysisToReject &&
         <RejectAnalysisModal
           analysis={this.state.analysisToReject}
           show={true}
           onCancel={(e) => this.setState({ analysisToReject: null })}
           onReject={(reason) => {
             this.state.analysisToReject && setAcceptance(this.props.firestore, this.props.profile, this.state.analysisToReject, false, reason)
               .then(() => this.setState({ analysisToReject: null }));
           }}/>}
      </Page>
    );
  }

  onClickAnalysis(analysis: Analysis) {
    this.setState({ analysisToOpen: analysis });
  }

  onAccept(analysis: Analysis) {
    setAcceptance(this.props.firestore, this.props.profile, analysis, true);
  }

  onReject(analysis: Analysis) {
    this.setState({ analysisToReject: analysis });
  }

  onMark(analysis: Analysis) {
    setMarked(this.props.firestore, this.props.profile, analysis, true);
  }

  onUnmark(analysis: Analysis) {
    setMarked(this.props.firestore, this.props.profile, analysis, false);
  }

  onFilterChangeFromTable(filterChanges: any) {
    const { filters } = this.props;
    const { changeFilters, clearFirestoreData } = this.props.actions;

    if (!isEqual(filters.orderBy, filterChanges.orderBy)) {
      clearFirestoreData({ data: (state, nextState) => ({ ...state, ...nextState, analysis: {} }) });
    }
    changeFilters(filterChanges);
  }

  private renderTable() {
    const {
      filteredAnalyses,
      filters: { mode },
      isLoading,
      profile,
    } = this.props;
    const filterMode = FilterMode.enumValueOf(mode);

    if (filterMode === FilterMode.inbox) {
      return <InboxTable
        analyses={filteredAnalyses}
        isLoading={isLoading}
        profile={profile}
        onClickAnalysis={this.onClickAnalysis}
        onFilterChange={this.onFilterChangeFromTable}
      />;
    } else if (filterMode === FilterMode.open) {
      return <OpenTable
        analyses={filteredAnalyses}
        isLoading={isLoading}
        profile={profile}
        onClickAnalysis={this.onClickAnalysis}
        onFilterChange={this.onFilterChangeFromTable}
      />;
    } else if (filterMode === FilterMode.results) {
      return <ResultsTable
        analyses={filteredAnalyses}
        isLoading={isLoading}
        profile={profile}
        onClickAnalysis={this.onClickAnalysis}
        onFilterChange={this.onFilterChangeFromTable}
      />;
    } else if (filterMode === FilterMode.cancelled) {
      return <CancelledTable
        analyses={filteredAnalyses}
        isLoading={isLoading}
        profile={profile}
        onClickAnalysis={this.onClickAnalysis}
        onAccept={this.onAccept}
        onFilterChange={this.onFilterChangeFromTable}
      />;
    } else {
      throw new Error('unknown table type');
    }
  }

  renderLoadMore() {
    const {
      filteredAnalyses,
      filters,
      hasMoreAnalyses,
      hasMoreAnalysesSearch,
      isLoading,
    } = this.props;
    // TODO: check hasMoreAnalyses value
    const { query, mode } = filters;
    const { changeFilters } = this.props.actions;
    const filterMode: FilterMode | undefined = FilterMode.enumValueOf(mode) as any;
    if (!filterMode) {
      throw new Error('Unexpected value for filterMode: undefined');
    }

    if (filteredAnalyses.length) {
      if (!query) {
        if (hasMoreAnalyses && hasMoreAnalyses[filterMode.enumKey]) {
          if (isLoading) {
            return <Loading isLoading={true}/>;
          } else {
            return <div className="load-more-container">
              <button
                className="btn btn-primary"
                onClick={() => changeFilters({
                  startAfter: get(last(filteredAnalyses), get(filters, `orderBy.${filterMode.enumKey}.0.0`, 'created')),
                })}
              >mehr
              </button>
            </div>;
          }
        }
      } else {
        if (hasMoreAnalysesSearch) {
          return <div className="load-more-container">
            <button
              className="btn btn-primary"
              onClick={() => {
                const page = (filters.page || DEFAULT_PAGE_INDEX) + ONE;
                changeFilters({ page });
                this.helper.setPage(page);
                this.helper.search();
              }}
            >mehr
            </button>
          </div>;
        }
      }
    }
    return null;
  }

  private renderAnalysesCountIfNeeded() {
    const { filters, profile } = this.props;
    const filterMode = FilterMode.enumValueOf(filters.mode);
    if (filterMode !== FilterMode.results || !profile.labId) return null;

    const spanStyle: React.CSSProperties = {
      fontSize: '18px',
      margin: '0 10px',
      display: 'inline-block',
    };
    return <div style={spanStyle}>
      <CompletedAnalysesCount labId={profile.labId} isSuperAdmin={profile.isSuperAdmin} />
    </div>;
  }
}

export default compose<React.ComponentClass>(
  connect((state) => {
    const allAnalyses = getAllAnalyses(state);
    const filters = getFilters(state);
    const profile = get(state, 'firebase.profile');
    const requesting = get(state, 'firestore.status.requesting');
    const filterMode: FilterMode | undefined = FilterMode.enumValueOf(filters.mode) as any;
    if (!filterMode) {
      throw new Error('Unexpected value for filterMode: undefined');
    }

    const loadingRequests = keys(requesting)
      .filter(r => requesting[r] && new RegExp('^analyses\\?where::state==' + filterMode?.filterState).exec(r));
    const isLoading = loadingRequests.length && !!requesting[loadingRequests[ZERO]];
    const hasMoreAnalysesFallbackValue = [ZERO, ONE, NEGATIVE_ONE]
      .reduce((o, state) => {
        o[state] = true;
        return o;
      }, {});

    return {
      filteredAnalyses: getFilteredAnalysisKeys(state).map(key => allAnalyses[key]),
      analyses: allAnalyses,
      foundKeys: get(state, 'search.foundKeys'),
      filters: filters,
      hasMoreAnalyses: get(state, 'hasMoreAnalyses', hasMoreAnalysesFallbackValue),
      hasMoreAnalysesSearch: get(state, 'search.hasMoreAnalyses', true),
      profile: profile,
      search: state.search || {},
      lab: (profile && profile.labId != null && get(state, `firestore.data.labs.${profile.labId}`)) || null,
      isLoading,
    };
  }, (dispatch) => ({
    actions: bindActionCreators(
      {
        changeFilters,
        clearFirestoreData,
        addFoundKeys,
        clearFoundKeys,
      },
      dispatch,
    ),
  })),

  firestoreConnect(props => {
    const filters = get(props, 'filters');
    const {
      limit,
      startAfter,
      orderBy: orderByForEachFilterMode,
      mode,
      query,
    } = filters;
    const listeners: any[] = [];
    const hasLabId = !props.profile.isSuperAdmin && props.profile.labId != null;
    const filterMode: FilterMode | undefined = FilterMode.enumValueOf(mode) as any;

    if (!query && filterMode !== FilterMode.open && filterMode !== FilterMode.inbox) {
      const where: any[] = [];
      if (filterMode) {
        where.push(['state', '==', filterMode.filterState]);
      }
      if (hasLabId) {
        where.push(['labSelected.key', '==', props.profile.labId]);
      }
      listeners.push(
        {
          collection: 'analyses',
          where,
          startAfter,
          limit,
          orderBy: filterMode ? orderByForEachFilterMode[filterMode.enumKey] : undefined,
          requestId: 'analysis-list',
          filterMode: filterMode?.enumKey,
        },
      );
    } else {
      const foundKeys = get(props, 'foundKeys', []);
      const analyses = get(props, 'analyses', {});

      listeners.splice(listeners.length, ZERO,
        ...foundKeys
          .filter(key => !analyses[key])
          .map(key => ({
            collection: 'analyses',
            doc: key,
          })),
      );
    }

    return listeners;
  }),
)(Analyses);
