import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get, entries } from 'lodash-es';
import Page from '../Page/Page';
import type { Profile } from '../../flowTypes';
import ReactTable from 'react-table';
import { removeLabUser, setLabAdmin } from '../../data/user';
import { firestoreConnect } from 'react-redux-firebase';
import './style.css';
import ToolBar from './ToolBar';
import CreateLabUser from './CreateLabUser';
import { ConfirmDialog } from '../Dialog/Dialog';

class UserTable extends React.Component<{
  children?: React.ReactNode;
  lab: Record<string, any>;
  usersWithPrivileges: Record<string, any>;
  users: Record<string, any>;
  profile: Profile;
  firestore: Record<string, any>;
  labs: Array<any>;
}, {
  showNewUserModal: boolean;
  userToRemove: string | null;
}> {
  state = {
    showNewUserModal: false,
    userToRemove: null,
  };

  render() {
    const {
      lab,
      labs,
      usersWithPrivileges,
      users,
      profile: { uid, isSuperAdmin },
      firestore,
    } = this.props;

    const data = entries(usersWithPrivileges)
      .filter(([key, u]) => u != null)
      .map(([key, u]) => ({
        ...users[key] || {},
        ...u,
      }));

    const adminCol = {
      ...adminColumn,
      Cell: ({ value, original }) => (
        <div className="form-group">
          <input type="checkbox" id={'admin-' + original.key} checked={!!value} disabled={original.key === uid} onChange={(e) => {
            setLabAdmin(firestore, original.key, !value);
          }}
                 className="form-control filled-in"/>
          <label htmlFor={'admin-' + original.key}/>
        </div>
      ),
    };
    const labCol = {
      ...labColumn,
      Cell: ({ value }) => get(labs, `${value}.name`) || '',
    };
    const buttonColumn = {
      Header: '',
      Cell: ({ original: { key, isSuperAdmin: userIsSuperAdmin } }) => (
        <div>
          {key !== uid && (isSuperAdmin || (!isSuperAdmin && !userIsSuperAdmin))
            ? (<button className="btn btn-danger" onClick={() => this.setState({ userToRemove: key })}>
            <i className="fa fa-times"/> Entfernen
          </button>)
            : null}
        </div>
      ),
    };

    const columns = isSuperAdmin
      ? [
          emailColumn,
          nameColumn,
          labCol,
          adminCol,
          buttonColumn,
        ]
      : [
          emailColumn,
          nameColumn,
          adminCol,
          buttonColumn,
        ];

    return (
      <Page
        className="users"
        title={lab ? <span>{lab.name}</span> : null}
        before={<ToolBar onAddNewUser={() => this.setState({ showNewUserModal: true })}/>}
      >
        <div className="row page-titles">
        </div>

        <div className="card">
          <div className="card-body">

            <ReactTable
              className="user-table dataTable table-striped table-hover"
              data={data}
              columns={columns}
              getTrProps={(state, rowInfo, column, instance) => ({
                onClick: e => null/* onClickAnalysis(rowInfo.original) */,
              })}
              showPagination={false}
              minRows={0}
              NoDataComponent={() => <div className="no-analysis">Keine Benutzer vorhanden.</div>}
            />
          </div>
        </div>
        {
          this.state.showNewUserModal &&
          <CreateLabUser onHide={() => this.setState({ showNewUserModal: false })}/>
        }
        {
          this.state.userToRemove
            ? <ConfirmDialog
              title={`${get(usersWithPrivileges, `${this.state.userToRemove}.name`, '')} löschen?`}
              show={!!this.state.userToRemove}
              onClose={(reason) => {
                if (reason === 'yes') {
                  removeLabUser(firestore, this.state.userToRemove)
                    .then(() => this.setState({ userToRemove: null }));
                } else {
                  this.setState({ userToRemove: null });
                }
              }}>
              Sind Sie sicher?
            </ConfirmDialog>
            : null
        }
      </Page>
    );
  }
}

export default compose<React.ComponentClass>(
  connect((state) => {
    const profile = get(state, 'firebase.profile');
    return ({
      lab: (profile && profile.labId != null && get(state, `firestore.data.labs.${profile.labId}`)) || null,
      labs: get(state, 'firestore.data.labs'),
      usersWithPrivileges: get(state, 'firestore.data.usersWithPrivileges', {}),
      users: get(state, 'firestore.data.users', {}),
      profile,
    });
  }),
  firestoreConnect(),
)(UserTable);

const emailColumn = {
  Header: 'E-Mail',
  getHeaderProps: () => ({ title: 'E-Mail' }),
  accessor: 'email',
};
const nameColumn = {
  Header: 'Name',
  getHeaderProps: () => ({ title: 'Name' }),
  accessor: 'displayName',
};
const adminColumn = {
  Header: 'Administrator',
  getHeaderProps: () => ({ title: 'Administrator' }),
  accessor: 'isLabAdminUser',
};
const labColumn = {
  Header: 'Labor',
  getHeaderProps: () => ({ title: 'Labor' }),
  accessor: 'labId',
};
