import * as React from 'react';
import { Analysis, AnalysisState } from '../../flowTypes';
import moment from 'moment/moment';
import { get } from 'lodash-es';
import './style.css';
import { toDate } from '../../utils/Date';

type Props = {
  analysis: Analysis;
};

export default function Status({ analysis }: Props) {
  const state = get(analysis, 'state', null);
  const labAccepted = get(analysis, 'lab_accepted', null);
  const labAcceptedBy = get(analysis, 'lab_accepted_by.name', null);
  const labAcceptedOn = toDate(get(analysis, 'lab_accepted_on', null));
  const cancelledReason = get(analysis, 'cancelled_reason', null);
  const cancelledBy = get(analysis, 'cancelled_by.name', null);
  const labProbeTakenBy = get(analysis, 'lab_probe_taken_by.name', null);
  const labProbeTakenOn = toDate(get(analysis, 'lab_probe_taken_on', null));
  const completedBy = get(analysis, 'completed_by.name', null);

  if (state === AnalysisState.Inbox) {
    if (labAccepted === true && labProbeTakenBy) {
      return <div className="status">
        <span>Probe wurde gezogen</span>
        <span>{labProbeTakenBy}</span>
        {labProbeTakenOn ? <span>{moment(labProbeTakenOn).format('DD.MM.YYYY HH:mm')}</span> : null}
      </div>;
    } else if (labAccepted === true) {
      return <div className="status">
        <span>Angenommen</span>
        {labAcceptedBy ? <span>{labAcceptedBy}</span> : null}
        {labAcceptedOn ? <span>{moment(labAcceptedOn).format('DD.MM.YYYY HH:mm')}</span> : null}
      </div>;
    } else if (labAccepted === false) {
      return <div className="status">
        <span>Abgelehnt</span>
        {labAcceptedBy ? <span>{labAcceptedBy}</span> : null}
        {labAcceptedOn ? <span>{moment(labAcceptedOn).format('DD.MM.YYYY HH:mm')}</span> : null}
        {cancelledReason ? <span>{cancelledReason}</span> : null}
      </div>;
    } else {
      return <div className="status">
        <span>nicht angenommen</span>
      </div>;
    }
  } else if (state === AnalysisState.Completed) {
    return <div className="status">
      <span>Erledigt</span>
      <span>{completedBy}</span>
    </div>;
  } else if (state === AnalysisState.Cancelled) {
    return <div className="status">
      {labAccepted == null ? <span>Storniert vom Benutzer</span> : labAccepted === false ? <span>Abgelehnt</span> : null}
      {cancelledBy && <span>{cancelledBy}</span>}
      {labAccepted === false && cancelledReason ? <span>{cancelledReason}</span> : null}
    </div>;
  }

  return null;
};
