import React, { Component } from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';

const firebase = require('firebase');

type Props = {
  path: string;
  component: (props: any) => JSX.Element;
  exact: boolean;
};

export default class PrivateRoute extends Component<Props> {
  render() {
    const { component: Component, ...rest } = this.props;
    const user = firebase.auth().currentUser;

    return <Route {...rest} render={props => (
      user
        ? (
        <Component {...props}/>
          )
        : (
        <Redirect to={{
          pathname: '/authenticate',
          state: { from: props.location },
        }}/>
          )
    )}/>;
  }
}
