export function getBounds(positions) {
  const bounds = new (window as any).google.maps.LatLngBounds();
  positions.forEach(pos => bounds.extend(posToLatLng(pos)));
  return bounds;
}

export function posToLatLng({ latitude, longitude }) {
  return new (window as any).google.maps.LatLng(latitude, longitude);
}

export function posToMapPos({ latitude, longitude }) {
  return {
    lat: latitude,
    lng: longitude,
  };
}

export function latLngToMapPos(latLng) {
  return { lat: latLng.lat(), lng: latLng.lng() };
}
