import * as React from 'react';
import Modal from 'react-bootstrap-modal';

type Props = {
  children?: React.ReactNode;
  analysis: Record<string, any>;
  show: boolean;
  onCancel: (...args: Array<any>) => any;
  onReject: (...args: Array<any>) => any;
};

type State = {
  reason: string;
};

class RejectAnalysisModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
    };
  }

  render() {
    const { analysis, show, onCancel, onReject, ...restProps } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Modal
        show={show}
        large={true}
        {...restProps}
        onHide={this.handleCancel.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{analysis.analyse_number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bitte den Grund der Ablehnung eingeben:</p>
          <textarea className="rejection-reason form-control" onInput={(e) => this.setState({ reason: e.currentTarget.value })} value={this.state.reason}/>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={this.handleCancel.bind(this)}>Abbrechen</button>
          <button className="btn btn-danger" onClick={(e) => {
            this.setState({ reason: '' });
            onReject(this.state.reason);
          }}>Ablehnen</button>
        </Modal.Footer>
      </Modal>
    );
  }

  private handleCancel() {
    this.setState({ reason: '' });
    this.props.onCancel();
  }
}

export default RejectAnalysisModal;
