import * as React from 'react';
import PropTypes from 'prop-types';
import '../../oms.min';

const MARKER_CLUSTERER = 'MARKER_CLUSTERER';

type MarkerClustererProps = {
  defaultAverageCenter?: boolean;
  defaultBatchSizeIE?: number;
  defaultBatchSize?: number;
  defaultCalculator?: (...args: Array<any>) => any;
  defaultClusterClass?: string;
  defaultEnableRetinaIcons?: boolean;
  defaultGridSize?: number;
  defaultIgnoreHidden?: boolean;
  defaultImageExtension?: string;
  defaultImagePath?: string;
  defaultImageSizes?: Array<any>;
  defaultMaxZoom?: number;
  defaultMinimumClusterSize?: number;
  defaultStyles?: Array<any>;
  defaultTitle?: string;
  defaultZoomOnClick?: boolean;
  averageCenter?: boolean;
  batchSizeIE?: number;
  batchSize?: number;
  calculator?: (...args: Array<any>) => any;
  clusterClass?: string;
  enableRetinaIcons?: boolean;
  gridSize?: number;
  ignoreHidden?: boolean;
  imageExtension?: string;
  imagePath?: string;
  imageSizes?: Array<any>;
  maxZoom?: number;
  minimumClusterSize?: number;
  styles?: Array<any>;
  title?: string;
  // zoomOnClick?: boolean,
  // onClick?: (...args: Array<any>) => any,
  // onClusteringBegin?: (...args: Array<any>) => any,
  // onClusteringEnd?: (...args: Array<any>) => any,
  // onMouseOut?: (...args: Array<any>) => any,
  // onMouseOver?: (...args: Array<any>) => any,
};

type SpiderfierProps = {
  children?: React.ReactNode;

  markersWontMove?: boolean;
  markersWontHide?: boolean;
  basicFormatEvents?: boolean;
  keepSpiderfied?: boolean;
  ignoreMapClick?: boolean;
  nearbyDistance?: number;
  circleSpiralSwitchover?: number;
  circleFootSeparation?: number;
  circleStartAngle?: number;
  spiralFootSeparation?: number;
  spiralLengthStart?: number;
  spiralLengthFactor?: number;
  legWeight?: number;
  map: google.maps.Map;
  onMarkerClusterer: (markerClusterer: any) => void;
};

type ClustererProps = MarkerClustererProps & SpiderfierProps;

class Spiderfier extends React.Component<SpiderfierProps> {
  static childContextTypes = {
    [MARKER_CLUSTERER]: PropTypes.object,
    spiderClusterer: PropTypes.bool,
  };

  static contextTypes = {
    [MARKER_CLUSTERER]: PropTypes.object,
  };

  oms: Record<string, any>;

  constructor(props, context) {
    super(props, context);
    this.oms = new (window as any).OverlappingMarkerSpiderfier(this.props.map, props);
    if (this.props.onMarkerClusterer) {
      this.props.onMarkerClusterer(this.getMarkerClusterer());
    }
  }

  getChildContext() {
    return {
      [MARKER_CLUSTERER]: this.getMarkerClusterer(),
      spiderClusterer: true,
    };
  }

  render() {
    return this.props.children;
  }

  private getMarkerClusterer() {
    return {
      addMarker: (marker, noRedraw) => {
        this.oms.addMarker(marker);
        // this.context[MARKER_CLUSTERER].addMarker(marker, noRedraw)
      },
      removeMarker: (marker, noRedraw) => {
        this.oms.removeMarker(marker);
        // this.context[MARKER_CLUSTERER].removeMarker(marker, noRedraw)
      },
    };
  }
}

export default function SpiderfiedMarkerClusterer({
  children,
  // maxZoom = 15,

  defaultAverageCenter,
  defaultBatchSizeIE,
  defaultBatchSize,
  defaultCalculator,
  defaultClusterClass,
  defaultEnableRetinaIcons,
  defaultGridSize,
  defaultIgnoreHidden,
  defaultImageExtension,
  defaultImagePath,
  defaultImageSizes,
  defaultMaxZoom,
  defaultMinimumClusterSize,
  defaultStyles,
  defaultTitle,
  defaultZoomOnClick,
  averageCenter,
  batchSizeIE,
  batchSize,
  calculator,
  clusterClass,
  enableRetinaIcons,
  gridSize,
  ignoreHidden,
  imageExtension,
  imagePath,
  imageSizes,
  minimumClusterSize,
  styles,
  title,
  ...restProps
}: ClustererProps) {
  /*
  return <MarkerClusterer
    defaultAverageCenter={defaultAverageCenter}
    defaultBatchSizeIE={defaultBatchSizeIE}
    defaultBatchSize={defaultBatchSize}
    defaultCalculator={defaultCalculator}
    defaultClusterClass={defaultClusterClass}
    defaultEnableRetinaIcons={defaultEnableRetinaIcons}
    defaultGridSize={defaultGridSize}
    defaultIgnoreHidden={defaultIgnoreHidden}
    defaultImageExtension={defaultImageExtension}
    defaultImagePath={defaultImagePath}
    defaultImageSizes={defaultImageSizes}
    defaultMaxZoom={defaultMaxZoom}
    defaultMinimumClusterSize={defaultMinimumClusterSize}
    defaultStyles={defaultStyles}
    defaultTitle={defaultTitle}
    defaultZoomOnClick={defaultZoomOnClick}
    averageCenter={averageCenter}
    batchSizeIE={batchSizeIE}
    batchSize={batchSize}
    calculator={calculator}
    clusterClass={clusterClass}
    enableRetinaIcons={enableRetinaIcons}
    gridSize={gridSize}
    ignoreHidden={ignoreHidden}
    imageExtension={imageExtension}
    imagePath={imagePath}
    imageSizes={imageSizes}
    maxZoom={maxZoom}
    minimumClusterSize={minimumClusterSize}
    styles={styles}
    title={title}
  >; */
  return <Spiderfier {...restProps}>
    {children}
  </Spiderfier>;
  // </MarkerClusterer>
};
