const MINUTES_IN_A_DEGREE = 60;
const SECONDS_IN_A_MINUTE = 60;
const ZERO = 0;
const NORTH = 'N';
const SOUTH = 'S';
const EAST = 'E';
const WEST = 'W';

export function convertDMS(lat, lng) {
  const latitude = toDegreesMinutesAndSeconds(lat);
  const latitudeCardinal = Math.sign(lat) >= ZERO ? NORTH : SOUTH;

  const longitude = toDegreesMinutesAndSeconds(lng);
  const longitudeCardinal = Math.sign(lng) >= ZERO ? EAST : WEST;

  return latitude + ' ' + latitudeCardinal + ' ' + longitude + ' ' + longitudeCardinal;
}

function toDegreesMinutesAndSeconds(coordinate) {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * MINUTES_IN_A_DEGREE;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * SECONDS_IN_A_MINUTE);

  return degrees + ' ' + minutes + ' ' + seconds;
}
