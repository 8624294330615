export type Profile = {
  isSuperAdmin?: boolean;
  isLabUser?: boolean;
  isLabAdminUser?: boolean;
  displayName: string;
  email: string;
  firstname: string;
  language: string;
  lastname: string;
  uid: string;
  isEmpty: boolean;
  isLoaded: boolean;
  phoneNumber: string;
  labId?: string;
  photoURL?: string;
};

export type Search = {
  apiKey: string;
  appId: string;
  analysesIndex: string;
  requesting: boolean;
  loaded: boolean;
};

export type AnalysisCost = {
  currency: string;
  grossPrice: number;
  netPrice: number;
  vat: number;
  vatSum: number;
};

export type Analysis = {
  analyse_number: string;
  company: {
    city: string;
    company_number: string;
    country: string;
    email: string;
    key: string;
    manager: {
      firstname: string;
      lastname: string;
    };
    modified: string;
    modified_by: {
      email: string;
      name: string;
      uid: string;
    };
    name: string;
    position: {
      latitude: number;
      longitude: number;
    };
    stats: {
      fields: {
        count: number;
        ha: number;
      };
      users: {
        count: number;
      };
    };
    street: string;
    tel: string;
    zip: string;
  };
  company_address: {
    city: string;
    country: string;
    email: string;
    name: string;
    street: string;
    tel: string;
    zip: string;
  };
  company_id: string;
  created: string;
  created_by: {
    email: string;
    name: string;
    uid: string;
  };
  editCompany: boolean;
  field: {
    activeCrop: {
      color: string;
      company_id: string;
      created: string;
      created_by: {
        email: string;
        name: string;
        uid: string;
      };
      favorite: boolean;
      field_id: string;
      key: string;
      name: string;
      not_a_crop: number;
      sown_on: string;
      state: number;
      type: string;
    };
    active_crop_uid: string;
    company_id: string;
    created: string;
    created_by: {
      email: string;
      name: string;
      uid: string;
    };
    key: string;
    name: string;
    position: {
      latitude: number;
      longitude: number;
    };
    size: number;
    state: number;
  };
  field_id: string;
  form: {
    crop: {
      color: string;
      name: string;
      notes: string;
      pre_crop: string;
      sown_on: string;
    };
    offers: {
      nitrate: Array<string>;
      other_chemicals: Array<string>;
      probe_date: string;
      result_on: string;
      service: string;
      serviceA: string;
    };
  };
  invoice_address: {
    city: string;
    country: string;
    name: string;
    street: string;
    zip: string;
  };
  key: string;
  labOffer: string;
  labSelected: {
    address: {
      city: string;
      country: string;
      street: string;
      zip: number;
    };
    email: string;
    key: string;
    logo: {
      height: number;
      uri: string;
      width: number;
    };
    name: string;
    offers: {
      residue: {
        crop_harvesting_state: {
          a: string;
          b: string;
        };
        db: {
          a: string;
          b: string;
          c: string;
        };
        farming_type: {
          a: string;
          b: string;
        };
        farming_type1: {
          a: string;
          b: string;
        };
        form: {
          a: string;
          b: string;
        };
        result_for: {
          a: string;
          b: string;
          c: string;
          d: string;
          e: string;
          f: string;
          g: string;
          h: string;
          k: string;
          l: string;
        };
        service: {
          a: string;
          b: string;
        };
        serviceA: {
          a: string;
          b: string;
        };
      };
      soil: {
        nitrate: {
          a: string;
          b: string;
        };
        other_chemicals: {
          c: string;
          d: string;
          e: string;
        };
        service: {
          a: string;
          b: string;
        };
        serviceA: {
          a: string;
          b: string;
        };
      };
    };
    tel: string;
    labId: string;
  };
  sentMail: boolean;
  state: number;
  lab_accepted: boolean;
  lab_accepted_by: Record<string, any>;
  lab_accepted_on: Date;
  cancelled_by: Record<string, any>;
  cancelled_on: Date;
  cancelled_reason: string;
  result: {
    file: Record<string, File>;
  };
  analysisCost: AnalysisCost;
};

export type File = {
  created: string;
  downloadURL: string;
  fullPath: string;
  name: string;
};

export enum AnalysisState {
  Inbox = 0,
  Completed = 1,
  Cancelled = -1,
}

export enum OrderByDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum HttpStatus {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  EARLYHINTS = 103,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  AMBIGUOUS = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED = 421,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
}
