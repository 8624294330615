import * as React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { setMapView } from '../../actions/actions';
import FixedMarkerWithLabel from './addons/FixedMarkerWithLabel';

const CLOSER_ZOOM = 20;

class CurrentLocationMarker extends React.Component<{
  children?: React.ReactNode;
  currentLocation: any;
  markerClusterer?: any;
  actions: {
    setMapView: (...args: Array<any>) => any;
  };
}> {
  render() {
    const { currentLocation, markerClusterer } = this.props;

    if (!currentLocation) {
      return null;
    }

    return (
      <FixedMarkerWithLabel
        position={currentLocation}
        labelAnchor={{ x: 33, y: 33 }}
        icon={{ path: '' }}
        labelStyle={{ width: '67px', height: '67px' }}
        labelClass="current-location"
        onClick={() => {
          this.props.actions.setMapView(currentLocation, CLOSER_ZOOM);
        }}
        markerClusterer={markerClusterer}
      >
        <div>
          <div className="pulse"></div>
          <img src="/images/bluedot_retina.png"/>
        </div>
      </FixedMarkerWithLabel>
    );
  }
}

export default compose(
  connect((state) => ({
    currentLocation: get(state, 'map.currentLocation'),
  }), (dispatch) => ({
    actions: bindActionCreators(
      {
        setMapView,
      },
      dispatch,
    ),
  })),
)(CurrentLocationMarker);
