import * as React from 'react';
import Header from '../Header/Header';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { get } from 'lodash-es';
import { classes } from '../../utils/dom';
import DefaultFooter from './Footer';

type DefaultWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

export const DefaultWrapper: React.FunctionComponent<DefaultWrapperProps> = ({ children, className }: DefaultWrapperProps) => {
  return <div className={['page-wrapper', className].filter(c => !!c).join(' ')}>
    {/* <div className="container-fluid"> */}
    {children}
    {/* </div> */}
  </div>;
};

class Page extends React.Component<{
  children?: React.ReactNode;
  title?: React.ReactNode | null;
  className?: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
  wrapper?: React.FunctionComponent<{ children: React.ReactNode, className: string | void | null }>;
  container?: string;
  wrapperClassName?: string;
  footer?: React.ComponentClass;
  menuOpen: boolean;
}> {
  render() {
    const {
      children,
      title = null,
      className = '',
      before = null,
      after = null,
      wrapperClassName,
      wrapper: Wrapper = DefaultWrapper,
      footer: Footer = DefaultFooter,
      menuOpen = false,
    } = this.props;

    return (
      <div id="main-wrapper" className={classes(className, 'mini-sidebar', menuOpen ? 'show-sidebar' : null)}>
        <Header title={title}/>
        {before}
        <Wrapper className={[wrapperClassName, Footer ? undefined : 'no-footer'].filter(c => !!c).join(' ')}>{children}</Wrapper>
        {after}
        {Footer ? <Footer/> : null}
        <div id="modal-portal"></div>
      </div>
    );
  }
}

export default compose(
  connect((state) => ({
    menuOpen: get(state, 'menuOpen'),
  }), (dispatch) => ({ dispatch })),
)(Page);
