import { get } from 'lodash-es';

export enum AnalysisType {
  Soil = 'soil',
  Residue = 'residue',
  Drone = 'drone',
  Unknown = 'unknown',
}

export function getAnalysisType(analysis): AnalysisType {
  return get(analysis, 'labOffer', AnalysisType.Unknown);
}

export function getAnalysisTypeShortName(analysis) {
  const analysisType = getAnalysisType(analysis);
  switch (analysisType) {
    case AnalysisType.Soil:
      return 'Boden';
    case AnalysisType.Residue:
      return 'Rückstand';
    case AnalysisType.Drone:
      return 'Drohnen';
    default:
      // Unknown
      return 'Unbekannt';
  }
}

export function getAnalysisTypeLongName(analysis) {
  const analysisType = getAnalysisType(analysis);
  switch (analysisType) {
    case AnalysisType.Soil:
      return 'Bodenanalyse';
    case AnalysisType.Residue:
      return 'Rückstandsanalyse';
    case AnalysisType.Drone:
      return 'Drohnenanalyse';
    default:
      // Unknown
      return 'Unbekannt';
  }
}
