import * as React from 'react';
import firebase from 'firebase';

export default function withToken(BaseComponent) {
  const factory = React.createFactory(BaseComponent);

  return class Container extends React.PureComponent<{}, {
    isLoaded: boolean;
    token: string | null;
  }> {
    state = {
      isLoaded: false,
      token: null,
    };

    /**
     * TODO: don't use legacy methods.
     * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
     */
    UNSAFE_componentWillMount() {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        throw new Error('Authentication is required');
      }
      currentUser.getIdToken(false).then((token) => {
        this.setState({ isLoaded: true, token });
      });
    }

    render() {
      const { isLoaded } = this.state;

      if (isLoaded) {
        return factory({
          ...this.props,
          token: this.state.token,
        } as any);
      } else {
        return null;
      }
    }
  };
}
