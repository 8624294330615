import { Profile, File, Analysis, AnalysisState } from '../flowTypes';
import { getUserInfo } from './user';
import { modifiedProps } from './firebase';
import firebase from 'firebase';

type FirebaseType = typeof firebase;

export function uploadFile(firebase: FirebaseType, reduxFirestore: any, o: inputObject, profile: Profile) {
  const { analysis, file } = o;
  const storagePath = `analyses/${analysis.company.key}/${analysis.key}`;

  return firebase.storage().ref().child(storagePath)
    .put(file as any)
    .then(async (snap) => {
      const downloadUrl = await snap.ref.getDownloadURL();

      return firebase.firestore().collection('analyses')
        .doc(analysis.key)
        .set({
          result: {
            file: {
              [analysis.key]: {
                downloadURL: downloadUrl,
                name: file.name,
                fullPath: snap.metadata.fullPath,
                created: snap.metadata.timeCreated,
              },
            },
          },
          completed_on: snap.metadata.timeCreated,
          completed_by: getUserInfo(),
          state: 1,
          ...modifiedProps(),
        }, { merge: true })
        .then(() => {
          return reduxFirestore.get({
            collection: 'analyses',
            doc: analysis.key,
          });
        })
        .then(() => o)
        .catch(e => {
          o.error = e;
          return Promise.reject(o);
        });
    }, (e) => {
      o.error = e;
      return Promise.reject(o);
    });
}

export function deleteFile(firebase: any, reduxFirestore: any, analysisKey: string, file: File) {
  const storageRef = firebase.storage().ref();

  return storageRef.child(file.fullPath).delete().then(() => {
    return reduxFirestore.update({
      collection: 'analyses',
      doc: analysisKey,
    }, {
      result: null,
      ...modifiedProps(),
    });
  });
}

export function setAcceptance(reduxFirestore: any, profile: Profile, analysis: Analysis, state: boolean, reason?: string) {
  const { isLabUser } = profile;

  const newProps: Partial<Analysis> = {
    state: state ? AnalysisState.Inbox : AnalysisState.Cancelled,
    lab_accepted: state,
    lab_accepted_by: getUserInfo(),
    lab_accepted_on: new Date(),
  };

  if (!state) {
    newProps.cancelled_by = {
      ...getUserInfo(),
      isLab: !!isLabUser,
    };
    newProps.cancelled_on = new Date();
    if (reason) {
      newProps.cancelled_reason = reason;
    }
  }

  return updateAnalysis(reduxFirestore, analysis.key, newProps);
}

export function setMarked(reduxFirestore: any, profile: Profile, analysis: Analysis, state: boolean) {
  const newProps: any = {};

  if (state) {
    newProps.lab_marked_by = getUserInfo();
  } else {
    newProps.lab_marked_by = null;
  }

  return updateAnalysis(reduxFirestore, analysis.key, newProps);
}

export function setProbeTaken(reduxFirestore: any, profile: Profile, analysis: Analysis, state: boolean) {
  const newProps: any = {};

  if (state) {
    newProps.lab_probe_taken_by = getUserInfo();
    newProps.lab_probe_taken_on = new Date();
  } else {
    newProps.lab_probe_taken_by = null;
    newProps.lab_probe_taken_on = null;
  }

  return updateAnalysis(reduxFirestore, analysis.key, newProps);
}

function updateAnalysis(reduxFirestore, analysisKey, newProps) {
  const analysisQuery = {
    collection: 'analyses',
    doc: analysisKey,
  };
  return reduxFirestore
    .update(analysisQuery, {
      ...newProps,
      ...modifiedProps(),
    })
    .then(() => reduxFirestore.get(analysisQuery)); // update store
}

type inputObject = {
  analysis: Record<string, any>;
  file: File;
  error: Error | string | void | null;
  number: string;
};
