import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { firestoreConnect } from 'react-redux-firebase';
import Dropzone from 'react-dropzone';
import './style.css';
import moment from 'moment/moment';
import { uploadFile } from '../../data/analysis';
import Modal from 'react-bootstrap-modal';
import { Loading, LoadingIcon } from '../Loading/Loading';
import { filter, get } from 'lodash-es';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Analysis } from '../../flowTypes';

const SINGLE_RESULT = 1;

const fileNameRegex = /^([\\w\\d]+)\\.pdf$/i;

type Props = {
  analysis?: Analysis;
  className?: string;
  dropZoneRef?: any;
  firebase: any;
  firestore: any;
  profile?: any;
};
type State = {
  isDraggingFiles: boolean;
  isInProgress: boolean;
  isMounted: boolean;
  uploadStatus: any;
  results?: any[] | null;
};

export class Uploader extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isDraggingFiles: false,
      isInProgress: false,
      isMounted: false,
      uploadStatus: {},
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  upload(o, index) {
    const { firebase, firestore, profile } = this.props;
    this.setState({
      uploadStatus: {
        ...this.state.uploadStatus,
        [index]: 'uploading',
      },
    });

    return uploadFile(firebase, firestore, o, profile)
      .then((o) => {
        this.setState({
          uploadStatus: {
            ...this.state.uploadStatus,
            [index]: 'done',
          },
        });
      })
      .catch((o) => {
        console.error(o);
        this.setState({
          uploadStatus: {
            ...this.state.uploadStatus,
            [index]: 'error',
          },
        });
      });
  }

  uploadAll() {
    const { results } = this.state;
    const staged = {};

    this.setState({
      uploadStatus: {
        ...this.state.uploadStatus,
        ...staged,
      },
    });

    if (!results) {
      throw new Error('Unexpected value for "results": ' + results);
    }

    return results
      .reduce((acc, o, i) => {
        if (o.error) {
          return acc;
        }
        staged[i] = 'staged';

        return acc.then(() => this.upload(o, i));
      }, Promise.resolve());
  }

  onDrop(files, rejectedFiles, e) {
    if (!files.length) {
      return;
    }

    const { firebase, analysis, profile } = this.props;

    this.setState({ isDraggingFiles: false, isInProgress: true });

    if (!analysis) {
      const filePromises = files.map((file) => {
        return Promise.resolve({
          file,
        } as any)
          .then((o) => {
            const match = fileNameRegex.exec(o.file.name);
            if (!match) {
              o.error = 'name doesn\'t match';
              return Promise.reject(o);
            }
            const [, namePart] = match;

            o.number = namePart;
            return o;
          })
          .then((o) => {
            let query = firebase.firestore().collection('analyses')
              .where('analyse_number', '==', o.number);

            if (!profile.isSuperAdmin && profile.labId != null) {
              query = query.where('labSelected.key', '==', profile.labId);
            }

            return query
              .limit(SINGLE_RESULT).get().then((snap) => {
                if (snap.size) {
                  const [firstDoc] = snap.docs;
                  o.analysis = firstDoc.data();
                  return o;
                } else {
                  o.error = 'analysis doesn\'t exist';
                  return Promise.reject(o);
                }
              }, (e) => {
                o.error = e;
                return Promise.reject(o);
              });
          });
        // .then((o) => new Promise((resolve) => setTimeout(() => resolve(o), 1000)))
      });

      Promise.all(filePromises.map(p => p.catch(e => e)))
        .then(results => {
          if (this.state.isMounted) {
            this.setState({
              results,
              isInProgress: false,
            });
          }
        });
    } else {
      if (this.state.isMounted) {
        const [firstFile] = files;
        this.setState({
          results: [{ analysis, number: analysis.analyse_number, file: firstFile }],
          isInProgress: true,
        }, () => this.uploadAll().then(() => this.setState({ isInProgress: false })));
      }
    }

    e.stopPropagation();
  }

  render() {
    const { analysis, className = null, dropZoneRef = null } = this.props;
    const renderDropzoneChildren = this.renderChildren.bind(this);

    return (
      <Dropzone id="analysis-result-uploader"
                ref={dropZoneRef}
                style={{}}
                className={className}
                disableClick={true}
                onDragEnter={(e) => {
                  if (filter(e.dataTransfer.items, i => i.kind === 'file').length) {
                    this.setState({ isDraggingFiles: true });
                  }
                }}
                onDragLeave={() => this.setState({ isDraggingFiles: false })}
                onDrop={this.onDrop.bind(this)}
                multiple={!analysis}
                disablePreview={true}
      >
        {({ getRootProps, getInputProps }) => (
          renderDropzoneChildren()
        )}
      </Dropzone>
    );
  }

  private renderChildren() {
    const result: React.ReactNode[] = [];
    const { children, analysis } = this.props;
    const stateResults = this.state.results;
    if (Array.isArray(children)) {
      result.push(...children);
    } else if (children) {
      result.push(children as any);
    }

    if (this.state.isDraggingFiles || this.state.isInProgress) {
      result.push(
        <div className="overlay" key="overlay">
          {this.state.isDraggingFiles ? <div className="message">Dateien hier ablegen</div> : null}
          {this.state.isInProgress ? <Loading isLoading={true} /> : null}
        </div>,
      );
    }

    if (!analysis && !!stateResults) {
      result.push(ReactDOM.createPortal(
        this.renderModal(),
        document.getElementById('modal-portal') as HTMLElement,
      ));
    }
    return result;
  }

  renderModal() {
    const results = this.state.results || [];

    return <Modal
      show={true}
      onHide={() => this.setState({ results: null, uploadStatus: {} })}
      aria-labelledby="ModalHeader"
      id="analysis-result-uploader-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id='ModalHeader'>Analysen hochladen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="file-list">
          <div>
            <div>Datei</div>
            <div>Analyse</div>
            <div></div>
          </div>
          {results.map((o, i) => {
            const { number, file: { name: fileName }, analysis, error } = o;
            if (error) {
              return <div key={i} className="failed">
                <div>{fileName}</div>
                <div>
                  <div>{error.message || error}</div>
                </div>
                <div></div>
              </div>;
            } else {
              const { company: { name: companyName }, form } = analysis;
              return <div key={i}>
                <div>{fileName}</div>
                <div>
                  <div>{companyName}</div>
                  <div>{number} - {moment(form.offers.result_on).format('DD.MM.YYYY HH:mm')}</div>
                </div>
                <div>
                  {
                    this.state.uploadStatus[i] === 'uploading' ? <LoadingIcon /> : null
                  }
                  {
                    this.state.uploadStatus[i] === 'error' ? <span title={error.message || error}><i className="fa fa-exclamation fa-2x" /></span> : null
                  }
                  {
                    this.state.uploadStatus[i] === 'done' ? <span><i className="fa fa-check fa-2x" /></span> : null
                  }
                  {
                    this.state.uploadStatus[i] === 'staged' ? <span><i className="fa fa-hourglass-start fa-2x" /></span> : null
                  }
                  {
                    this.state.uploadStatus[i] == null ? <button className="btn btn-primary" onClick={this.upload.bind(this, o, i)}>Hochladen</button> : null
                  }
                </div>
              </div>;
            }
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Dismiss className='btn btn-default'>Schließen</Modal.Dismiss>
        <button className='btn btn-primary' onClick={this.uploadAll.bind(this)}>Alle hochladen</button>
      </Modal.Footer>
    </Modal>;
  }
}

type AnalysisResultUploaderExternalProps = {
  analysis?: Analysis;
  className?: string;
  dropZoneRef?: any;
};

export default compose<React.ComponentClass<AnalysisResultUploaderExternalProps>>(
  connect((state) => ({ profile: get(state, 'firebase.profile') })),
  firestoreConnect(props => {
    return [];
  }),
)(Uploader);
