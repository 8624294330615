import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <footer className="footer">
      &copy; farmerJoe GmbH
      &nbsp;|&nbsp;
      <a href="https://farmerjoe.com/cookies.html" target="_blank" rel="noreferrer">
        Cookies
      </a>
      &nbsp;|&nbsp;
      <a href="https://farmerjoe.com/datenschutz.html" target="_blank" rel="noreferrer">
        Datenschutz
      </a>
      &nbsp;|&nbsp;
      <a href="https://farmerjoe.com/agb.html" target="_blank" rel="noreferrer">
        AGB
      </a>
      &nbsp;|&nbsp;
      <a href="https://farmerjoe.com/impressum.html" target="_blank" rel="noreferrer">
        Impressum
      </a>
    </footer>
  );
}
