const ZERO = 0;
const ONE = 1;

function analysisClassNameBasedOnDaysCount(leftDaysCount: number) {
  if (leftDaysCount < ZERO) {
    return 'overdue';
  } else if (leftDaysCount <= ONE) {
    return 'due-today';
  } else {
    return null;
  }
}

export function analysisCellClassNameBasedOnDaysCount(leftDaysCount: number) {
  const classNames = ['days-left'];
  const additionalClassName = analysisClassNameBasedOnDaysCount(leftDaysCount);
  if (additionalClassName !== null) {
    classNames.push(additionalClassName);
  }
  return classNames.join(' ');
}

export function analysisRowClassNameBasedOnDaysCount(leftDaysCount: number) {
  return analysisClassNameBasedOnDaysCount(leftDaysCount);
}
