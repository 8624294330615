import * as React from 'react';
import { compose } from 'redux';
import { get } from 'lodash-es';
import type { Analysis } from '../../flowTypes';
import './style.css';
import Marker from './Marker';
import { posToLatLng } from './utils';
import { daysLeft, getPosition } from '../../selectors/analyses';
import { AnalysisType, getAnalysisType } from '../../utils/analyses';

const ZERO = 0;
const ONE = 1;
const LINE_HEIGHT = 15;

type Props = {
  children?: React.ReactNode;
  analysis: Analysis;
  color?: string;
  width?: number;
  height?: number;
  labelClass?: string;
  zIndex?: number;
  labelStyle?: Record<string, any>;
  onClick?: (...args: Array<any>) => any;
  wrapper1Before?: React.ReactNode;
  map: google.maps.Map;
  markerClusterer?: any;
};

class AnalysisMarker extends React.Component<Props> {
  render() {
    const {
      analysis,
      ...restProps
    } = this.props;

    const name = get(analysis, 'lab_marked_by.name', '');
    const labAccepted = get(analysis, 'lab_accepted', null);
    const initials = name.split(/\s+/).map(s => s[ZERO]).join('');
    const lineCount = (initials ? ONE : ZERO) + ONE;

    return (
      <Marker
        position={posToLatLng(getPosition(analysis))}
        width={45}
        height={LINE_HEIGHT * lineCount}
        color={markerColorForAnalysis(analysis)}
        wrapper1Before={labAccepted == null ? <div className="inbox-dot"/> : null}
        {...restProps}
      >
        <div className="days-left">{daysLeft(analysis)} T</div>
        {!!initials && <div className="marker-initials">{initials}</div>}
      </Marker>
    );
  }
}

function markerColorForAnalysis(analysis) {
  const analysisType = getAnalysisType(analysis);
  switch (analysisType) {
    case AnalysisType.Soil:
      return '#985534';
    case AnalysisType.Residue:
      return '#61af3c';
    case AnalysisType.Drone:
      return '#333333';
    default:
      return '#FFFFFF';
  }
}

export default compose(
)(AnalysisMarker);
