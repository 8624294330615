import firebase from 'firebase';
import 'firebase/firestore';
import { getUserInfo } from './user';

const config = {
  /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  apiKey: process.env.REACT_APP_FIRE_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRE_DB_URL,
  projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
};
const fire = firebase.initializeApp(config);

firebase.firestore();

export default fire;

export const modifiedProps = () => ({
  modified_by: getUserInfo(),
  modified: firebase.firestore.FieldValue.serverTimestamp(),
});
