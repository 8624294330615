import React, { Component } from 'react';
import Authenticate from './components/Authenticate/Authenticate';
import { Route, Switch } from 'react-router-dom';
import Analyses from './components/Analyses/Analyses';
import PrivateRoute from './components/Router/PrivateRoute';
import UserLoader from './components/Loaders/UserLoader';
import SearchLoader from './components/Loaders/SearchLoader';
import LabLoader from './components/Loaders/LabLoader';
import ChangePassword from './components/ChangePassword/ChangePassword';
import OpenAnalysisLoader from './components/Loaders/OpenAnalysisLoader';
import AnalysesMap from './components/Map/AnalysesMap';
import UserTable from './components/LabAdminPage/UserTable';
import { Redirect } from 'react-router';

class App extends Component<{}> {
  render() {
    return (
      <Switch>
        <PrivateRoute path="/(|open|inbox|results|cancelled)" exact component={(props) => {
          return <UserLoader>
            <SearchLoader>
              <LabLoader>
                <OpenAnalysisLoader>
                  <Analyses {...props}/>
                </OpenAnalysisLoader>
              </LabLoader>
            </SearchLoader>
          </UserLoader>;
        }}/>
        <PrivateRoute path="/map" exact component={(props) => {
          return <UserLoader>
            <SearchLoader>
              <LabLoader>
                <OpenAnalysisLoader>
                  <AnalysesMap {...props}/>
                </OpenAnalysisLoader>
              </LabLoader>
            </SearchLoader>
          </UserLoader>;
        }}/>
        <PrivateRoute path="/changepassword" exact component={(props) => {
          return <ChangePassword/>;
        }}/>
        <PrivateRoute path="/users" exact component={(props) => {
          return <UserLoader loadAll={true} loadFromUsersTable={true}>
            <LabLoader>
              <UserTable/>
            </LabLoader>
          </UserLoader>;
        }}/>
        <Route path="/authenticate" exact component={Authenticate}/>
        <Redirect to="/"/>
      </Switch>
    );
  }
}

export default App;
