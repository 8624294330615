import * as React from 'react';
import { firestoreConnect, isLoaded, actionTypes } from 'react-redux-firebase';
import { compose } from 'redux';
import firebase from 'firebase';
import { connect } from 'react-redux';
import { get, entries } from 'lodash-es';
import { Loading } from '../Loading/Loading';
import Page from '../Page/Page';
import type { Profile } from '../../flowTypes';
import './style.css';

const { SET_PROFILE } = actionTypes;

type Props = {
  children?: React.ReactNode;
  usersWithPrivileges: Array<any>;
  users: Array<any>;
  profile: Profile;
  dispatch: (...args: Array<any>) => any;
  loadAll: boolean;
  loadFromUsersTable: boolean;
};

class Loader extends React.Component<Props, { first: boolean }> {
  state = {
    first: true,
  };

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillReceiveProps(props) {
    const { usersWithPrivileges, profile, dispatch } = props;
    if (this.state.first && isLoaded(usersWithPrivileges) && profile.isLoaded) {
      const uWPProfile = { ...get(usersWithPrivileges, [profile.uid], {}) };
      delete uWPProfile.key;
      delete uWPProfile.name;
      dispatch({
        type: SET_PROFILE,
        profile: {
          ...uWPProfile,
          ...profile,
        },
      });
      this.setState({ first: false });
    }
  }

  render() {
    const {
      children, usersWithPrivileges, profile, loadFromUsersTable, users,
    } = this.props;
    const areUsersLoaded = !loadFromUsersTable || (loadFromUsersTable && (profile.isSuperAdmin || profile.isLabAdminUser) && isLoaded(users));

    if (!isLoaded(usersWithPrivileges) || !profile.isLoaded || !areUsersLoaded) {
      return <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column">
        <Loading isLoading={true}/>
      </Page>;
    } else if (!this.state.first && !profile.isSuperAdmin && (!profile.isLabUser || profile.labId == null)) {
      return <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column">
        <div className="not-lab-user-notice align-self-center">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">kein Zugriff</h4>
              <p>
                Sie haben kein Zugriff auf diese Seite. Wenn Sie das als ein Fehler betrachten,
                kontaktieren Sie uns unter <a href="mailto:office@farmerjoe.de">office@farmerjoe.de</a>.
              </p>
            </div>
          </div>
        </div>
      </Page>;
    } else {
      return children;
    }
  }
}

type UserLoaderExternalProps = {
  loadAll?: boolean;
  loadFromUsersTable?: boolean;
};

export default compose<React.ComponentClass<UserLoaderExternalProps>>(
  connect((state) => ({
    usersWithPrivileges: get(state, 'firestore.data.usersWithPrivileges'),
    users: get(state, 'firestore.data.users'),
    profile: get(state, 'firebase.profile'),
  }), (dispatch) => ({ dispatch })),
  firestoreConnect(props => {
    const {
      profile: { isSuperAdmin, isLabAdminUser, labId },
      usersWithPrivileges,
      loadFromUsersTable,
    } = props;
    const toLoad: any[] = [];

    if (isSuperAdmin && props.loadAll) {
      toLoad.push({
        collection: 'usersWithPrivileges',
      });
    } else if (isLabAdminUser && props.loadAll) {
      toLoad.push({
        collection: 'usersWithPrivileges',
        where: [['labId', '==', labId]],
      });
    } else {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        toLoad.push({
          collection: 'usersWithPrivileges',
          doc: currentUser.uid,
        });
      }
    }

    if (loadFromUsersTable && (isSuperAdmin || isLabAdminUser) && isLoaded(usersWithPrivileges)) {
      entries<any>(usersWithPrivileges)
        .filter(([key, u]) => u && (isSuperAdmin || (isLabAdminUser && u.labId === labId)))
        .map(([key, u]) => key)
        .map(key => ({
          collection: 'users',
          doc: key,
        }))
        .forEach(l => toLoad.push(l));
    }

    return toLoad;
  }),
)(Loader);
