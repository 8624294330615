import * as React from 'react';
import { defaults } from 'lodash-es';
import PropTypes from 'prop-types';
import FixedMarkerWithLabel from './addons/FixedMarkerWithLabel';

const MARKER_DEFAULT_Z_INDEX = 100;
const ONE = 1;
const TWO = 2;
const THREE = 3;
const FOUR = 4;

const pinDefaults = {
  width: 30,
  height: 30,
  color: '#ee4039',
  triangleSide: 6,
};

export type MarkerProps = {
  children?: React.ReactNode;
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  color?: string;
  width?: number;
  height?: number;
  labelClass?: string;
  zIndex?: number;
  labelStyle?: Record<string, any>;
  onClick?: (...args: Array<any>) => any;
  wrapper1Before?: React.ReactNode;
  map: google.maps.Map;
  markerClusterer?: any;
};

export default class Marker extends React.Component<MarkerProps> {
  static contextTypes = {
    spiderClusterer: PropTypes.bool,
  };

  render() {
    const {
      position,
      color,
      width,
      height,
      labelClass,
      zIndex,
      labelStyle,
      children,
      onClick,
      wrapper1Before,
      ...restProps
    } = this.props;
    const pinOptions = defaults(
      {},
      {
        color,
        width,
        height,
      },
      pinDefaults,
    );

    const triangleHeight = Math.round(Math.sin(ONE / THREE * Math.PI) * pinOptions.triangleSide);
    const heightWithTriangle = Math.ceil(pinOptions.height + triangleHeight + FOUR);
    const halfOfTriangleSide = Math.round(pinOptions.triangleSide / TWO);

    return (
      <FixedMarkerWithLabel
        className="analysis-marker"
        position={position}
        labelAnchor={{ x: pinOptions.width / TWO, y: heightWithTriangle }}
        icon={{ path: '' }}
        zIndex={zIndex || MARKER_DEFAULT_Z_INDEX}
        labelClass={['marker-label', labelClass].filter(s => s).join(' ')}
        labelStyle={{ width: pinOptions.width + 'px', height: heightWithTriangle + 'px', ...labelStyle }}
        onClick={this.context.spiderClusterer ? undefined : onClick}
        onSpiderClick={this.context.spiderClusterer ? onClick : undefined}
        {...restProps}
      >
        <div className="marker-label-wrapper-1" style={{ paddingBottom: triangleHeight }}>
          {!!wrapper1Before && wrapper1Before}
          <div className="marker-label-wrapper-2" style={{ backgroundColor: pinOptions.color }}>
            {children}
          </div>
          <div className="triangle" style={{
            borderLeftWidth: halfOfTriangleSide,
            borderRightWidth: halfOfTriangleSide,
            borderTopWidth: triangleHeight,
            marginLeft: -halfOfTriangleSide,
            borderTopColor: pinOptions.color,
          }}/>
        </div>
      </FixedMarkerWithLabel>
    );
  }
}
