import { compose, createStore } from 'redux';
import { reactReduxFirebase } from 'react-redux-firebase';
import { reduxFirestore } from 'redux-firestore';

import fire from '../data/firebase';
import combinedReducers from '../reducers/reducers';

const rrfConfig = {
  updateProfileOnLogin: true,
  userProfile: 'users',
  useFirestoreForProfile: true,
  oneListenerPerPath: true,
  logListenerError: process.env.NODE_ENV !== 'production',
  /**
   * Executed after a registration. We grab the data and add it to the user's profile
   * /users/{userid}/
   *
   * @param userData
   * @param profile
   * @returns {{uid: *, email: *, displayName: *, firstname: *, lastname: *}}
   */
  profileFactory: (userData, profile) => {
    let { uid, email, displayName } = userData;
    const { firstname = null, lastname = null } = profile;
    if (!displayName && profile.displayName) {
      displayName = profile.displayName;
    }

    return { uid, email, displayName, firstname, lastname };
  },
};

export default function configureStore(initialState = {}) {
  const composeEnhancers = (process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const createStoreWithFirebase = composeEnhancers(
    reactReduxFirebase(fire, rrfConfig), // firebase instance as first argument
    reduxFirestore(fire as any),
  )(createStore);

  const store = createStoreWithFirebase(combinedReducers, initialState);

  return store;
}
