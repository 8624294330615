import { _fetch } from 'whatwg-fetch';
import { HttpStatus } from '../flowTypes';

const originalFetch = _fetch || window.fetch;

type ErrorWithResponse = Error & {response?: Record<string, any>};

function checkStatus(response: Response) {
  if (response.status >= HttpStatus.OK && response.status < HttpStatus.AMBIGUOUS) {
    return response;
  } else {
    const error: ErrorWithResponse = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export default function fetch(...args: any[]): Promise<Response> {
  return originalFetch(...args)
    .then(checkStatus);
}
