import firebase from 'firebase';

/**
 * Firestore now returns data that is formatted as a Timestamp
 * Our functions normally expect a js Date object.
 * Copied from farmerjoe-common.
 *
 * @param date
 */
export function toDate(date) {
  if (date === null || date === undefined) {
    return date;
  }

  // the value is already a Date object, just return it
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return date;
  }

  // Test if firestore timestamp and convert to date
  if (date instanceof firebase.firestore.Timestamp) {
    return date.toDate();
  }

  // try to convert to date object
  if (typeof date === 'string') {
    return new Date(date);
  }

  // if the timestamp is being copied around sometimes it is a plain js object.
  // if it has seconds and nanoseconds treat it as a firestore Timestamp
  if (
    date !== null &&
    typeof date === 'object' &&
    date.hasOwnProperty('seconds') &&
    date.hasOwnProperty('nanoseconds')
  ) {
    const firestore = firebase.firestore;
    return new firestore.Timestamp(date.seconds, date.nanoseconds).toDate();
  }
}
