import React, { Component } from 'react';
import firebase from 'firebase';
import Script from 'react-load-script';
import './style.css';

import fire from '../../data/firebase';

let authUi;
window.firebase = firebase;

type Props = {
  signInSuccessUrl: string;
};

type State = {
  currentUser: firebase.User | null;
};

class FirebaseUI extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: firebase.auth().currentUser,
    };
  }

  componentDidUpdate() {
    this.createUIIfNeeded();
  }

  createUIIfNeeded() {
    if (!authUi) {
      authUi = new (window as any).firebaseui.auth.AuthUI(fire.auth());
    }
    const { signInSuccessUrl } = this.props;

    if (document.getElementById('firebaseui-auth') != null) {
      // if (!this.state.currentUser) {
      const uiConfig = {
        signInSuccessUrl,
        credentialHelper: (window as any).firebaseui.auth.CredentialHelper.NONE,
        signInFlow: 'popup',
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
      };
      authUi.start('#firebaseui-auth', uiConfig);
      // }
    }
  }

  resetUI() {
    if (authUi) {
      authUi.reset();
    }
  }

  componentWillUnmount() {
    this.resetUI();
  }

  render() {
    // if (!this.state.currentUser) {

    return (
      <div>
        <Script
          url="https://www.gstatic.com/firebasejs/ui/4.0.0/firebase-ui-auth__de.js"
          onLoad={() => this.createUIIfNeeded()}
        />
        <div id="firebaseui-auth"></div>
      </div>
    );
    // }

    // return null
  }
}

export default FirebaseUI;
