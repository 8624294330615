export const searchApiRequest = () => ({
  type: 'app/searchApiRequest',
});

export const searchApiResponse = (payload) => ({
  type: 'app/searchApiResponse',
  payload,
});

export const searchApiResponseError = (payload) => ({
  type: 'app/searchApiResponseError',
  payload,
});

export const addFoundKeys = (payload, meta) => ({
  type: 'app/addFoundKeys',
  payload,
  meta,
});

export const clearFoundKeys = () => ({
  type: 'app/clearFoundKeys',
});
