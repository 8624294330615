import * as React from 'react';
import { get, isArray, map } from 'lodash-es';
import { AnalysisType, getAnalysisType } from '../../utils/analyses';

const DEFAULT_CROP_AGE = 0;

export function getCropRow(a) {
  const field = a.field;
  const label = field.stock ? 'Lagerware' : 'Kultur';
  const crop = a.form.crop;
  const stack = [crop.name];
  // let img = <AnalysisImage>{};
  const farmingType = getFarmingType(a);

  if (farmingType) {
    stack.push(farmingType);
  }

  if (!field.stock) {
    stack.push(`${crop.age || DEFAULT_CROP_AGE} Tage`);
  }

  if (crop.pre_crop) {
    stack.push(`Vorfrucht: ${crop.pre_crop}`);
  }

  if (getAnalysisType(a) === AnalysisType.Residue && crop.crop_havesting_state) {
    stack.push(`${a.labSelected.offers.residue.crop_havesting_state[crop.crop_havesting_state]}`);
  }
  if (crop.harvested_on) {
    stack.push(`Erntetermin: ${crop.harvested_on}`);
  }

  // if (IMAGE) {
  // img = {image: IMAGE, width: 250}
  // }

  stack.push(crop.notes ? `Info: ${crop.notes}` : '');

  return {
    label,
    stack,
  };
}

function getFarmingType(a) {
  const analysisType = getAnalysisType(a);
  if (analysisType !== AnalysisType.Residue) {
    return false;
  }
  const type: any[] = [];

  if (a.form.offers.farming_type) {
    type.push(a.labSelected.offers[analysisType].farming_type[a.form.offers.farming_type]);
  }
  if (a.form.offers.farming_type1) {
    type.push(a.labSelected.offers[analysisType].farming_type1[a.form.offers.farming_type1]);
  }

  if (!type.length) {
    return false;
  }

  return type.join(' ');
}

export function getCompost(a) {
  if (getAnalysisType(a) !== AnalysisType.Soil) {
    return false;
  }
  const compost = a.form.compost || {};
  const info: string[] = [];
  if (compost.start) {
    info.push(compost.start);
  }
  if (compost.volume) {
    info.push(`${compost.volume}t`);
  }
  if (compost.year) {
    info.push(`Ausbringungsjahr ${a.form.compost.year}`);
  }

  if (info.length) {
    return {
      label: 'Kompost',
      stack: info,
    };
  }

  return false;
}

export function getService(a) {
  const type = getAnalysisType(a);
  if (type === AnalysisType.Soil) {
    return getServiceSoil(a);
  }

  if (type === AnalysisType.Residue) {
    return getServiceResidue(a);
  }

  return false;
}

export function getProbeForm(a) {
  const service = a.form.offers.service;
  const serviceA = a.form.offers.serviceA;
  const analysisType = getAnalysisType(a);

  const services = [a.labSelected.offers[analysisType].service[service]];
  if (service === 'a') {
    services.push(a.labSelected.offers[analysisType].serviceA[serviceA]);
  }
  let form = '';

  if (analysisType === AnalysisType.Residue) {
    form = a.labSelected.offers[analysisType].form[a.form.offers.form];
  }

  return {
    label: 'Probenahme',
    stack: [...services, form],
  };
}

export function getResultFor(a) {
  const analysisType = getAnalysisType(a);
  if (analysisType !== AnalysisType.Residue) {
    return false;
  }
  const result = get(a, 'form.offers.result_for');
  const ResultFor = result.map(key => {
    return a.labSelected.offers[analysisType].result_for[key];
  });

  return {
    label: 'Ergebnis für',
    stack: ResultFor,
  };
}

export function getDatabase(a) {
  const analysisType = getAnalysisType(a);
  if (analysisType !== AnalysisType.Residue) {
    return false;
  }
  const db = get(a, 'form.offers.db', []);
  const dbs = db.map(key => {
    return a.labSelected.offers[analysisType].db[key];
  });

  return {
    label: 'Datenbank',
    stack: dbs,
  };
}

export function getServiceResidue(a) {
  return false;
}

const catchCrop = {
  leguminous: 'Leguminose',
  nonleguminous: 'Nicht leguminose',
};
const frozen = {
  frozen: 'Abgefroren',
  not_frozen: 'Nicht Abgefroren',
};
const incorporation = {
  spring: 'Frühjahr',
  autumn: 'Herbst',
};

export function getServiceSoil(a) {
  const offers = a.form.offers;
  let nitrate = offers.nitrate;
  let other_chemicals = offers.other_chemicals;
  let table = {};
  const fertlizerNeed = get(offers, 'fertilizer_need', false);
  const organicFertilizer = get(offers, 'organic_fertilizer', false);
  let fertilizerNeedTemplate = '';

  if (nitrate && isArray(nitrate)) {
    nitrate = offers.nitrate.map(value => a.labSelected.offers.soil.nitrate[value]).join(', ');
  }

  if (other_chemicals) {
    other_chemicals = offers.other_chemicals.map(value => a.labSelected.offers.soil.other_chemicals[value]).join(', ');
  } else {
    other_chemicals = null;
  }

  if (fertlizerNeed) {
    fertilizerNeedTemplate = `
Düngerbedarfsermittlung: ja
Zwischenfrucht: ${catchCrop[offers.catch_crop]}, ${frozen[offers.frozen]}
Folie: ${offers.folie ? 'Ja' : 'Nein'}
Humus >4%: ${offers.humus ? 'Ja' : 'Nein'}
Einarbeitung: ${incorporation[offers.incorporation]}
Bisherige Düngung: ${offers.current_fertilizer} kg N/ha`;

    if (organicFertilizer) {
      fertilizerNeedTemplate += 'Organische Düngung: ja';
      table = <table className="table table-striped">
        <thead>
        <tr>
          <th>Vorjahr</th>
          <th>Düngeart</th>
          <th>kg N/ha</th>
        </tr>
        </thead>
        <tbody>
        {map(offers.organic_fertilizer_years, (value, key) => {
          return <tr>
            <td>{key + '.'}</td>
            <td>{value.art}</td>
            <td>{value.kgha}</td>
          </tr>;
        })}
        </tbody>
      </table>;
    }
  }

  // In the first virsions of the App we had other_chemicals, but later we moved everything
  // under nitrate. That's why if other_chemicals is not defined, we return here
  if (typeof other_chemicals === 'undefined') {
    return {
      label: 'Leistung',
      stack: [nitrate, ...fertilizerNeedTemplate.split(/\n/g), table],
    };
  }

  return {
    label: 'Leistung',
    stack: [nitrate, other_chemicals],
  };
}

export function getNote(a) {
  const notes = get(a, 'form.offers.notes');
  if (notes) {
    return {
      label: 'Bemerkung des Auftraggebers',
      stack: [notes],
    };
  }
  return false;
}
