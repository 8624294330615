import * as React from 'react';

import firebase from '../../data/firebase';
import { Loading } from '../Loading/Loading';
import { identity } from 'lodash-es';
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';
import './style.css';

const SMALL_SCREEN_WIDTH_THRESHOLD = 320;

type Props = {
  imageRef: string;
  className?: string;
};
type State = {
  imageSource: string;
  visible: boolean;
};

export default class FirebaseImage extends React.PureComponent<Props, State> {
  state = {
    imageSource: '',
    visible: false,
  };

  componentDidMount() {
    const { imageRef } = this.props;

    const storage = firebase.storage();

    storage.ref(imageRef).getDownloadURL().then((url) => {
      this.setState({ imageSource: url });
    });
  }

  render() {
    const { className, imageRef, ...restProps } = this.props;
    const { imageSource } = this.state;

    let size = { width: 250, height: 150 };

    if (window.innerWidth > SMALL_SCREEN_WIDTH_THRESHOLD) {
      size = { width: 300, height: 250 };
    }

    if (!imageSource) {
      return (
        <div style={size}><Loading/></div>
      );
    }

    const close = () => { this.setState({ visible: false }); };
    return (
      <div>
        <img
          className={['firebase-image', className].filter(identity).join(' ')}
          style={size}
          src={imageSource}
          onClick={() => this.setState({ visible: !this.state.visible })}
          {...restProps}
        />
        <Viewer
          visible={this.state.visible}
          onMaskClick={close}
          onClose={close}
          images={[{ src: imageSource }]}
          noToolbar={true}
          noNavbar={true}
          noImgDetails={true}
          changeable={false}
        />
      </div>
    );
  }
}
