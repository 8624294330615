import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './themeCSS/bootstrap-grid-xxl.css';
import 'react-tabs/style/react-tabs.css';
import * as Waves from 'node-waves/dist/waves';
import 'react-table/react-table.css';
import 'react-bootstrap-modal/lib/css/rbm-complete.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
import './themeCSS/style.css';
import './themeCSS/farmerjoe.css';
import './index.css';
import App from './App';
import createStore from './stores/store';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { Loading } from './components/Loading/Loading';
import {
  Router,
} from 'react-router-dom';
import Page from './components/Page/Page';
import { FilterMode } from './selectors/filters';
import { createBrowserHistory, createHashHistory } from 'history';
import { OrderByDirection } from './flowTypes';

export const store = createStore({
  filters: {
    mode: FilterMode.open.enumKey,
    // limit: 20,
    orderBy: {
      [FilterMode.open.enumKey]: [['form.offers.result_on', OrderByDirection.Asc]],
      [FilterMode.inbox.enumKey]: [['form.offers.result_on', OrderByDirection.Asc]],
      [FilterMode.results.enumKey]: [['completed_on', OrderByDirection.Desc]],
      [FilterMode.cancelled.enumKey]: [['created', OrderByDirection.Desc]],
    },
  },
  map: {
    filters: {
      deadline: null,
      marked: false,
      soil: true,
      residue: true,
      probeTaken: false,
    },
  },
});

const history = createHistory();

type Props = {};

type State = {
  userReady: boolean;
};

class FarmerJoeLab extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      userReady: false,
    };
  }

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillMount() {
    store.firebase.auth().onAuthStateChanged((user) => {
      this.setState({ userReady: true });
    });
  }

  render() {
    if (!this.state.userReady) {
      return (
        <Provider store={store}>
          <Router history={history}>
            <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column">
              <Loading isLoading={true}/>
            </Page>
          </Router>
        </Provider>
      );
    }

    return (
      <Provider store={store}>
        <Router history={history}>
          <App/>
        </Router>
      </Provider>
    );
  }
}

function createHistory() {
  return window.matchMedia('(display-mode: standalone)').matches
    ? createHashHistory()
    : createBrowserHistory();
}

// enable hot module reloading
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept();
}

if (process.env.NODE_ENV == 'development') {
  document.title = 'DEV - FarmerJoe labs';
}

Waves.displayEffect();

ReactDOM.render(<FarmerJoeLab/>, document.getElementById('root'));
registerServiceWorker();
