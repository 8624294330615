import * as React from 'react';
import { iframeResizer } from 'iframe-resizer';

const MODAL_HEADER_HEIGHT = 81;
const TOP_MARGIN = 30;
const BOTTOM_MARGIN = 30;

// It is unclear where these numbers come from
const UNKNOWN_HEIGHT_1 = 68;
const UNKNOWN_HEIGHT_2 = 40;

type Props = {
  children?: React.ReactNode;
  src: string;
  className: string;
};

export default class PdfViewer extends React.Component<Props, {
  listeners: Array<(arg0: Record<string, any>) => void>;
}> {
  state = {
    listeners: [],
  };

  private iframeRef = React.createRef<HTMLIFrameElement>();

  componentDidMount() {
    this.listen('viewer-ready', () => {
      this.send('init', {
        src: this.props.src,
      });
    });
    iframeResizer({
      maxHeight: window.innerHeight - TOP_MARGIN - BOTTOM_MARGIN - MODAL_HEADER_HEIGHT - UNKNOWN_HEIGHT_1 - UNKNOWN_HEIGHT_2,
    }, this.iframeRef.current);
  }

  componentWillUnmount() {
    if (this.iframeRef.current !== null && this.iframeRef.current.contentWindow !== null) {
      const iframeContentWindow = this.iframeRef.current.contentWindow;
      this.state.listeners.forEach((listener) => iframeContentWindow.removeEventListener('message', listener));
    }
  }

  render() {
    return (
      <iframe ref={this.iframeRef} {...this.props} src="/pdf/web/viewer.html"/>
    );
  }

  download() {
    this.send('download');
  }

  send(event: string, data?: any) {
    if (this.iframeRef.current !== null && this.iframeRef.current.contentWindow !== null) {
      this.iframeRef.current.contentWindow.postMessage(JSON.stringify({
        event: event,
        data: data,
      }), '*');
    }
  }

  listen(event: string, callback: (Object) => void) {
    const listener = function(e) {
      try {
        const data = JSON.parse(e.data);
        if (data.event === event) {
          callback(data.data);
        }
      } catch (e) {
      }
    };
    if (this.iframeRef.current !== null && this.iframeRef.current.contentWindow !== null) {
      this.iframeRef.current.contentWindow.addEventListener('message', listener);
    }
    this.setState({
      listeners: [...this.state.listeners, listener],
    });
  }
}
