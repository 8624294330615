import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { get } from 'lodash-es';
import { changeMapFilters } from '../../actions/actions';
import type { Profile } from '../../flowTypes';

const DEADLINE_A_DAY = 1;
const DEADLINE_2_DAYS = 2;
const DEADLINE_3_DAYS = 3;
const DEADLINE_7_DAYS = 7;

class ToolBar extends React.Component<{
  filters: Record<string, any>;
  actions: Record<string, any>;
  profile: Profile;
}> {
  render() {
    const {
      markedBy,
      soil,
      residue,
      drone,
    } = this.props.filters;
    const { changeMapFilters } = this.props.actions;
    const uid = this.props.profile.uid;

    return (
      [
        <div key={0} style={{ height: '52px' }}></div>,
        <aside key={1} className="left-sidebar">
          <div className="scroll-sidebar">
            <nav className="sidebar-nav">
              <ul id="sidebarnav">
                {this.renderDeadlineFilterButton(DEADLINE_A_DAY, '1 Tag')}
                {this.renderDeadlineFilterButton(DEADLINE_2_DAYS, '2 Tage')}
                {this.renderDeadlineFilterButton(DEADLINE_3_DAYS, '3 Tage')}
                {this.renderDeadlineFilterButton(DEADLINE_7_DAYS, '7 Tage')}
                <li className={activeClassIf(markedBy)}>
                  <a onClick={() => changeMapFilters({ markedBy: markedBy ? null : uid })} href="javascript:void 0">
                    <span>Markiert</span>
                  </a>
                </li>
                <li className={activeClassIf(soil)}>
                  <a onClick={() => changeMapFilters({ soil: !soil })} href="javascript:void 0">
                    <span>Boden</span>
                  </a>
                </li>
                <li className={activeClassIf(residue)}>
                  <a onClick={() => changeMapFilters({ residue: !residue })} href="javascript:void 0">
                    <span>Rückstand</span>
                  </a>
                </li>
                <li className={activeClassIf(drone)}>
                  <a onClick={() => changeMapFilters({ drone: !drone })} href="javascript:void 0">
                    <span>Drohnen</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </aside>,
      ]
    );
  }

  private renderDeadlineFilterButton(daysCount: number, deadlineLabel: string) {
    const { deadline } = this.props.filters;

    return (
      <li className={activeClassIf(deadline === daysCount)}>
        <a onClick={() => this.toggleDeadlineFilter(deadline, daysCount)} href="javascript:void 0">
          <span>{deadlineLabel}</span>
        </a>
      </li>
    );
  }

  private toggleDeadlineFilter = (currentDeadline: number | null, newDeadline: number) => {
    const { changeMapFilters } = this.props.actions;
    if (currentDeadline === newDeadline) {
      changeMapFilters({ deadline: null });
    } else {
      changeMapFilters({ deadline: newDeadline });
    }
  };
}

function activeClassIf(condition) {
  if (condition) {
    return 'active';
  }
  return undefined;
}

export default compose(
  connect((state) => ({
    filters: get(state, 'map.filters'),
    profile: get(state, 'firebase.profile'),
  }), (dispatch) => ({
    actions: bindActionCreators(
      {
        changeMapFilters,
      },
      dispatch,
    ),
  })),
)(ToolBar);
