import fetch from './fetch';

export function requestApiKey(token: string) {
  return fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/getSearchApiKey`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => response.json());
}
