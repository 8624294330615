import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Loading } from '../Loading/Loading';
import Page from '../Page/Page';
import { searchApiRequest, searchApiResponse, searchApiResponseError } from '../../actions/search';
import { requestApiKey } from '../../data/search';

const firebase = require('firebase');

type Props = {
  children?: React.ReactNode;
  dispatch: (...args: Array<any>) => any;
  search: Record<string, any>;
};

class Loader extends React.Component<Props, { first: boolean }> {
  state = {
    first: true,
  };

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillMount() {
    const { dispatch, search: { requesting = false, loaded = false } } = this.props;

    if (!loaded && !requesting) {
      dispatch(searchApiRequest());

      firebase.auth().currentUser
        .getIdToken(true)
        .then((token) => {
          // console.log(token)
          return requestApiKey(token)
            .then((response) => dispatch(searchApiResponse(response)))
            .catch((e) => dispatch(searchApiResponseError(e)));
        },
        );
    }
  }

  render() {
    const { children, search: { loaded = false } } = this.props;
    if (!loaded) {
      return <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column">
        <Loading isLoading={true}/>
      </Page>;
    } else {
      return children;
    }
  }
}

export default compose(
  connect((state) => ({
    search: state.search || {},
  }), (dispatch) => ({ dispatch })),
)(Loader);
