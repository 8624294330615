import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactTable from 'react-table';
import { get, isArray, isEqual, isString } from 'lodash-es';
import { Loading } from '../Loading/Loading';
import { FilterMode } from '../../selectors/filters';
import { Analysis, OrderByDirection, Profile } from '../../flowTypes';

export type TableProps = {
  onClickAnalysis: (arg0: Analysis) => void;
  onFilterChange: (...args: Array<any>) => any;
  analyses: Array<Analysis>;
  isLoading: boolean;
  columns: Array<Record<string, any>>;
  getTrProps: (state: any, rowInfo: any, column: any, instance: any) => Record<string, any>;
  profile: Profile;
};

class Table extends React.Component<TableProps & {
  orderBy: any;
  filterMode: string;
  actions: Record<string, any>;
}, {
  firstFetchData: boolean;
  tableSort: Array<any>;
  analysisToReject: any;
}> {
  state = {
    firstFetchData: true,
    tableSort: [],
    analysisToReject: null,
  };

  /**
   * TODO: don't use legacy methods.
   * @see https://reactjs.org/docs/react-component.html#legacy-lifecycle-methods.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      orderBy: orderByForEachFilterMode,
      filterMode,
    } = nextProps;
    const orderBy = orderByForEachFilterMode[filterMode];
    let tableSort;

    // prepare sorted field from filters for react table
    if (orderBy) {
      if (isString(orderBy)) {
        tableSort = [{ id: orderBy, desc: false }];
      } else if (isArray(orderBy)) {
        tableSort = orderBy.map((o) => {
          if (isString(o)) {
            return { id: o, desc: false };
          } else if (isArray(o)) {
            const [id, orderByDirection] = o;
            return { id, desc: orderByDirection === OrderByDirection.Desc };
          } else {
            return null;
          }
        });
      }
      this.setState({ tableSort: tableSort || [] });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.orderBy, nextProps.orderBy) || !isEqual(this.props.filterMode, nextProps.filterMode) ||
           !isEqual(this.props.isLoading, nextProps.isLoading) ||
           !isEqual(this.props.analyses, nextProps.analyses) || !isEqual(this.state, nextState);
  }

  render() {
    const {
      isLoading,
      onClickAnalysis,
      analyses,
      onFilterChange,
      filterMode: mode,
      orderBy,
      columns,
      getTrProps,
    } = this.props;

    const filterMode: FilterMode | undefined = FilterMode.enumValueOf(mode) as any;
    if (!filterMode) {
      throw new Error('Unexpected value for filterMode: undefined');
    }

    return (
      <ReactTable
        className="analysis-table dataTable table-striped table-hover"
        data={analyses}
        columns={columns}
        getTrProps={(state, rowInfo, column, instance) => ({
          onClick: e => onClickAnalysis(rowInfo.original),
          ...((getTrProps && getTrProps(state, rowInfo, column, instance)) || {}),
        })}
        manual
        showPagination={false}
        minRows={0}
        NoDataComponent={() => isLoading ? <Loading isLoading={true}/> : <div className="no-analysis">Keine Analysen vorhanden.</div>}
        onSortedChange={(sorted, column, shiftKey) => {
          const filterChanges: any = {};

          filterChanges.orderBy = {
            ...orderBy,
            [filterMode?.enumKey]: sorted.map((o) => [o.id, o.desc ? OrderByDirection.Desc : OrderByDirection.Asc]),
          };

          if (!isEqual(this.state.tableSort, sorted)) {
            this.setState({ tableSort: sorted });
          }

          onFilterChange(filterChanges);
        }}
        sorted={this.state.tableSort}
      />
    );
  }
}

export default compose(
  connect((state) => ({
    orderBy: get(state, 'filters.orderBy'),
    filterMode: get(state, 'filters.mode'),
  })),
)(Table);
